/* eslint-disable */
import React, { Component } from "react";
import TimeField from "react-simple-timefield";

class TimeInput extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <TimeField
                value={this.props.value || "00:00"}
                onChange={(value) => this.props.onChange(value)}
                style={{
                    fontSize: 20,
                    width: "100%",
                    textAlign: "center",
                }}
            />
        );
    }
}

TimeInput.defaultProps = {};

export default TimeInput;
