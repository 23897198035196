import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setProject } from "../../actions";

class ProjectButton extends Component {
    getProjectName(projectId) {
        if (!projectId) {
            return "Not selected";
        }
        return this.props.projects.find((project) => project.id === projectId)
            .name;
    }

    render() {
        if (!this.props.selectedClient) {
            return (
                <button
                    className="button"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                    type="button"
                    disabled
                >
                    <span>Not selected</span>
                    <span className="icon is-small">
                        <i className="fas fa-angle-down" aria-hidden="true" />
                    </span>
                </button>
            );
        }

        const projectList = this.props.clients
            .find((client) => client.id === this.props.selectedClient)
            .projects.map((projectId) => (
                <span
                    className="dropdown-item"
                    key={projectId}
                    onClick={() => this.props.setProject(projectId)}
                >
                    {this.getProjectName(projectId)}
                </span>
            ));
        return (
            <div
                className="dropdown is-hoverable"
                style={{ paddingRight: "8px" }}
            >
                <div className="dropdown-trigger">
                    <button
                        className="button"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu"
                        type="button"
                    >
                        <span>
                            {this.getProjectName(this.props.selectedProject)}
                        </span>
                        <span className="icon is-small">
                            <i
                                className="fas fa-angle-down"
                                aria-hidden="true"
                            />
                        </span>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                        <span
                            className="dropdown-item"
                            onClick={() => this.props.setProject(null)}
                        >
                            Not selected
                        </span>
                        {projectList}
                    </div>
                </div>
            </div>
        );
    }
}

ProjectButton.propTypes = {
    setProject: PropTypes.func.isRequired,
    clients: PropTypes.array,
    projects: PropTypes.array,
    selectedClient: PropTypes.string,
    selectedProject: PropTypes.string,
};

const mapStateToProps = (state) => ({
    clients: state.clients.clients.results,
    projects: state.projects.projects.results,
    selectedClient: state.overview.selectedClient,
    selectedProject: state.overview.selectedProject,
});

const mapDispatchToProps = { setProject };

export default connect(mapStateToProps, mapDispatchToProps)(ProjectButton);
