import { createReducer } from "../../reducers/reducerUtilities";
import {
    INVITE_TO_USER_BEGIN,
    INVITE_TO_USER_SUCCESS,
    INVITE_TO_USER_FAILURE,
} from "./actions";

const initialState = {
    loading: false,
    error: null,
};

function inviteToUserBegin(state) {
    return {
        ...state,
        loading: true,
    };
}

function inviteToUserSuccess(state) {
    return {
        ...state,
        loading: false,
    };
}

function inviteToUserFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
    };
}

const handlers = {};
handlers[INVITE_TO_USER_BEGIN] = inviteToUserBegin;
handlers[INVITE_TO_USER_SUCCESS] = inviteToUserSuccess;
handlers[INVITE_TO_USER_FAILURE] = inviteToUserFailure;

const inviteReducer = createReducer(initialState, handlers);

export default inviteReducer;
