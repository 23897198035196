import React from "react";

import {
    MonthButton,
    YearButton,
    EmployeeButton,
    ClientButton,
    ProjectButton,
    // StatusButton,
    ResetButton,
} from "./Controls";

const Controls = () => (
    <div className="columns">
        <div className="column">
            <label className="label">Year</label>
            <YearButton />
        </div>
        <div className="column">
            <label className="label">Month</label>
            <MonthButton />
        </div>
        <div className="column">
            <label className="label">Employee</label>
            <EmployeeButton />
        </div>
        <div className="column">
            <label className="label">Client</label>
            <ClientButton />
        </div>
        <div className="column">
            <label className="label">Project</label>
            <ProjectButton />
        </div>
        {/* <div className="column">
      <label className="label">Status</label>
      <StatusButton />
    </div> */}
        <div className="column">
            <label className="label">Reset</label>
            <ResetButton />
        </div>
    </div>
);

export default Controls;
