import moment from "moment";

import { createReducer } from "../../reducers/reducerUtilities";
import {
    OVERVIEW_SET_MONTH,
    OVERVIEW_SET_YEAR,
    OVERVIEW_SET_CHECKED,
    OVERVIEW_GET_HOURS_BEGIN,
    OVERVIEW_GET_HOURS_SUCCESS,
    OVERVIEW_GET_HOURS_FAILURE,
    OVERVIEW_SET_EMPLOYEE,
    OVERVIEW_SET_CLIENT,
    OVERVIEW_SET_PROJECT,
    OVERVIEW_SET_STATUS,
    OVERVIEW_RESET_FILTERS,
} from "./actions";

const initialState = {
    year: parseInt(moment().format("YYYY"), 10),
    month: parseInt(moment().format("M"), 10) - 1,
    selectedEmployee: null,
    selectedClient: null,
    selectedProject: null,
    selectedStatus: null,
    loadingHours: false,
    loadingHoursError: null,
    updatingStatus: false,
    updatingStatusError: null,
    hours: [],
    checked: [],
};

function resetFilters(state) {
    return {
        ...state,
        year: parseInt(moment().format("YYYY"), 10),
        month: parseInt(moment().format("M"), 10) - 1,
        selectedEmployee: null,
        selectedClient: null,
        selectedProject: null,
        selectedStatus: null,
    };
}

function setProject(state, action) {
    return {
        ...state,
        selectedProject: action.payload,
    };
}

function setStatus(state, action) {
    return {
        ...state,
        selectedStatus: action.payload,
    };
}

function setEmployee(state, action) {
    return {
        ...state,
        selectedEmployee: action.payload,
    };
}

function setClient(state, action) {
    return {
        ...state,
        selectedClient: action.payload,
    };
}

function setMonth(state, action) {
    return {
        ...state,
        month: action.payload,
    };
}

function setYear(state, action) {
    return {
        ...state,
        year: action.payload,
    };
}

function getHoursBegin(state) {
    return {
        ...state,
        hours: [],
        loadingHours: true,
    };
}

function getHoursSuccess(state, action) {
    return {
        ...state,
        hours: action.payload.results,
        loadingHours: false,
    };
}

function getHoursFailure(state, action) {
    return {
        ...state,
        loadingHours: false,
        loadingHoursError: action.payload,
    };
}

function setChecked(state, action) {
    // If the item is already in the list.
    if (state.checked.find((item) => item === action.payload)) {
        return {
            ...state,
            checked: state.checked.filter((item) => item !== action.payload),
        };
    }
    // Item is not in the list.
    return {
        ...state,
        checked: [...state.checked, action.payload],
    };
}

const handlers = {};
handlers[OVERVIEW_SET_MONTH] = setMonth;
handlers[OVERVIEW_SET_YEAR] = setYear;
handlers[OVERVIEW_GET_HOURS_BEGIN] = getHoursBegin;
handlers[OVERVIEW_GET_HOURS_SUCCESS] = getHoursSuccess;
handlers[OVERVIEW_GET_HOURS_FAILURE] = getHoursFailure;
handlers[OVERVIEW_SET_CHECKED] = setChecked;
handlers[OVERVIEW_SET_EMPLOYEE] = setEmployee;
handlers[OVERVIEW_SET_CLIENT] = setClient;
handlers[OVERVIEW_SET_PROJECT] = setProject;
handlers[OVERVIEW_SET_STATUS] = setStatus;
handlers[OVERVIEW_RESET_FILTERS] = resetFilters;

const reducer = createReducer(initialState, handlers);

export default reducer;
