import * as React from "react";
import axios from "axios";
import { getUrl } from "../../getBackend";
import { Modal, Table } from "../shared";

interface IProps {
    onClose: () => void;
}

interface IRow {
    ticket: string;
    status: string;
    spent: string;
    estimate: string;
    last_entry: string;
    remaining: string;
}

export const MixerModal: React.FC<IProps> = (props) => {
    const [data, setData] = React.useState<IRow[]>([]);

    React.useEffect(() => {
        axios.get(`${getUrl()}/api/v1/hours/mixer/`).then((res) => {
            setData(res.data);
        });
    }, []);

    return (
        <>
            <Modal
                closeModal={() => {
                    props.onClose();
                }}
                modalState
                title="Recent tickets"
            >
                <Table>
                    <thead>
                        <tr>
                            <th>Last entry</th>
                            <th>Ticket</th>
                            <th>Status</th>
                            <th>Story points</th>
                            <th>Hours</th>
                            <th>Hours remaining</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((ticket) => (
                            <tr>
                                <td>{ticket.last_entry}</td>
                                <td>{ticket.ticket}</td>
                                <td>{ticket.status}</td>
                                <td>{ticket.estimate}</td>
                                <td>{ticket.spent}</td>
                                <td>{ticket.remaining}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal>
        </>
    );
};
