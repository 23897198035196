import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../getBackend";

export const GET_HOURS_BEGIN = "GET_HOURS_BEGIN";
export const GET_HOURS_SUCCESS = "GET_HOURS_SUCCESS";
export const GET_HOURS_FAILURE = "GET_HOURS_FAILURE";

export const getHoursBegin = () => ({
    type: GET_HOURS_BEGIN,
});

export const getHoursSuccess = (data) => ({
    type: GET_HOURS_SUCCESS,
    payload: { ...data },
});

export const getHoursFailure = (error) => ({
    type: GET_HOURS_FAILURE,
    payload: { error },
});

export function getHours(date) {
    return (dispatch) => {
        dispatch(getHoursBegin());

        axios(`${getUrl()}/api/v1/hours/?date=${date}`)
            .then((res) => {
                dispatch(getHoursSuccess(res.data));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to get hours.", { type: "error" });
                dispatch(getHoursFailure(error));
            });
    };
}
