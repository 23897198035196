import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ContextMenu, Item, Separator } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../getBackend";

import { changeStatus, getInvoices } from "./actions";
import { confirmAlert } from "react-confirm-alert";

class Context extends Component {
    constructor(props) {
        super(props);

        this.onClickPending = this.onClickPending.bind(this);
        this.onClickAwaitingPayment = this.onClickAwaitingPayment.bind(this);
        this.onClickPaid = this.onClickPaid.bind(this);
        this.onClickInvoice = this.onClickInvoice.bind(this);
        this.onClickTimesheet = this.onClickTimesheet.bind(this);
        this.onClickCsv = this.onClickCsv.bind(this);
        this.sendInvoiceToCustomer = this.sendInvoiceToCustomer.bind(this);
    }

    onClickPending({ dataFromProvider }) {
        this.props.changeStatus([dataFromProvider], "pending");
    }

    onClickAwaitingPayment({ dataFromProvider }) {
        this.props.changeStatus([dataFromProvider], "awaiting_payment");
    }

    onClickPaid({ dataFromProvider }) {
        this.props.changeStatus([dataFromProvider], "paid");
    }

    onClickInvoice({ dataFromProvider: id }) {
        this.downloadFile(
            `${getUrl()}/api/v1/invoices/${id}/download_invoice/`
        );
    }

    onClickTimesheet({ dataFromProvider: id }) {
        this.downloadFile(
            `${getUrl()}/api/v1/invoices/${id}/download_timesheet/`
        );
    }

    onClickCsv({ dataFromProvider: id }) {
        this.downloadFile(
            `${getUrl()}/api/v1/invoices/${id}/download_csv_timesheet/`
        );
    }

    downloadFile(url) {
        axios(url)
            .then((res) => {
                window.open(
                    `${getUrl()}/api/download_file/${res.data.id}/`,
                    "_blank"
                );
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to generate download.", { type: "error" });
            });
    }

    sendInvoiceToCustomer({ dataFromProvider: id }) {
        confirmAlert({
            title: "Are you sure you want to send the invoice?",
            buttons: [
                {
                    label: "No",
                },
                {
                    label: "Yes",
                    onClick: () =>
                        axios
                            .post(`${getUrl()}/api/v1/invoices/${id}/send/`)
                            .then((res) => {
                                toast("Invoice send succesfully!", {
                                    type: "success",
                                });

                                this.props.getInvoices();
                            })
                            .catch((error) => {
                                console.error(error);
                                toast("Unable to send invoice.", {
                                    type: "error",
                                });
                            }),
                },
            ],
        });
    }

    render() {
        return (
            <ContextMenu id="menu_id">
                <Item onClick={this.onClickPending}>
                    Change status to pending
                </Item>
                <Item onClick={this.onClickAwaitingPayment}>
                    Change status to awaiting payment
                </Item>
                <Item onClick={this.onClickPaid}>Change status to paid</Item>
                <Separator />
                <Item onClick={this.sendInvoiceToCustomer}>
                    Send invoice to customer
                </Item>
                <Separator />
                <Item onClick={this.onClickInvoice}>Download invoice</Item>
                <Item onClick={this.onClickTimesheet}>Download timesheet</Item>
                <Item onClick={this.onClickCsv}>Download CSV</Item>
            </ContextMenu>
        );
    }
}

Context.propTypes = {
    changeStatus: PropTypes.func.isRequired,
    getInvoices: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = { changeStatus, getInvoices };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Context));
