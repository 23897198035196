import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LocalForm } from "react-redux-form";

import { Modal, ModalActionButton } from "../shared";
import { createClient } from "./actions/createClient";
import { toggleCreateClientModal } from "./actions/toggleCreateClientModal";
import ClientForm from "./ClientForm";

class CreateClientModal extends Component {
    onSubmit(client) {
        this.props.createClient(client);
    }

    toggleModal() {
        this.props.toggleCreateClientModal();
    }

    render() {
        return (
            <Modal
                closeModal={this.props.toggleCreateClientModal}
                modalState={this.props.modalState}
                title="Add new client"
                footerButtons={
                    <ModalActionButton
                        form="createClientForm"
                        text="Create client"
                    />
                }
            >
                <LocalForm
                    onSubmit={(values) => this.onSubmit(values)}
                    id="createClientForm"
                >
                    <ClientForm />
                </LocalForm>
            </Modal>
        );
    }
}

CreateClientModal.propTypes = {
    createClient: PropTypes.func.isRequired,
    toggleCreateClientModal: PropTypes.func.isRequired,
    modalState: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    modalState: state.clients.clientCreateModal.toggled,
});

const mapDispatchToProps = {
    createClient,
    toggleCreateClientModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateClientModal);
