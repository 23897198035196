/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";

import { setTotalTime } from "../actions";

class TimeInput extends Component {
    constructor(props) {
        super(props);
        this.lastVal = "";
    }

    isValid(val) {
        var letterArr = val.split(":").join("").split(""),
            regexp = /^\d{0,2}?\:?\d{0,2}$/,
            valArr = [];

        var [hoursStr, minutesStr] = val.split(":");

        if (!regexp.test(val)) {
            return false;
        }

        const hours = Number(hoursStr);
        const minutes = Number(minutesStr);

        const isValidHour = (hour) =>
            Number.isInteger(hours) && hours >= 0 && hours < 24;
        const isValidMinutes = (minutes) =>
            (Number.isInteger(minutes) && hours >= 0 && hours < 24) ||
            Number.isNaN(minutes);
        if (!isValidHour(hours) || !isValidMinutes(minutes)) {
            return false;
        }

        if (minutes < 10 && Number(minutesStr[0]) > 5) {
            return false;
        }

        if (valArr.indexOf(":")) {
            valArr = val.split(":");
        } else {
            valArr.push(val);
        }

        // check mm and HH
        if (
            valArr[0] &&
            valArr[0].length &&
            (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)
        ) {
            return false;
        }

        if (
            valArr[1] &&
            valArr[1].length &&
            (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)
        ) {
            return false;
        }

        return true;
    }

    onChangeHandler(val) {
        if (val == this.props.total) {
            return;
        }
        if (this.isValid(val)) {
            if (val.length === 2 && val.includes(":")) {
                val = "0" + val;
            }

            if (
                val.length === 2 &&
                this.lastVal.length !== 3 &&
                val.indexOf(":") === -1
            ) {
                val = val + ":";
            }

            if (val.length === 2 && this.lastVal.length === 3) {
                val = val.slice(0, 1);
            }

            if (val.length > 5) {
                return false;
            }

            this.lastVal = val;

            this.props.setTotalTime(val);
        }
    }

    render() {
        return (
            <div key="totalTimeWrapper">
                <input
                    readOnly={true}
                    key="totalTime"
                    tabIndex="-1"
                    id="totalTime"
                    className="input is-large"
                    type="tel"
                    style={{ textAlign: "center", height: "84px" }}
                    placeholder="00:00"
                    value={this.props.total}
                    onChange={(e) => this.onChangeHandler(e.target.value)}
                />
            </div>
        );
    }
}

TimeInput.defaultProps = {
    placeholder: " ",
};

const mapStateToProps = (state) => ({
    total: state.register.time.total,
});

export default connect(mapStateToProps, { setTotalTime })(TimeInput);
