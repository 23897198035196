import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../getBackend";

export const GET_GROUP_BEGIN = "GET_GROUP_BEGIN";
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
export const GET_GROUP_FAILURE = "GET_GROUP_FAILURE";

export const getGroupBegin = () => ({
    type: GET_GROUP_BEGIN,
});

export const getGroupSuccess = (data) => ({
    type: GET_GROUP_SUCCESS,
    payload: { ...data },
});

export const getGroupFailure = (error) => ({
    type: GET_GROUP_FAILURE,
    payload: { error },
});

export function getGroup(groupId) {
    return (dispatch) => {
        dispatch(getGroupBegin());

        axios(`${getUrl()}/api/v1/groups/${groupId}/`)
            .then((res) => {
                dispatch(getGroupSuccess(res.data));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to get group details.", { type: "error" });
                dispatch(getGroupFailure(error));
            });
    };
}
