import React from "react";

import {
    ClientInput,
    ProjectInput,
    HourInput,
    SubmitButton,
    RateInput,
    CommentInput,
} from "./Controls";

const Controls = () => (
    <div className="box">
        <ClientInput />
        <ProjectInput />
        <RateInput />
        <HourInput />
        <CommentInput />
        <SubmitButton />
    </div>
);

export default Controls;
