import {
    CREATE_HOURS_BEGIN,
    CREATE_HOURS_SUCCESS,
    CREATE_HOURS_FAILURE,
} from "../actions/hours/createHour";

import {
    GET_HOURS_BEGIN,
    GET_HOURS_SUCCESS,
    GET_HOURS_FAILURE,
} from "../actions/hours/getHours";

import { createReducer } from "./reducerUtilities";
import { RESET_HOURS } from "../actions/hours/resetHours";
import {
    REMOVE_HOURS_BEGIN,
    REMOVE_HOURS_SUCCESS,
    REMOVE_HOURS_FAILURE,
} from "../actions/hours/removeHours";
import {
    GET_WEEK_HOURS_BEGIN,
    GET_WEEK_HOURS_SUCCESS,
    GET_WEEK_HOURS_FAILURE,
} from "../actions/hours/getWeekHours";

const initialState = {
    getLoading: false,
    getError: null,
    createLoading: null,
    createError: null,
    removeLoading: false,
    removeError: null,
    weekHoursLoading: false,
    weekHoursError: null,
    weekView: [],
    hours: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
};

function getHoursBegin(state) {
    return {
        ...state,
        getLoading: true,
    };
}

function getHoursSuccess(state, action) {
    return {
        ...state,
        hours: action.payload,
        getLoading: false,
    };
}

function getHoursFailure(state, action) {
    return {
        ...state,
        getLoading: false,
        getError: action.payload,
    };
}

function createHoursBegin(state) {
    return {
        ...state,
        createLoading: true,
    };
}

function createHoursSuccess(state, action) {
    return {
        ...state,
        hours: {
            ...state.hours,
            results: [...state.hours.results, action.payload],
            count: state.hours.count + 1,
        },
        createLoading: false,
    };
}

function createHoursFailure(state, action) {
    return {
        ...state,
        createLoading: false,
        createError: action.payload,
    };
}

function resetHours(state) {
    return {
        ...state,
        hours: {
            ...initialState.hours,
        },
    };
}

function removeHoursBegin(state) {
    return {
        ...state,
        removeLoading: true,
    };
}

function removeHoursSuccess(state, action) {
    return {
        ...state,
        removeLoading: false,
        hours: {
            ...state.hours,
            results: state.hours.results.filter(
                (hour) => hour.id !== action.payload
            ),
            count: state.hours.results.count - 1,
        },
    };
}

function removeHoursFailure(state, action) {
    return {
        ...state,
        removeLoading: false,
        removeError: action.payload,
    };
}

function getWeekHoursBegin(state) {
    return {
        ...state,
        weekHoursLoading: true,
    };
}

function getWeekHoursSuccess(state, action) {
    return {
        ...state,
        weekHoursLoading: false,
        weekView: action.payload,
    };
}

function getWeekHoursFailure(state, action) {
    return {
        ...state,
        weekHoursLoading: false,
        weekHoursError: action.payload,
    };
}

const handlers = {};
handlers[CREATE_HOURS_BEGIN] = createHoursBegin;
handlers[CREATE_HOURS_SUCCESS] = createHoursSuccess;
handlers[CREATE_HOURS_FAILURE] = createHoursFailure;
handlers[GET_HOURS_BEGIN] = getHoursBegin;
handlers[GET_HOURS_SUCCESS] = getHoursSuccess;
handlers[GET_HOURS_FAILURE] = getHoursFailure;
handlers[RESET_HOURS] = resetHours;
handlers[REMOVE_HOURS_BEGIN] = removeHoursBegin;
handlers[REMOVE_HOURS_SUCCESS] = removeHoursSuccess;
handlers[REMOVE_HOURS_FAILURE] = removeHoursFailure;
handlers[GET_WEEK_HOURS_BEGIN] = getWeekHoursBegin;
handlers[GET_WEEK_HOURS_SUCCESS] = getWeekHoursSuccess;
handlers[GET_WEEK_HOURS_FAILURE] = getWeekHoursFailure;

const hoursReducer = createReducer(initialState, handlers);

export default hoursReducer;
