import moment from "moment";
import { getOverviewHours } from "./getHours";

export const OVERVIEW_RESET_FILTERS = "OVERVIEW_RESET_FILTERS";

export const resetFiltersAction = () => ({
    type: OVERVIEW_RESET_FILTERS,
});

export function resetFilters() {
    return (dispatch) => {
        dispatch(
            getOverviewHours(
                parseInt(moment().format("M"), 10) - 1,
                parseInt(moment().format("YYYY"), 10)
            )
        );
        dispatch(resetFiltersAction());
    };
}
