import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { logout } from "../../actions/user/logout";
import logo from "../../logo.svg";

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
        };
        this.logout = this.logout.bind(this);
    }

    logout() {
        this.props.userLogout();
        setTimeout(() => {
            window.location.href = "/login";
        }, 200);
    }

    render() {
        return (
            <div
                className="navbar is-transparent"
                role="navigation"
                aria-label="dropdown navigation"
            >
                <div className="navbar-brand">
                    <Link className="navbar-item" to="/">
                        <img src={logo} alt="InoGo Software" />
                    </Link>
                    <div
                        className={
                            this.state.toggle
                                ? "navbar-burger burger is-active"
                                : "navbar-burger burger"
                        }
                        onClick={() =>
                            this.setState({ toggle: !this.state.toggle })
                        }
                        data-target="navbarExampleTransparentExample"
                        role="button"
                    >
                        <span />
                        <span />
                        <span />
                    </div>
                </div>

                <div
                    id="navbarExampleTransparentExample"
                    className={
                        this.state.toggle
                            ? "navbar-menu is-active"
                            : "navbar-menu"
                    }
                >
                    <div className="navbar-start" />
                    <div className="navbar-end">
                        <Link className="navbar-item" to="/dashboard">
                            <span
                                className="icon"
                                style={{ marginRight: "8px" }}
                            >
                                <i className="fas fa-tachometer-alt" />
                            </span>
                            <span>Dashboard</span>
                        </Link>
                        <Link className="navbar-item" to="/register">
                            <span
                                className="icon"
                                style={{ marginRight: "8px" }}
                            >
                                <i className="fas fa-pen" />
                            </span>
                            <span>Register</span>
                        </Link>
                        <Link className="navbar-item" to="/overview/hours">
                            <span
                                className="icon"
                                style={{ marginRight: "8px" }}
                            >
                                <i className="fas fa-file-download" />
                            </span>
                            <span>Overview</span>
                        </Link>
                        <Link className="navbar-item" to="/clients">
                            <span
                                className="icon"
                                style={{ marginRight: "8px" }}
                            >
                                <i className="fas fa-users" />
                            </span>
                            <span>Clients</span>
                        </Link>
                        <div className="navbar-item has-dropdown is-hoverable is-boxed">
                            <Link
                                to="/settings/profile"
                                className="navbar-link"
                            >
                                <span
                                    className="icon"
                                    style={{ marginRight: "8px" }}
                                >
                                    <i className="fas fa-user-circle" />
                                </span>
                                <span>Account</span>
                            </Link>

                            <div className="navbar-dropdown">
                                <Link
                                    to="/settings/profile"
                                    className="navbar-item"
                                    role="link"
                                >
                                    <span
                                        className="icon"
                                        style={{ marginRight: "8px" }}
                                    >
                                        <i className="fas fa-cog" />
                                    </span>
                                    Settings
                                </Link>
                                <span
                                    className="navbar-item"
                                    role="link"
                                    onClick={() => this.logout()}
                                >
                                    <span
                                        className="icon"
                                        style={{ marginRight: "8px" }}
                                    >
                                        <i className="fas fa-sign-out-alt" />
                                    </span>
                                    Log out
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

NavBar.propTypes = {
    userLogout: PropTypes.func.isRequired,
};

export default connect(null, { userLogout: logout })(NavBar);
