import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Route } from "react-router-dom";
import { NavBar } from "../shared";
import Menu from "./Menu";

import GeneralSection from "./GeneralSection";
import RatesSection from "./RatesSection";
import PaymentSection from "./PaymentSection";

import { setProjectDetail } from "./actions/setDetailPage";
import CreateRateModal from "./CreateRateModal";
import CreateRecurringPaymentModal from "./CreateRecurringPaymentModal";

class ProjectPage extends Component {
    componentDidMount() {
        this.props.setProjectDetail(this.props.match.params.project);
    }

    componentWillUnmount() {
        this.props.setProjectDetail(null);
    }

    render() {
        const { match, history } = this.props;

        if (!this.props.project) {
            return <NavBar history={history} />;
        }

        return (
            <div>
                <NavBar history={history} />

                <div className="container">
                    <div className="box">
                        <Link to="/clients" className="button is-link">
                            back
                        </Link>
                        <h2
                            className="title is-3"
                            style={{ display: "inline", marginLeft: "32px" }}
                        >
                            Project: {this.props.project.name}
                        </h2>
                    </div>
                    <div className=" box">
                        <div className="columns">
                            <Menu match={match} />
                            {this.props.projectsLoading ? (
                                <p>Loading...</p>
                            ) : (
                                <div className="column">
                                    <Route
                                        exact
                                        path={`/projects/${match.params.project}`}
                                        render={() => <GeneralSection />}
                                    />
                                    <Route
                                        path={`/projects/${match.params.project}/types`}
                                        render={() => <RatesSection />}
                                    />
                                    <Route
                                        path={`/projects/${match.params.project}/payments`}
                                        render={() => (
                                            <PaymentSection
                                                projectId={match.params.project}
                                            />
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <CreateRateModal />
                <CreateRecurringPaymentModal />
            </div>
        );
    }
}

ProjectPage.propTypes = {
    setProjectDetail: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    projectsLoading: PropTypes.bool,
    project: PropTypes.object,
};

const mapStateToProps = (state) => ({
    projectsLoading: state.projects.loading,
    project: state.projects.projects.results.find(
        (item) => item.id === state.projects.projectDetailPage
    ),
});

const mapDispatchToProps = {
    setProjectDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);
