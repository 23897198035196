import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LocalForm } from "react-redux-form";

import { Input } from "../../shared";
import { updateGroup } from "../../../actions/group/updateGroup";

export class OrganizationSettingsSection extends Component {
    onSubmit(values) {
        this.props.updateGroup(this.props.group.id, values);
    }

    render() {
        const { group } = this.props;

        const initialState = {
            name: group.name,
            city: group.city,
            address: group.address,
            zipcode: group.zipcode,
            phone: group.phone,
            email: group.email,
            kvk: group.kvk,
            iban: group.iban,
            btw_number: group.btw_number,
        };

        return (
            <div className="column is-6">
                <h1 className="title is-3">Organization settings</h1>
                <hr />
                <div className="content">
                    <LocalForm
                        initialState={initialState}
                        id="companySettings"
                        onSubmit={(profileForm) => this.onSubmit(profileForm)}
                    >
                        <Input label="Company name" id=".name" />
                        <Input label="City" id=".city" />
                        <Input label="Address" id=".address" />
                        <Input label="Zip" id=".zipcode" />
                        <Input label="Phone" id=".phone" type="phone" />
                        <Input label="Email" id=".email" type="email" />
                        <Input label="KvK" id=".kvk" />
                        <Input label="IBAN" id=".iban" />
                        <Input label="BTW Number" id=".btw_number" />

                        <button type="submit" className="button is-success">
                            Update
                        </button>
                    </LocalForm>
                </div>
                <hr />
                <p className="is-size-7">Organization ID: {group.id}</p>
            </div>
        );
    }
}

OrganizationSettingsSection.propTypes = {
    updateGroup: PropTypes.func.isRequired,
    group: PropTypes.object,
};

const mapStateToProps = (state) => ({
    group: state.group,
});

const mapDispatchToProps = {
    updateGroup,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganizationSettingsSection);
