import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => (
    <div
        style={{
            top: "40%",
            position: "absolute",
            width: "100%",
            textAlign: "center",
        }}
    >
        <h1 className="title is-1">Not Found</h1>
        <Link to="/" className="button">
            Go back
        </Link>
    </div>
);

export default PageNotFound;
