/* eslint no-param-reassign: off */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "moment-duration-format";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { setDate } from "./actions";

function toThreeDigits(input) {
    const minutes = String(input.split(":")[1]);
    if (minutes.length !== 2) {
        input += "0";
    }
    return input;
}

class DateList extends Component {
    /**
     * Returns an array of moments with all days of the current week.
     */
    getDaysInWeek() {
        const startOfWeek = this.props.date.clone().startOf("isoWeek");
        const endOfWeek = this.props.date.clone().endOf("isoWeek");

        const days = [];
        let day = startOfWeek;

        while (day <= endOfWeek) {
            days.push(day);
            day = day.clone().add(1, "d");
        }
        return days;
    }

    /**
     * Returns the total hours recorded in the current week.
     */
    getTotalHours() {
        let total = null;
        this.props.weekOverview.forEach((item) => {
            const x = moment.duration(item.total, "H:m");

            if (total === null) {
                total = x;
            } else {
                total = total
                    .add(x.days(), "days")
                    .add(x.hours(), "hours")
                    .add(x.minutes(), "minutes");
            }
        });
        try {
            return toThreeDigits(total.format("H:m"));
        } catch (error) {
            return "0:00";
        }
    }

    isCurrentDay(date) {
        return (
            this.props.date.format("YYYY-MM-DD") === date.format("YYYY-MM-DD")
        );
    }

    render() {
        if (!this.props.weekOverview) {
            return (
                <span className="icon is-large">
                    <i className="fas fa-spinner fa-spin" />
                </span>
            );
        }

        const daysElement = this.getDaysInWeek().map((item) => {
            const combined = this.props.weekOverview.find(
                (week) => week.date === item.format("YYYY-MM-DD")
            );
            const total = combined
                ? `${toThreeDigits(combined.total)}`
                : "0:00";

            return (
                <div
                    role="button"
                    className="level-item has-text-centered"
                    key={item.toJSON()}
                    style={{
                        borderRadius: "4px",
                        cursor: "pointer",
                    }}
                    onClick={() =>
                        this.isCurrentDay(item)
                            ? null
                            : this.props.setDate(item, this.props.date)
                    }
                >
                    <div>
                        <p
                            className={
                                this.isCurrentDay(item)
                                    ? "heading has-text-link"
                                    : "heading"
                            }
                            style={{
                                fontWeight: this.isCurrentDay(item)
                                    ? "700"
                                    : "300",
                            }}
                        >
                            {item.format("ddd")} {item.format("D")}
                        </p>
                        <p
                            className={
                                this.isCurrentDay(item) ? "has-text-link" : null
                            }
                            style={{
                                fontWeight: this.isCurrentDay(item)
                                    ? "700"
                                    : "300",
                            }}
                        >
                            {total}
                        </p>
                    </div>
                </div>
            );
        });

        return (
            <div className="level box">
                {daysElement}
                {!isMobile && (
                    <div
                        style={{
                            borderLeft: "2px solid #dadada",
                            height: "45px",
                            marginLeft: "20px",
                        }}
                    />
                )}

                <div className="level-item has-text-centered">
                    <div>
                        <p className="is-bold">Total</p>
                        <p>{this.getTotalHours()}</p>
                    </div>
                </div>
            </div>
        );
    }
}

DateList.propTypes = {
    date: PropTypes.object.isRequired,
    setDate: PropTypes.func.isRequired,
    weekOverview: PropTypes.array,
};

const mapStateToProps = (state) => ({
    date: state.register.date,
    weekOverview: state.hours.weekView.results,
});

const mapDispatchToProps = { setDate };

export default connect(mapStateToProps, mapDispatchToProps)(DateList);
