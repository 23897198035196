import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import axios from "axios";
import { toast } from "react-toastify";
import { getOverviewHours } from "../actions";
import { Table } from "../../shared";
import { getUrl } from "../../../getBackend";

class OverviewTable extends Component {
    componentWillMount() {
        this.props.getOverviewHours(this.props.month, this.props.year);
    }

    getProject(id) {
        return this.props.projects.find((item) => item.id === id).name;
    }

    getUser(id) {
        return this.props.members.find((item) => item.id === id).first_name;
    }

    isChecked(id) {
        return !!this.props.checked.find((item) => item === id);
    }

    render() {
        if (
            this.props.hoursLoading ||
            this.props.groupLoading ||
            this.props.projectsLoading
        ) {
            return <p>Loading...</p>;
        }

        if (this.props.hours.length === 0) {
            return (
                <div>
                    <span>No data.</span>
                </div>
            );
        }

        let filteredHours = this.props.hours;

        // Filter employee
        if (this.props.selectedEmployee) {
            filteredHours = filteredHours.filter(
                (hour) => hour.user === this.props.selectedEmployee
            );
        }

        // Filter client
        if (this.props.selectedClient) {
            filteredHours = filteredHours.filter(
                (hour) => hour.client.id === this.props.selectedClient
            );
        }

        // Filter project
        if (this.props.selectedProject) {
            filteredHours = filteredHours.filter(
                (hour) => hour.project === this.props.selectedProject
            );
        }

        // Filter status
        if (this.props.selectedStatus) {
            filteredHours = filteredHours.filter(
                (hour) => hour.status === this.props.selectedStatus
            );
        }

        const content = filteredHours.map((hour) => (
            <tr
                key={hour.id}
                className={this.isChecked(hour.id) ? "is-selected" : null}
                style={{ fontSize: "80%" }}
            >
                <td>{hour.date}</td>
                <td>{this.getUser(hour.user)}</td>
                <td>{hour.client.name}</td>
                <td>{this.getProject(hour.project)}</td>
                <td>{hour.comment}</td>
                <td>{hour.duration.slice(0, 5)}</td>
                <td>
                    {hour.invoice
                        ? `Invoice ${
                              this.props.invoices.find(
                                  (item) => item.id === hour.invoice
                              ).number
                          }`
                        : this.props.projects.find(
                              (project) => project.id === hour.project
                          ).next_invoice}
                </td>
                <td>
                    <button
                        type="button"
                        className="button is-small is-link"
                        onClick={() =>
                            axios
                                .post(
                                    `${getUrl()}/api/v1/hours/${
                                        hour.id
                                    }/tempo_export/?date=${hour.date}`
                                )
                                .then(() =>
                                    toast("Exported to Tempo", {
                                        type: "success",
                                    })
                                )
                                .catch(() =>
                                    toast("Unable to export to Tempo", {
                                        type: "error",
                                    })
                                )
                        }
                        disabled={hour.exported_on !== null}
                    >
                        Tempo export
                    </button>
                </td>
            </tr>
        ));

        return (
            <Table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Employee</th>
                        <th>Client</th>
                        <th>Project</th>
                        <th>Comment</th>
                        <th>Hours</th>
                        <th>Status</th>
                        <th>Export</th>
                    </tr>
                </thead>
                <tbody>{content}</tbody>
            </Table>
        );
    }
}

OverviewTable.propTypes = {
    getOverviewHours: PropTypes.func.isRequired,
    month: PropTypes.number,
    year: PropTypes.number,
    hours: PropTypes.array,
    projects: PropTypes.array,
    members: PropTypes.array,
    checked: PropTypes.array,
    hoursLoading: PropTypes.bool,
    groupLoading: PropTypes.bool,
    projectsLoading: PropTypes.bool,
    selectedEmployee: PropTypes.string,
    selectedClient: PropTypes.string,
    selectedProject: PropTypes.string,
    selectedStatus: PropTypes.string,
    invoices: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    month: state.overview.month,
    year: state.overview.year,
    hours: state.overview.hours,
    members: state.group.members,
    projects: state.projects.projects.results,
    checked: state.overview.checked,
    hoursLoading: state.hours.getLoading,
    groupLoading: state.group.getGroupLoading,
    projectsLoading: state.projects.loading,
    selectedEmployee: state.overview.selectedEmployee,
    selectedClient: state.overview.selectedClient,
    selectedProject: state.overview.selectedProject,
    selectedStatus: state.overview.selectedStatus,
    invoices: state.invoices.invoices.results.sort(
        (a, b) => a.number < b.number
    ),
});

const mapDispatchToProps = { getOverviewHours };

export default connect(mapStateToProps, mapDispatchToProps)(OverviewTable);
