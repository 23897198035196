import React from "react";
import PropTypes from "prop-types";
import NavBar from "./NavBar";

export const LoadingSpinner = () => (
    <span className="icon is-large" style={{ width: "100%" }}>
        <i className="fas fa-spinner fa-spin" />
    </span>
);

export const LoadingPage = ({ history }) => (
    <div>
        <NavBar history={history} />

        <div className="container box">
            <LoadingSpinner />
        </div>
    </div>
);

LoadingPage.propTypes = {
    history: PropTypes.object.isRequired,
};
