export const REGISTER_SET_COMMENT_TIME = "REGISTER_SET_COMMENT_TIME";

const setComment = (comment) => ({
    type: REGISTER_SET_COMMENT_TIME,
    payload: comment,
});

export function setCommentTime(comment) {
    return (dispatch) => {
        dispatch(setComment(comment));
    };
}
