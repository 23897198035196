export const REMOVE_PROJECT_FROM_CLIENT = "REMOVE_PROJECT_FROM_CLIENT";

export const removeProject = (data) => ({
    type: REMOVE_PROJECT_FROM_CLIENT,
    payload: { ...data },
});

export function removeProjectFromClient(clientId, projectId) {
    return (dispatch) => {
        dispatch(removeProject({ clientId, projectId }));
    };
}
