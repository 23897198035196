import React, { Component } from "react";
import { LocalForm } from "react-redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { createUser } from "../../actions/user/create";
import { HorizontalInput } from "../shared";

class CreateAccountPage extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(form) {
        if (form.password !== form.confirm_password) {
            alert("Passwords don't match");
            return;
        }
        this.props.createUser(form);
    }

    render() {
        return (
            <section className="hero is-fullheight">
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <div className="column is-6 is-offset-3">
                            <h1 className="title is-2">Sign up</h1>
                            <div className="box">
                                <LocalForm
                                    onSubmit={(values) => this.onSubmit(values)}
                                >
                                    <h2 className="subtitle">
                                        Personal details
                                    </h2>

                                    <HorizontalInput
                                        id=".first_name"
                                        label="First name"
                                    />
                                    <HorizontalInput
                                        id=".last_name"
                                        label="Last name"
                                    />
                                    <HorizontalInput
                                        id=".email"
                                        label="Email"
                                    />
                                    <HorizontalInput
                                        id=".password"
                                        label="Password"
                                        type="password"
                                    />
                                    <HorizontalInput
                                        id=".confirm_password"
                                        label="Confirm password"
                                        type="password"
                                    />

                                    <h2 className="subtitle">
                                        Company details
                                    </h2>

                                    <HorizontalInput
                                        id=".name"
                                        label="Name"
                                        placeholder="Company name"
                                    />
                                    <HorizontalInput
                                        id=".address"
                                        label="Address"
                                    />
                                    <HorizontalInput
                                        id=".zipcode"
                                        label="Zip"
                                    />
                                    <HorizontalInput id=".city" label="City" />
                                    <HorizontalInput
                                        id=".phone"
                                        label="Phone"
                                        type="phone"
                                    />
                                    <HorizontalInput id=".kvk" label="KvK" />
                                    <HorizontalInput id=".iban" label="IBAN" />
                                    <HorizontalInput
                                        id=".btw_number"
                                        label="Tax number"
                                    />

                                    <div className="field is-horizontal">
                                        <div className="field-label" />
                                        <div className="field-body">
                                            <div className="field">
                                                <div className="control">
                                                    <button
                                                        className="button is-fullwidth is-primary"
                                                        type="submit"
                                                    >
                                                        Sign Up
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </LocalForm>
                            </div>
                            <Link to="/login" className="blacklink">
                                Back
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

CreateAccountPage.propTypes = {
    createUser: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { createUser })(CreateAccountPage);
