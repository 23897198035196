import * as actions from "./actions";

import { createReducer } from "../../../reducers/reducerUtilities";

const initialState = {
    getLoading: false,
    getLoadingError: null,
    fetched: false,
    updatingStatus: false,
    updatingStatusError: null,
    hourSummaryModalToggled: false,
    hourSummaryLoading: false,
    hourSummaryError: null,
    hourSummary: {
        hours: [],
    },
    createInvoiceModalToggled: false,
    creatingInvoice: false,
    creatingInvoiceError: null,
    invoices: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
};

function toggleCreateInvoiceModal(state) {
    return {
        ...state,
        createInvoiceModalToggled: !state.createInvoiceModalToggled,
    };
}

function toggleHourSummaryModal(state) {
    return {
        ...state,
        hourSummaryModalToggled: !state.hourSummaryModalToggled,
    };
}

function getInvoicesBegin(state) {
    return {
        ...state,
        getLoading: true,
    };
}

function getInvoicesSuccess(state, action) {
    return {
        ...state,
        getLoading: false,
        fetched: true,
        invoices: action.payload,
    };
}

function getInvoicesFailure(state) {
    return {
        ...state,
        getLoading: false,
        getLoadingError: false,
    };
}

function updateStatusBegin(state) {
    return {
        ...state,
        updatingStatus: true,
    };
}

function updateStatusSuccess(state, action) {
    return {
        ...state,
        updatingStatus: false,
        invoices: {
            ...state.invoices,
            results: state.invoices.results.map((invoice) =>
                action.payload.ids.includes(invoice.id)
                    ? { ...invoice, status: action.payload.status }
                    : invoice
            ),
        },
    };
}

function updateStatusFailure(state, action) {
    return {
        ...state,
        updatingStatus: false,
        updatingStatusError: action.payload,
    };
}

function createInvoiceBegin(state) {
    return {
        ...state,
        creatingInvoice: true,
    };
}
function createInvoiceSuccess(state) {
    return {
        ...state,
        creatingInvoice: false,
        creatingInvoiceError: null,
    };
}
function createInvoiceFailure(state, action) {
    return {
        ...state,
        creatingInvoice: false,
        creatingInvoiceError: action.payload,
    };
}

function getHourSummaryBegin(state) {
    return {
        ...state,
        hourSummaryLoading: true,
    };
}
function getHourSummarySuccess(state, action) {
    return {
        ...state,
        hourSummaryLoading: false,
        hourSummary: action.payload,
    };
}
function getHourSummaryFailure(state, action) {
    return {
        ...state,
        hourSummaryLoading: false,
        hourSummaryError: action.payload,
    };
}

function resetHourSummaryData(state) {
    return {
        ...state,
        hourSummary: {
            hours: [],
        },
    };
}

const handlers = {};
handlers[actions.TOGGLE_CREATE_INVOICE_MODAL] = toggleCreateInvoiceModal;
handlers[actions.TOGGLE_HOUR_SUMMARY_MODAL] = toggleHourSummaryModal;
handlers[actions.GET_INVOICES_BEGIN] = getInvoicesBegin;
handlers[actions.GET_INVOICES_SUCCESS] = getInvoicesSuccess;
handlers[actions.GET_INVOICES_FAILURE] = getInvoicesFailure;
handlers[actions.INVOICE_CHANGE_STATUS_BEGIN] = updateStatusBegin;
handlers[actions.INVOICE_CHANGE_STATUS_SUCCESS] = updateStatusSuccess;
handlers[actions.INVOICE_CHANGE_STATUS_FAILURE] = updateStatusFailure;
handlers[actions.CREATE_INVOICES_BEGIN] = createInvoiceBegin;
handlers[actions.CREATE_INVOICES_SUCCESS] = createInvoiceSuccess;
handlers[actions.CREATE_INVOICES_FAILURE] = createInvoiceFailure;
handlers[actions.GET_HOUR_SUMMARY_BEGIN] = getHourSummaryBegin;
handlers[actions.GET_HOUR_SUMMARY_SUCCESS] = getHourSummarySuccess;
handlers[actions.GET_HOUR_SUMMARY_FAILURE] = getHourSummaryFailure;
handlers[actions.RESET_HOUR_SUMMARY_DATA] = resetHourSummaryData;

const clientsReducer = createReducer(initialState, handlers);

export default clientsReducer;
