export const REGISTER_SET_BREAK_TIME = "REGISTER_SET_BREAK_TIME";

const setTime = (time) => ({
    type: REGISTER_SET_BREAK_TIME,
    payload: time,
});

export function setBreakTime(time) {
    return (dispatch) => {
        dispatch(setTime(time));
    };
}
