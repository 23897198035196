import {
    DASHBOARD_GET_DATA_BEGIN,
    DASHBOARD_GET_DATA_FAILURE,
    DASHBOARD_GET_DATA_SUCCESS,
} from "./actions";
import { createReducer } from "../../reducers/reducerUtilities";

const initialState = {
    dashboardDataLoading: false,
    dashboardDataError: null,
    hoursPerProject: {
        data: [],
        labels: [],
    },
    revenuePerMonth: {
        data: [],
        labels: [],
    },
    unbilledAmount: [],
    outstandingInvoices: 0,
};

function getDashboardDataBegin(state) {
    return {
        ...state,
        dashboardDataLoading: true,
    };
}

function getDashboardDataSuccess(state, action) {
    const hoursPerProject = action.payload.hours_per_project;
    const revenuePerMonth = action.payload.revenue_per_month;
    return {
        ...state,
        dashboardDataLoading: false,
        hoursPerProject: {
            data: hoursPerProject.map((item) => item.hours),
            labels: hoursPerProject.map((item) => item.project_name),
        },
        revenuePerMonth: {
            data: revenuePerMonth,
            labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        unbilledAmount: action.payload.unbilled_amount,
        outstandingInvoices: action.payload.outstanding_invoices,
    };
}

function getDashboardDataFailure(state, action) {
    return {
        ...state,
        dashboardDataLoading: false,
        dashboardDataError: action.payload,
    };
}

const handlers = {};
handlers[DASHBOARD_GET_DATA_BEGIN] = getDashboardDataBegin;
handlers[DASHBOARD_GET_DATA_SUCCESS] = getDashboardDataSuccess;
handlers[DASHBOARD_GET_DATA_FAILURE] = getDashboardDataFailure;

const reducer = createReducer(initialState, handlers);

export default reducer;
