import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { createHours } from "../../../actions/hours/createHour";

export class SubmitButton extends Component {
    render() {
        return (
            <button
                type="button"
                className="button is-info is-fullwidth"
                disabled={
                    !this.props.selectedClientId ||
                    !this.props.selectedProjectId ||
                    !this.props.time.total ||
                    this.props.loading
                }
                onClick={() => {
                    this.props.createHours(
                        {
                            ...this.props.time,
                            projectId: this.props.selectedProjectId,
                            date: this.props.date.format("YYYY-MM-DD"),
                            rate: this.props.rate,
                        },
                        this.props.userId,
                        this.props.date.clone(),
                        this.props.rates.find(
                            (item) => item.id === this.props.rate
                        )
                    );
                }}
            >
                Submit
            </button>
        );
    }
}

SubmitButton.propTypes = {
    createHours: PropTypes.func.isRequired,
    selectedClientId: PropTypes.string,
    selectedProjectId: PropTypes.string,
    userId: PropTypes.string,
    rate: PropTypes.string,
    time: PropTypes.object,
    date: PropTypes.object,
    rates: PropTypes.array,
};

const mapStateToProps = (state) => ({
    loading: state.hours.getLoading || state.hours.createLoading,
    selectedClientId: state.register.client,
    selectedProjectId: state.register.project,
    userId: state.user.id,
    time: state.register.time,
    date: state.register.date,
    rate: state.register.rate,
    rates: state.projects.projects.results.find(
        (item) => item.id === state.register.project
    )
        ? state.projects.projects.results.find(
              (item) => item.id === state.register.project
          ).rates
        : null,
});

const mapDispatchToProps = { createHours };

export default connect(mapStateToProps, mapDispatchToProps)(SubmitButton);
