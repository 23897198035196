import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Control, Form } from "react-redux-form";

import { Input, Modal, ModalActionButton } from "../../shared";
import { createInvoice, toggleCreateInvoiceModal } from "./actions";

class CreateInvoiceModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {
        const vals = { ...values };
        const client = this.props.clients.find((c) => c.id === values.client);
        if (!client.projects.includes(values.project)) {
            // eslint-disable-next-line no-console
            console.log("Project/client mismatch.");
            vals.project = client.projects[0];
        }

        this.props.createInvoice(vals);
    }

    render() {
        const clientsList = this.props.clients.map((client) => (
            <option value={client.id} key={client.id}>
                {client.name}
            </option>
        ));

        const projectList = this.props.projects
            .filter(
                (project) => project.client_id === this.props.selectedClient
            )
            .map((project) => (
                <option value={project.id} key={project.id}>
                    {project.name}
                </option>
            ));

        const taxAmount =
            parseFloat(this.props.net) *
            parseFloat(this.props.tax_percentage / 100);
        const grossAmount = (taxAmount + parseFloat(this.props.net)).toFixed(2);

        return (
            <Modal
                closeModal={this.props.toggleCreateInvoiceModal}
                modalState={this.props.modalState}
                title="Create invoice"
                footerButtons={
                    <ModalActionButton
                        form="rateCreateForm"
                        text="Create invoice"
                    />
                }
            >
                <Form
                    model="createInvoiceModal"
                    onSubmit={(values) => this.handleSubmit(values)}
                    id="rateCreateForm"
                >
                    <div className="field">
                        <label className="label">Client</label>

                        <div className="control">
                            <Control.select
                                defaultValue={
                                    this.props.clients.length > 0
                                        ? this.props.clients[0].id
                                        : null
                                }
                                model=".client"
                                className="input"
                                id="client"
                                required
                            >
                                {clientsList}
                            </Control.select>
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Project</label>

                        <div className="control">
                            <Control.select
                                defaultValue={
                                    this.props.projects.length > 0
                                        ? this.props.projects[0].id
                                        : null
                                }
                                model=".project"
                                className="input"
                                id="project"
                                required
                            >
                                {projectList}
                            </Control.select>
                        </div>
                    </div>

                    <Input
                        label="Invoice date"
                        id=".date"
                        type="date"
                        required
                    />
                    <Input
                        label="Activity"
                        id=".activity"
                        type="text"
                        required
                    />
                    <Input
                        label="Net amount"
                        id=".amount_net"
                        type="number"
                        step="0.01"
                        required
                    />
                    <Input
                        label="Tax percentage"
                        id=".tax_percentage"
                        type="number"
                        min="0"
                        max="100"
                        required
                    />
                    <Input
                        label="Gross amount"
                        id="gross_amount"
                        type="number"
                        step="0.01"
                        value={grossAmount}
                        readOnly
                    />
                    <Input
                        label="Start date"
                        id=".hours_from"
                        type="date"
                        required
                    />
                    <Input
                        label="End date"
                        id=".hours_to"
                        type="date"
                        required
                    />
                </Form>
            </Modal>
        );
    }
}

CreateInvoiceModal.propTypes = {
    toggleCreateInvoiceModal: PropTypes.func.isRequired,
    createInvoice: PropTypes.func.isRequired,
    modalState: PropTypes.bool,
    clients: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    net: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    tax_percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    selectedClient: PropTypes.string,
};

const mapStateToProps = (state) => ({
    modalState: state.invoices.createInvoiceModalToggled,
    clients: state.clients.clients.results,
    projects: state.projects.projects.results,
    net: state.createInvoiceModal.amount_net || 0,
    tax_percentage: state.createInvoiceModal.tax_percentage || 0,
    selectedClient: state.createInvoiceModal.client,
});

const mapDispatchToProps = { toggleCreateInvoiceModal, createInvoice };

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoiceModal);
