import React, { Component } from "react";
import { LocalForm, Control } from "react-redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";
import { login } from "../../actions/user/login";
import { Input } from "../shared";

import logo from "../../logo.svg";

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(form) {
        this.props.userLogin(form);
    }

    render() {
        const { loggedIn } = this.props;

        if (loggedIn) {
            return <Redirect to="/" />;
        }

        return (
            <section className="hero is-fullheight">
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <div className="column is-4 is-offset-4">
                            <div className="box">
                                <figure
                                    className="image"
                                    style={{ padding: "64px" }}
                                >
                                    <img src={logo} alt="InoGo Software" />
                                </figure>
                                <LocalForm
                                    onSubmit={(values) => this.onSubmit(values)}
                                >
                                    <Input
                                        id=".username"
                                        type="email"
                                        placeholder="Email"
                                    />
                                    <Input
                                        id=".password"
                                        type="password"
                                        placeholder="Password"
                                    />

                                    <div className="field">
                                        <Control.checkbox
                                            className="is-checkradio"
                                            model=".remember"
                                            id=".remember"
                                            type="checkbox"
                                            name=".remember"
                                            checked="checked"
                                        />
                                        <label htmlFor=".remember">
                                            Remember me
                                        </label>
                                    </div>
                                    <button
                                        type="submit"
                                        className="button is-block is-info is-fullwidth"
                                        style={{ marginTop: "32px" }}
                                    >
                                        Login
                                    </button>
                                </LocalForm>
                                <div
                                    style={{
                                        height: "22px",
                                        marginTop: "14px",
                                    }}
                                >
                                    <Link
                                        to="/create-account"
                                        style={{ float: "left" }}
                                        className="help blacklink"
                                    >
                                        Create account
                                    </Link>
                                    <Link
                                        to="/forgot-password"
                                        className="help blacklink"
                                        style={{ float: "right" }}
                                    >
                                        Forgot password
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

LoginPage.propTypes = {
    userLogin: PropTypes.func.isRequired,
    loggedIn: PropTypes.string,
};

const mapStateToProps = (state) => ({
    loggedIn: state.user.token,
});

export default connect(mapStateToProps, { userLogin: login })(LoginPage);
