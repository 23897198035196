import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setEmployee } from "../../actions";

class EmployeeButton extends Component {
    getFullName(employee) {
        if (!employee) {
            return "Not selected";
        }
        return `${employee.first_name} ${employee.last_name}`;
    }

    render() {
        const employeeList = this.props.members.map((employee) => (
            <span
                className="dropdown-item"
                key={employee.id}
                onClick={() => this.props.setEmployee(employee.id)}
            >
                {`${employee.first_name} ${employee.last_name}`}
            </span>
        ));
        return (
            <div
                className="dropdown is-hoverable"
                style={{ paddingRight: "8px" }}
            >
                <div className="dropdown-trigger">
                    <button
                        className="button"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu"
                        type="button"
                    >
                        <span>
                            {this.getFullName(
                                this.props.members.find(
                                    (member) =>
                                        member.id ===
                                        this.props.selectedEmployee
                                )
                            )}
                        </span>
                        <span className="icon is-small">
                            <i
                                className="fas fa-angle-down"
                                aria-hidden="true"
                            />
                        </span>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                        <span
                            className="dropdown-item"
                            onClick={() => this.props.setEmployee(null)}
                        >
                            Not selected
                        </span>
                        {employeeList}
                    </div>
                </div>
            </div>
        );
    }
}

EmployeeButton.propTypes = {
    setEmployee: PropTypes.func.isRequired,
    members: PropTypes.array,
    selectedEmployee: PropTypes.string,
};

const mapStateToProps = (state) => ({
    members: state.group.members,
    selectedEmployee: state.overview.selectedEmployee,
});

const mapDispatchToProps = { setEmployee };

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeButton);
