import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../getBackend";

import { toggleCreateRateModal } from "./toggleCreateRateModal";

export const CREATE_RATE_BEGIN = "CREATE_RATE_BEGIN";
export const CREATE_RATE_SUCCESS = "CREATE_RATE_SUCCESS";
export const CREATE_RATE_FAILURE = "CREATE_RATE_FAILURE";

export const createRateBegin = () => ({
    type: CREATE_RATE_BEGIN,
});

export const createRateSuccess = (data) => ({
    type: CREATE_RATE_SUCCESS,
    payload: { ...data },
});

export const createRateFailure = (error) => ({
    type: CREATE_RATE_FAILURE,
    payload: { error },
});

export function createRate(values) {
    return (dispatch) => {
        dispatch(createRateBegin());

        axios({
            method: "post",
            url: `${getUrl()}/api/v1/rates/`,
            data: { ...values },
        })
            .then((res) => {
                dispatch(createRateSuccess(res.data));
                dispatch(toggleCreateRateModal());

                toast("Rate created succesfully!", {
                    type: "success",
                });
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to create rate.", { type: "error" });
                dispatch(createRateFailure(error));
            });
    };
}
