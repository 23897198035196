import {
    GET_USER_DETAILS_BEGIN,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAILURE,
} from "../actions/user/getDetails";

import {
    USER_LOGIN_BEGIN,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAILURE,
} from "../actions/user/login";

import {
    USER_UPDATE_PROFILE_BEGIN,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_FAILURE,
} from "../actions/user/updateProfile";

import { setAuthenticationToken } from "../setAuthenticationToken";

// Get the token from the storage.
const token =
    sessionStorage.getItem("token") || localStorage.getItem("token") || null;

// Set the axios header.
if (token) {
    setAuthenticationToken(token);
}

const initialState = {
    token,
    user_id: null,
    email: null,
    username: null,
    first_name: null,
    last_name: null,
    loading: false,
    error: null,
    group_id: null,
    id: null,
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case USER_LOGIN_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action.payload,
            };

        case USER_LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_USER_DETAILS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action.payload,
            };

        case GET_USER_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case USER_UPDATE_PROFILE_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case USER_UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action.payload,
            };

        case USER_UPDATE_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        default:
            return state;
    }
}
