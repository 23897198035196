import React from "react";
import PropTypes from "prop-types";

const Table = ({ children }) => (
    <div style={{ overflowX: "auto" }}>
        <table className="table is-fullwidth is-hoverable">{children}</table>
    </div>
);

Table.propTypes = {
    children: PropTypes.array,
};

export default Table;
