import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setClient } from "../../actions";

class ClientButton extends Component {
    getClientName(client) {
        if (!client) {
            return "Not selected";
        }
        return client.name;
    }

    render() {
        const clientList = this.props.clients.map((client) => (
            <span
                className="dropdown-item"
                key={client.id}
                onClick={() => this.props.setClient(client.id)}
            >
                {client.name}
            </span>
        ));
        return (
            <div
                className="dropdown is-hoverable"
                style={{ paddingRight: "8px" }}
            >
                <div className="dropdown-trigger">
                    <button
                        className="button"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu"
                        type="button"
                    >
                        <span>
                            {this.getClientName(
                                this.props.clients.find(
                                    (client) =>
                                        client.id === this.props.selectedClient
                                )
                            )}
                        </span>
                        <span className="icon is-small">
                            <i
                                className="fas fa-angle-down"
                                aria-hidden="true"
                            />
                        </span>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                        <span
                            className="dropdown-item"
                            onClick={() => this.props.setClient(null)}
                        >
                            Not selected
                        </span>
                        {clientList}
                    </div>
                </div>
            </div>
        );
    }
}

ClientButton.propTypes = {
    setClient: PropTypes.func.isRequired,
    clients: PropTypes.array,
    selectedClient: PropTypes.string,
};

const mapStateToProps = (state) => ({
    clients: state.clients.clients.results,
    selectedClient: state.overview.selectedClient,
});

const mapDispatchToProps = { setClient };

export default connect(mapStateToProps, mapDispatchToProps)(ClientButton);
