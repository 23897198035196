import React, { Component } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getUserData } from "../../actions/user/getDetails";
import { NavBar, LoadingPage } from "../shared";
import Menu from "./Menu";
import { AccountSection } from "./AccountSection";
import { ProfileSection } from "./ProfileSection";
import { MembersSection } from "./MembersSection";
import { InviteSection } from "./InviteSection";
import { InvoiceSection } from "./InvoiceSection";
import { OrganizationSettingsSection } from "./OrganizationSettingsSection";

class Account extends Component {
    componentWillMount() {
        this.props.getUserData();
    }

    render() {
        const { match, groupLoading, history } = this.props;

        if (groupLoading) {
            return <LoadingPage history={history} />;
        }

        return (
            <div>
                <NavBar history={history} />

                <div className="container box">
                    <div className="columns">
                        <Menu />
                        <div className="column">
                            <Route
                                path={`${match.path}/profile`}
                                render={() => <ProfileSection />}
                            />
                            <Route
                                path={`${match.path}/admin`}
                                render={() => <AccountSection />}
                            />
                            <Route
                                path={`${match.path}/organization/settings`}
                                render={() => <OrganizationSettingsSection />}
                            />
                            <Route
                                path={`${match.path}/organization/invoices`}
                                render={() => <InvoiceSection />}
                            />
                            <Route
                                path={`${match.path}/organization/members`}
                                render={() => <MembersSection />}
                            />
                            <Route
                                path={`${match.path}/organization/invite`}
                                render={() => <InviteSection />}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Account.propTypes = {
    getUserData: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    groupLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    groupLoading: state.group.getGroupLoading,
});

const mapDispatchToProps = { getUserData };

export default connect(mapStateToProps, mapDispatchToProps)(Account);
