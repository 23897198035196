import moment from "moment";

export const getColorById = (id: string): string => {
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
        hash = id.charCodeAt(i) + ((hash << 5) - hash);
    }

    let colour = "#";
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xff;
        colour += ("00" + value.toString(16)).substr(-2);
    }

    return colour;
};

export const getYearList = (): Number[] => {
    const years: number[] = [];
    const currentYear = moment().year();

    for (let i = 2018; i <= currentYear; i++) {
        years.push(i);
    }

    return years;
};
