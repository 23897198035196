export const CREATE_CLIENT_MODAL_TOGGLE = "CREATE_CLIENT_MODAL_TOGGLE";

export const toggleCreateClientModalBegin = () => ({
    type: CREATE_CLIENT_MODAL_TOGGLE,
});

export function toggleCreateClientModal() {
    return (dispatch) => {
        dispatch(toggleCreateClientModalBegin());
    };
}
