import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { removeClient } from "./actions/removeClient";

import { toggleCreateProjectModal } from "../ProjectPage/actions/toggleCreateProjectModal";
import { toggleEditClientModal } from "./actions/toggleEditClientModal";

class Actions extends Component {
    render() {
        return (
            <div className="dropdown is-hoverable">
                <div className="dropdown-trigger">
                    <button
                        type="button"
                        className="button is-link"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu"
                    >
                        <span>Actions</span>
                        <span className="icon is-small">
                            <i
                                className="fas fa-angle-down"
                                aria-hidden="true"
                            />
                        </span>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                        <span
                            role="button"
                            className="dropdown-item"
                            onClick={this.props.toggleEditClientModal}
                        >
                            Edit client
                        </span>
                        <span
                            role="button"
                            className="dropdown-item"
                            onClick={this.props.toggleCreateProjectModal}
                        >
                            Create project
                        </span>
                        <hr className="dropdown-divider" />
                        <span
                            role="button"
                            className="dropdown-item"
                            onClick={() =>
                                confirmAlert({
                                    title: "Confirm to submit",
                                    message: "Are you sure to do this.",
                                    buttons: [
                                        {
                                            label: "No",
                                        },
                                        {
                                            label: "Yes",
                                            onClick: () =>
                                                this.props.removeClient(
                                                    this.props.selectedId,
                                                    this.props.clients.find(
                                                        (client) =>
                                                            client.id ===
                                                            this.props
                                                                .selectedId
                                                    ).projects
                                                ),
                                        },
                                    ],
                                })
                            }
                        >
                            <span className="has-text-danger">
                                Remove client
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

Actions.propTypes = {
    removeClient: PropTypes.func.isRequired,
    toggleCreateProjectModal: PropTypes.func.isRequired,
    toggleEditClientModal: PropTypes.func.isRequired,
    selectedId: PropTypes.string,
    clients: PropTypes.array,
};

const mapStateToProps = (state) => ({
    selectedId: state.clients.selected,
    clients: state.clients.clients.results,
});

export default connect(mapStateToProps, {
    removeClient,
    toggleCreateProjectModal,
    toggleEditClientModal,
})(Actions);
