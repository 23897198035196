export const RESET_HOURS = "RESET_HOURS";

export const resetHoursBegin = () => ({
    type: RESET_HOURS,
});

export function resetHours() {
    return (dispatch) => {
        dispatch(resetHoursBegin());
    };
}
