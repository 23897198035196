export const REGISTER_SELECT_RATE = "REGISTER_SELECT_RATE";

const setRate = (id) => ({
    type: REGISTER_SELECT_RATE,
    payload: id,
});

export function selectRate(id) {
    return (dispatch) => {
        dispatch(setRate(id));
    };
}
