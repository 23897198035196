import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import ControlBox from "./ControlBox";
import OverviewTable from "./OverviewTable";
// import Actions from './Actions';

export class HoursOverview extends Component {
    render() {
        const { month } = this.props;
        return (
            <div>
                <div style={{ paddingBottom: "32px" }}>
                    <h2
                        className="title is-size-3"
                        style={{ display: "inline" }}
                    >
                        {moment.months(month)}
                    </h2>
                    <div style={{ display: "inline", float: "right" }}>
                        {/* <Actions /> */}
                    </div>
                </div>
                <ControlBox />
                <OverviewTable />
            </div>
        );
    }
}

HoursOverview.propTypes = {
    month: PropTypes.number,
};

const mapStateToProps = (state) => ({
    year: state.overview.year,
    month: state.overview.month,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HoursOverview);
