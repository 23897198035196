import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NavBar } from "../shared";
import { getDashboardData } from "./actions";
import { YearOverviewBarChart } from "./YearOverviewBarChart";
import { getYearList } from "../../shared/functions";
import moment from "moment";

class DashboardPage extends Component {
    /**
     * min and max included
     */
    static randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    constructor(props) {
        super(props);
        this.getColor = this.getColor.bind(this);
        this.getData = this.getData.bind(this);

        this.state = {
            year: moment().year(),
        };

        this.getData();

        this.colors = {};
        for (let i = 0; i < 10; i += 1) {
            this.colors[i] = (alpha) =>
                `rgba(${DashboardPage.randomIntFromInterval(
                    0,
                    255
                )}, ${DashboardPage.randomIntFromInterval(
                    0,
                    255
                )},${DashboardPage.randomIntFromInterval(0, 255)}, ${alpha})`;
        }
    }

    getData(year) {
        this.props.getDashboardData(year || this.state.year);
    }

    getColor(idx, alpha) {
        if (idx === 0) {
            return `rgba(50,115,220,${alpha})`;
        }
        if (idx === 1) {
            return `rgba(0,209,178,${alpha})`;
        }
        return this.colors[idx](alpha);
    }

    render() {
        const { history } = this.props;

        if (!this.props.users.length) {
            return null;
        }

        return (
            <div>
                <NavBar history={history} />
                <div className="container box" style={{ marginBottom: "2em" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <h2 className="title is-size-3 has-text-centered">
                            Revenue / Month
                        </h2>
                        <div
                            className="dropdown is-hoverable"
                            style={{ marginLeft: "8px" }}
                        >
                            <div className="dropdown-trigger">
                                <button
                                    className="button"
                                    aria-haspopup="true"
                                    aria-controls="dropdown-menu"
                                    type="button"
                                >
                                    <span>{this.state.year}</span>
                                    <span className="icon is-small">
                                        <i
                                            className="fas fa-angle-down"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </button>
                            </div>
                            <div
                                className="dropdown-menu"
                                id="dropdown-menu"
                                role="menu"
                            >
                                <div className="dropdown-content">
                                    {getYearList().map((item) => (
                                        <span
                                            className="dropdown-item"
                                            key={item}
                                            onClick={() => {
                                                this.setState({
                                                    year: item,
                                                });
                                                this.getData(item);
                                            }}
                                        >
                                            {item}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column">
                            <YearOverviewBarChart
                                users={this.props.users}
                                revenuePerMonth={
                                    this.props.revenuePerMonth.data
                                }
                            />
                        </div>
                    </div>

                    <div className="columns" style={{ marginTop: "3em" }}>
                        <div className="column">
                            <h2 className="title is-size-3 has-text-centered">
                                Unbilled hours
                            </h2>
                            <table className="table is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>User</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.unbilledAmount.map((item) => (
                                        <tr key={item.name}>
                                            <td>{item.name}</td>
                                            <td>€ {item.unbilled_amount}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="column">
                            <h2 className="title is-size-3 has-text-centered">
                                {`€ ${this.props.outstandingInvoices} outstanding invoices`}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DashboardPage.propTypes = {
    history: PropTypes.object.isRequired,
    getDashboardData: PropTypes.func.isRequired,
    hoursPerProject: PropTypes.object.isRequired,
    revenuePerMonth: PropTypes.object.isRequired,
    unbilledAmount: PropTypes.array.isRequired,
    outstandingInvoices: PropTypes.number.isRequired,
    users: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    hoursPerProject: state.dashboard.hoursPerProject,
    revenuePerMonth: state.dashboard.revenuePerMonth,
    unbilledAmount: state.dashboard.unbilledAmount,
    outstandingInvoices: state.dashboard.outstandingInvoices,
    users: state.group.members,
});

const mapDispatchToProps = {
    getDashboardData,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
