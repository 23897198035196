import * as React from "react";
import { TicketSummary } from "./models/TicketSummary";
import axios from "axios";
import { getUrl } from "../../getBackend";
import { Input, Modal, Table } from "../shared";
import { CommentSummaryModal } from "./CommentSummaryModal";

interface IProps {
    onClose: () => void;
    projectId: string;
}

export const RecentTicketsModal: React.FC<IProps> = (props) => {
    const [commentInput, setCommentInput] = React.useState("");
    const [comment, setComment] = React.useState("");
    const [tickets, setTickets] = React.useState<TicketSummary[]>([]);

    React.useEffect(() => {
        if (props.projectId) {
            axios
                .get(
                    `${getUrl()}/api/v1/hours/summary/?project_id=${
                        props.projectId
                    }`
                )
                .then((res) => {
                    setTickets(res.data);
                });
        }
    }, []);

    const getTicketColor = (
        ticket: TicketSummary
    ): React.CSSProperties["color"] => {
        if (ticket.hours_remaining === null) {
            return undefined;
        }

        if (ticket.hours_remaining < 0) {
            return "#ff6161";
        }

        if (ticket.hours_remaining > 0) {
            if (ticket.status === "Done") {
                return "#ace3ac";
            } else {
                return "#fea542";
            }
        }

        return undefined;
    };

    return (
        <>
            <Modal
                closeModal={() => {
                    props.onClose();
                }}
                modalState
                title="Recent tickets"
            >
                <Input
                    id="comment"
                    placeholder="Comment (press enter to search)"
                    onChange={(e: any) => setCommentInput(e.target.value)}
                    onKeyPress={(e: any) => {
                        if (e.key === "Enter") {
                            setComment(commentInput);
                        }
                    }}
                    value={commentInput}
                    label={undefined}
                    type={undefined}
                    required={undefined}
                    help={undefined}
                />

                <Table>
                    <thead>
                        <tr>
                            <th>Ticket</th>
                            <th>Title</th>
                            <th>Status</th>
                            <th>Story points</th>
                            <th>Hours</th>
                            <th>Hours remaining</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tickets.map((ticket) => (
                            <tr
                                style={{
                                    backgroundColor: getTicketColor(ticket),
                                }}
                            >
                                <td onClick={() => setComment(ticket.key)}>
                                    {ticket.key}
                                </td>
                                <td>{ticket.summary}</td>
                                <td>{ticket.status}</td>
                                <td>{ticket.story_points}</td>
                                <td>{ticket.total_hours}</td>
                                <td>{ticket.hours_remaining}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal>
            {comment && (
                <CommentSummaryModal
                    comment={comment}
                    onClose={() => setComment("")}
                />
            )}
        </>
    );
};
