import * as React from "react";
import axios from "axios";
import { getUrl } from "../../getBackend";
import { Modal, Table } from "../shared";
import { TicketSummary } from "./models/TicketSummary";
import { connect } from "react-redux";

interface IProps {
    onClose: () => void;
    comment: string;
}

const mapStateToProps = (state: any) => ({
    users: state.group.members,
    projects: state.projects.projects.results,
});

const mapDispatchToProps = {};

type Props = IProps & ReturnType<typeof mapStateToProps>;

const InnerCommentSummaryModal: React.FC<Props> = (props) => {
    const [ticketSummary, setTicketSummary] =
        React.useState<TicketSummary | null>(null);

    React.useEffect(() => {
        if (props.comment) {
            axios
                .get(
                    `${getUrl()}/api/v1/hours/ticket_summary/?comment=${
                        props.comment
                    }`
                )
                .then((res) => {
                    setTicketSummary(res.data);
                });
        }
    }, []);

    const getNameByUserId = (userId: string): string => {
        const user = props.users.find((user: any) => user.id === userId);
        if (!user) {
            return "unknown";
        }

        return `${user.first_name} ${user.last_name}`;
    };

    const getProjectNameById = (projectId: string): string => {
        const project = props.projects.find(
            (project: any) => project.id === projectId
        );
        if (!project) {
            return "unknown";
        }

        return project.name;
    };

    return (
        <Modal
            closeModal={() => {
                props.onClose();
            }}
            modalState
            title="Comment summary"
            width="1000px"
        >
            <>
                <Table>
                    <thead>
                        <tr>
                            <th>Ticket</th>
                            <th>Title</th>
                            <th>Story points</th>
                            <th>Hours</th>
                            <th>Hours remaining</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ticketSummary && (
                            <tr>
                                <td>{ticketSummary.key}</td>
                                <td>{ticketSummary.summary}</td>
                                <td>{ticketSummary.story_points}</td>
                                <td>{ticketSummary.total_hours}</td>
                                <td>{ticketSummary.hours_remaining}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                <div
                    style={{
                        borderTop: "2px solid lightgrey",
                        marginBottom: "1em",
                    }}
                />

                {ticketSummary && (
                    <Table>
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Project</th>
                                <th>Rate</th>
                                <th>Date</th>
                                <th>Start</th>
                                <th>End</th>
                                <th>Pause</th>
                                <th>Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ticketSummary.hours
                                .sort((a, b) =>
                                    `${a.date}${a.start_time}`.localeCompare(
                                        `${b.date}${b.start_time}`
                                    )
                                )
                                .map((hour) => (
                                    <tr>
                                        <td>{getNameByUserId(hour.user)}</td>
                                        <td>
                                            {getProjectNameById(hour.project)}
                                        </td>
                                        <td>{hour.rate.name}</td>
                                        <td>{hour.date}</td>
                                        <td>{hour.start_time}</td>
                                        <td>{hour.end_time}</td>
                                        <td>{hour.pause}</td>
                                        <td>{hour.duration}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                )}
            </>
        </Modal>
    );
};

export const CommentSummaryModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(InnerCommentSummaryModal);
