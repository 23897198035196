import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../getBackend";

export const GET_CLIENTS_BEGIN = "GET_CLIENTS_BEGIN";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAILURE = "GET_CLIENTS_FAILURE";

export const getClientsBegin = () => ({
    type: GET_CLIENTS_BEGIN,
});

export const getClientsSuccess = (data) => ({
    type: GET_CLIENTS_SUCCESS,
    payload: { ...data },
});

export const getClientsFailure = (error) => ({
    type: GET_CLIENTS_FAILURE,
    payload: { error },
});

export function getClients() {
    return (dispatch) => {
        dispatch(getClientsBegin());

        axios(`${getUrl()}/api/v1/clients/`)
            .then((res) => {
                dispatch(getClientsSuccess(res.data));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to get clients.", { type: "error" });
                dispatch(getClientsFailure(error));
            });
    };
}
