import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import enhanceWithClickOutside from "react-click-outside";
import { registerSelectClient } from "../actions";

class Client extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };

        this.selectClient = this.selectClient.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    selectClient(id) {
        this.props.registerSelectClient(id);
        this.toggleDropdown();
    }

    toggleDropdown() {
        this.setState({
            open: !this.state.open,
        });
    }

    handleClickOutside() {
        this.setState({ open: false });
    }

    render() {
        const options = this.props.clients.map((client) => (
            <span
                role="button"
                key={client.id}
                className="dropdown-item"
                onClick={() => this.selectClient(client.id)}
            >
                {client.name}
            </span>
        ));

        return (
            <div
                role="button"
                className={this.state.open ? "dropdown is-active" : "dropdown"}
                style={{ width: "100%", marginBottom: "1em" }}
                onClick={() => this.toggleDropdown()}
            >
                <div className="dropdown-trigger" style={{ width: "100%" }}>
                    <button
                        type="button"
                        className="button is-fullwidth"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu"
                    >
                        <span>
                            {this.props.selectedClientId
                                ? this.props.clients.find(
                                      (client) =>
                                          client.id ===
                                          this.props.selectedClientId
                                  ).name
                                : "Select client"}
                        </span>
                        <span className="icon is-small">
                            <i
                                className="fas fa-angle-down"
                                aria-hidden="true"
                            />
                        </span>
                    </button>
                </div>
                <div
                    className="dropdown-menu"
                    id="dropdown-menu"
                    role="menu"
                    style={{ width: "100%" }}
                >
                    <div className="dropdown-content">{options}</div>
                </div>
            </div>
        );
    }
}

Client.propTypes = {
    clients: PropTypes.array,
    registerSelectClient: PropTypes.func.isRequired,
    selectedClientId: PropTypes.string,
};

const mapStateToProps = (state) => ({
    clients: state.clients.clients.results.filter((client) => client.enabled),
    selectedClientId: state.register.client,
});

const mapDispatchToProps = { registerSelectClient };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(enhanceWithClickOutside(Client));
