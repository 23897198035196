import moment from "moment";

export const initialCreateClientForm = {
    clientName: null,
    clientNumber: null,
};

export const initialCreateProjectForm = {
    projectName: null,
    projectNumber: null,
};

export const initialInviteSectionForm = {
    first_name: "",
    last_name: "",
    email: "",
};

export const initialInvoiceForm = {
    client: null,
    project: null,
    date: moment().format("YYYY-MM-DD"),
    tax_percentage: 21,
};
