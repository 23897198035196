/* eslint no-param-reassign: off */
import React from "react";
import { Control } from "react-redux-form";
import PropTypes from "prop-types";

const Input = ({ label, id, type, required, help, placeholder, ...rest }) => {
    if (!placeholder) {
        placeholder = label;
    }
    return (
        <div className="field">
            {label && <label className="label">{label}</label>}
            {type !== "checkbox" && (
                <div className="control">
                    <Control.text
                        model={id}
                        id={id}
                        className="input"
                        type={type}
                        placeholder={placeholder}
                        autoComplete="off"
                        required={required}
                        {...rest}
                    />
                </div>
            )}

            {type === "checkbox" && (
                <label className="checkbox">
                    <Control.checkbox
                        model={id}
                        id={id}
                        placeholder={placeholder}
                        autoComplete="off"
                        required={required}
                        {...rest}
                    />
                    <span style={{ marginLeft: "4px" }}>{label}</span>
                </label>
            )}
            {help && <p className="help">{help}</p>}
        </div>
    );
};

Input.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    required: PropTypes.bool,
    help: PropTypes.string,
    placeholder: PropTypes.string,
};

Input.defaultProps = {
    type: "text",
    required: true,
    help: null,
};

export default Input;
