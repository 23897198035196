import React from "react";
import { NavLink } from "react-router-dom";

const Tabs = () => (
    <div
        className="buttons has-addons"
        style={{ textAlign: "center", display: "block" }}
    >
        <NavLink
            to="/overview/hours"
            activeClassName="is-primary"
            className="button"
        >
            Hours
        </NavLink>

        <NavLink
            to="/overview/invoices"
            activeClassName="is-primary"
            className="button"
        >
            Invoices
        </NavLink>
    </div>
);

export default Tabs;
