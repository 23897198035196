import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { selectClient } from "./actions/selectClient";

class ClientsOverview extends Component {
    selectClient(clientId) {
        this.props.selectClient(clientId);
    }

    render() {
        const clients = this.props.clients.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        const clientList = clients.map((client) => (
            <span
                role="button"
                className={
                    this.props.selectedId === client.id
                        ? "panel-block is-active"
                        : "panel-block"
                }
                key={client.id}
                onClick={() => this.selectClient(client.id)}
            >
                <span className="panel-icon">
                    <i className="fas fa-building" aria-hidden="true" />
                </span>
                {client.name}
            </span>
        ));

        return (
            <nav className="panel box">
                <p className="panel-heading">Clients</p>
                {clientList}
            </nav>
        );
    }
}

ClientsOverview.propTypes = {
    selectClient: PropTypes.func.isRequired,
    clients: PropTypes.array,
    selectedId: PropTypes.string,
};

const mapStateToProps = (state) => ({
    clients: state.clients.clients.results,
    selectedId: state.clients.selected,
});

export default connect(mapStateToProps, { selectClient })(ClientsOverview);
