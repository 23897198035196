import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ContextMenuProvider } from "react-contexify";
import moment from "moment";

import { Table } from "../../shared";
import Context from "./ContextMenu";
import {
    exportInvoice,
    toggleCreateInvoiceModal,
    toggleHourSummaryModal,
} from "./actions";
import CreateInvoiceModal from "./CreateInvoiceModal";
import { HourSummaryModal } from "./HourSummaryModal";
import { Status } from "./Status";
import { GenerateInvoiceButton } from "./GenerateInvoiceButton";

class InvoiceOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedInvoiceId: null,
        };
    }

    render() {
        const today = moment();

        const invoiceList = this.props.invoices
            .sort((x, y) => {
                if (x.number > y.number) {
                    return -1;
                }
                if (x.number < y.number) {
                    return 1;
                }
                return 0;
            })
            .map((item) => {
                const project = this.props.projects.find(
                    (i) => i.id === item.project
                );
                const client = this.props.clients.find(
                    (i) => i.id === project.client_id
                );

                const date = moment(item.date, "YYYY-MM-DD");

                return (
                    <ContextMenuProvider
                        key={item.id}
                        component="tr"
                        data={item.id}
                        id="menu_id"
                    >
                        <td>{item.number}</td>
                        <td>{item.date}</td>
                        <td>{client.name}</td>
                        <td>{project.name}</td>
                        <td>{item.total_hours}</td>
                        <td>
                            €{" "}
                            {String(item.amount_gross.toFixed(2)).replace(
                                ".",
                                ","
                            )}
                        </td>
                        <td>
                            {item.status !== "paid" && (
                                <span>{today.diff(date, "days")}</span>
                            )}
                        </td>
                        <td>
                            <Status invoiceId={item.id} status={item.status} />
                        </td>
                        <td>
                            {item.exported_on
                                ? moment(item.exported_on).format("YYYY-MM-DD")
                                : ""}
                        </td>
                        <td>
                            <button
                                className="button is-small is-primary"
                                type="button"
                                onClick={() => {
                                    this.setState({
                                        selectedInvoiceId: item.id,
                                    });
                                    this.props.toggleHourSummaryModal();
                                }}
                            >
                                Hours
                            </button>
                        </td>
                        <td>
                            <button
                                className="button is-small"
                                type="button"
                                onClick={() => {
                                    this.props.exportInvoice(item.id);
                                }}
                                disabled={item.status === "exported"}
                            >
                                Export
                            </button>
                        </td>
                    </ContextMenuProvider>
                );
            });

        return (
            <div>
                <button
                    className="button is-primary"
                    type="button"
                    onClick={() => this.props.toggleCreateInvoiceModal()}
                >
                    Create invoice
                </button>
                <GenerateInvoiceButton />
                <p className="help">
                    Right click the rows to change the status.
                </p>
                <Table>
                    <thead>
                        <tr>
                            <th>Number</th>
                            <th>Date</th>
                            <th>Client</th>
                            <th>Project</th>
                            <th>Hours</th>
                            <th>Total amount</th>
                            <th>&#916;</th>
                            <th>Status</th>
                            <th>Exported</th>
                            <th />
                            <th />
                        </tr>
                    </thead>
                    <tbody>{invoiceList}</tbody>
                </Table>
                <Context />
                {this.state.selectedInvoiceId && (
                    <HourSummaryModal
                        invoiceId={this.state.selectedInvoiceId}
                    />
                )}
                <CreateInvoiceModal />
            </div>
        );
    }
}

InvoiceOverview.propTypes = {
    toggleCreateInvoiceModal: PropTypes.func.isRequired,
    toggleHourSummaryModal: PropTypes.func.isRequired,
    invoices: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    clients: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    invoices: state.invoices.invoices.results.sort(
        (a, b) => a.number < b.number
    ),
    projects: state.projects.projects.results,
    clients: state.clients.clients.results,
});

const mapDispatchToProps = {
    toggleCreateInvoiceModal,
    toggleHourSummaryModal,
    exportInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceOverview);
