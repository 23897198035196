/* eslint no-param-reassign: 0 */

import { combineReducers } from "redux";
import { createForms } from "react-redux-form";

import userReducer from "./userReducer";
import clientsReducer from "./clientsReducer";
import projectsReducer from "./projectsReducer";
import { registerReducer } from "../components/RegisterPage";
import hoursReducer from "./hoursReducer";
import inviteReducer from "../components/InvitePage/reducer";
import { overviewReducer } from "../components/OverviewPage";
import { invoiceReducer } from "../components/OverviewPage/InvoiceOverview";

import { REGISTER_RESET } from "../components/RegisterPage/actions";
import { USER_LOGOUT } from "../actions/user/logout";

import {
    initialCreateClientForm,
    initialCreateProjectForm,
    initialInviteSectionForm,
    initialInvoiceForm,
} from "./reduxForms";
import groupReducer from "./groupReducer";
import { reducer as paymentsReducer } from "../components/ProjectPage";
import { dashboardReducer } from "../components/Dashboard";

const appReducer = combineReducers({
    user: userReducer,
    clients: clientsReducer,
    projects: projectsReducer,
    register: registerReducer,
    hours: hoursReducer,
    group: groupReducer,
    invite: inviteReducer,
    overview: overviewReducer,
    payments: paymentsReducer,
    invoices: invoiceReducer,
    dashboard: dashboardReducer,
    ...createForms({
        createClientForm: initialCreateClientForm,
        createProjectForm: initialCreateProjectForm,
        inviteSectionForm: initialInviteSectionForm,
        createInvoiceModal: initialInvoiceForm,
    }),
});

const rootReducer = (state, action) => {
    if (action.type === USER_LOGOUT) {
        state = undefined;
    }
    if (action.type === REGISTER_RESET) {
        state.register = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
