import React, { Component } from "react";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import * as Sentry from "@sentry/browser";

import "./App.css";

import store from "./store";
import { PageNotFound, PrivateRoute } from "./components/shared";

import { LoginPage } from "./components/LoginPage";
import { AccountPage } from "./components/AccountPage";
import { ClientPage } from "./components/ClientPage";
import { RegisterPage } from "./components/RegisterPage";
import { ProjectPage } from "./components/ProjectPage";
import { CreateAccountPage } from "./components/CreateAccountPage";
import { DashboardPage } from "./components/Dashboard";

import { OverviewPage } from "./components/OverviewPage";
import InvitePage from "./components/InvitePage/InvitePage";

import { getUserData } from "./actions/user/getDetails";
import { getClients } from "./components/ClientPage/actions/getClients";
import { getProjects } from "./components/ProjectPage/actions/getProjects";

class App extends Component {
    componentWillMount() {
        if (process.env.NODE_ENV === "production") {
            Sentry.init({
                dsn: "https://a7079f1fbbc14c90b6d8fe9043c61fee@sentry.inogo.nl/5",
            });
        }

        // Load user data.
        if (store.getState().user.token !== null) {
            store.dispatch(getUserData());
            store.dispatch(getClients());
            store.dispatch(getProjects());
        }
    }

    render() {
        return (
            <div>
                <Provider store={store}>
                    <Router>
                        <Switch>
                            <Route
                                exact
                                path="/"
                                component={() => <Redirect to="/register" />}
                            />
                            <PrivateRoute
                                path="/dashboard"
                                component={DashboardPage}
                            />
                            <PrivateRoute
                                path="/register"
                                component={RegisterPage}
                            />
                            <PrivateRoute
                                path="/overview"
                                component={OverviewPage}
                            />
                            <PrivateRoute
                                path="/clients"
                                component={ClientPage}
                            />
                            <PrivateRoute
                                path="/settings"
                                component={AccountPage}
                            />
                            <PrivateRoute
                                path="/projects/:project"
                                component={ProjectPage}
                            />
                            <Route path="/login" component={LoginPage} />
                            <Route
                                path="/create-account"
                                component={CreateAccountPage}
                            />
                            <Route
                                path="/invite/:group"
                                component={InvitePage}
                            />
                            <Route component={PageNotFound} />
                        </Switch>
                    </Router>
                </Provider>
                <ToastContainer autoClose={2500} />
            </div>
        );
    }
}

export default App;
