import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../getBackend";

export const UPDATE_PROJECT_BEGIN = "UPDATE_PROJECT_BEGIN";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAILURE = "UPDATE_PROJECT_FAILURE";

export const updateProjectBegin = () => ({
    type: UPDATE_PROJECT_BEGIN,
});

export const updateProjectSuccess = (data) => ({
    type: UPDATE_PROJECT_SUCCESS,
    payload: { ...data },
});

export const updateProjectFailure = (error) => ({
    type: UPDATE_PROJECT_FAILURE,
    payload: { error },
});

export function updateProject(id, data) {
    return (dispatch) => {
        dispatch(updateProjectBegin());
        axios({
            method: "patch",
            url: `${getUrl()}/api/v1/projects/${id}/`,
            data: { ...data },
        })
            .then((res) => {
                toast("Project updated!", { type: "success" });
                dispatch(updateProjectSuccess(res.data));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to update project.", { type: "error" });
                dispatch(updateProjectFailure(error));
            });
    };
}
