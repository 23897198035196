import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../../getBackend";

export const GET_INVOICES_BEGIN = "GET_INVOICES_BEGIN";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_FAILURE = "GET_INVOICES_FAILURE";

export const getInvoicesBegin = () => ({
    type: GET_INVOICES_BEGIN,
});

export const getInvoicesSuccess = (data) => ({
    type: GET_INVOICES_SUCCESS,
    payload: { ...data },
});

export const getInvoicesFailure = (error) => ({
    type: GET_INVOICES_FAILURE,
    payload: { error },
});

export function getInvoices() {
    return (dispatch) => {
        dispatch(getInvoicesBegin());

        axios(`${getUrl()}/api/v1/invoices/`)
            .then((res) => {
                dispatch(getInvoicesSuccess(res.data));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to get invoices.", { type: "error" });
                dispatch(getInvoicesFailure(error));
            });
    };
}
