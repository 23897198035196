/* eslint camelcase: off */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { Table } from "../../shared";
import { removeInvite } from "../../../actions/group/removeInvite";

class MembersSection extends Component {
    render() {
        const { members, invites } = this.props;

        const memberList = members.map((member) => (
            <li key={member.id}>
                <span>
                    {`${member.first_name} ${member.last_name} ${
                        this.props.currentUserId === member.id ? "(You)" : ""
                    }`}
                </span>
            </li>
        ));

        const inviteList =
            invites.length > 0 ? (
                invites.map((invite) => (
                    <tr key={invite.id}>
                        <td>
                            <span>
                                {`${invite.first_name} ${invite.last_name} (${invite.email})`}
                            </span>
                        </td>
                        <td>
                            <div
                                className="delete"
                                role="button"
                                onClick={() => {
                                    confirmAlert({
                                        title: "Confirm delete",
                                        message:
                                            "Are you sure to do delete this invite?",
                                        buttons: [
                                            {
                                                label: "No",
                                            },
                                            {
                                                label: "Yes",
                                                onClick: () => {
                                                    this.props.removeInvite(
                                                        invite.id
                                                    );
                                                },
                                            },
                                        ],
                                    });
                                }}
                            />
                        </td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td>No open invites</td>
                </tr>
            );

        return (
            <div className="column is-6">
                <h1 className="title is-3">Members</h1>
                <hr />
                <div className="content">
                    <ul>{memberList}</ul>
                </div>
                <h1 className="title is-3">Open invites</h1>
                <hr />
                <div className="content">
                    <Table>
                        <tbody>{inviteList}</tbody>
                    </Table>
                </div>
            </div>
        );
    }
}

MembersSection.propTypes = {
    removeInvite: PropTypes.func.isRequired,
    members: PropTypes.array,
    invites: PropTypes.array,
    currentUserId: PropTypes.string,
};

const mapStateToProps = (state) => ({
    organization_id: state.user.group_id,
    members: state.group.members,
    invites: state.group.invites,
    currentUserId: state.user.id,
});

const mapDispatchToProps = { removeInvite };

export default connect(mapStateToProps, mapDispatchToProps)(MembersSection);
