import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../getBackend";

export const REMOVE_CLIENT_BEGIN = "REMOVE_CLIENT_BEGIN";
export const REMOVE_CLIENT_SUCCESS = "REMOVE_CLIENT_SUCCESS";
export const REMOVE_CLIENT_FAILURE = "REMOVE_CLIENT_FAILURE";

export const removeClientBegin = () => ({
    type: REMOVE_CLIENT_BEGIN,
});

export const removeClientSuccess = (id) => ({
    type: REMOVE_CLIENT_SUCCESS,
    payload: id,
});

export const removeClientFailure = (error) => ({
    type: REMOVE_CLIENT_FAILURE,
    payload: { error },
});

export function removeClient(clientId) {
    return (dispatch) => {
        dispatch(removeClientBegin());

        axios({
            method: "delete",
            url: `${getUrl()}/api/v1/clients/${clientId}/`,
        })
            .then((res) => {
                dispatch(removeClientSuccess(clientId));
                toast("Client removed succesfully!", {
                    type: "success",
                });
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to REMOVE client.", { type: "error" });
                dispatch(removeClientFailure(error));
            });
    };
}
