import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert"; // Import
import moment from "moment";
import { removeHours } from "../../actions/hours/removeHours";
import { LoadingSpinner, Table } from "../shared";
import {
    registerSelectClient,
    registerSelectProject,
    selectRate,
    setBreakTime,
    setCommentTime,
    setEndTime,
    setStartTime,
    setTotalTime,
} from "./actions";
import { calculateTotal } from "./Controls/hourHelpers";
import { HourComment } from "./HourComment";
import { HoursOnDayTimeLine } from "./HoursOnDayTimeLine";

class HoursOverview extends Component {
    getSortedHours() {
        return this.props.hours.sort((a, b) => {
            // If the start times are the same compare the end times.
            if (a.start_time === b.start_time) {
                return a.end_time.localeCompare(b.end_time);
            }

            return a.start_time.localeCompare(b.start_time);
        });
    }

    getPendingHours() {
        // Don't show the pending hour if the start/end time isn't changed.
        if (
            this.props.register.time.start === "00:00" ||
            this.props.register.time.end === "00:00"
        ) {
            return [];
        }

        if (this.props.register.time.start && this.props.register.time.end) {
            return [
                {
                    id: "-",
                    comment: this.props.register.time.comment,
                    start_time: `${this.props.register.time.start}:00`,
                    end_time: `${this.props.register.time.end}:00`,
                    project: this.props.register.project,
                    user: this.props.user.id,
                },
            ];
        }

        return [];
    }

    onHourClick(item, moveEnd = false) {
        if (item.client) {
            this.props.registerSelectClient(item.client.id);
            this.props.registerSelectProject(item.project);
            this.props.selectRate(item.rate.id);
        }

        let newStart = moment(item.start_time, "HH:mm:ss").format("HH:mm");
        let newEnd = moment(item.end_time, "HH:mm:ss").format("HH:mm");
        let newBreak = moment(item.pause, "HH:mm:ss").format("HH:mm");
        let newComment = item.comment;

        if (moveEnd) {
            // Set the start time as the end time of the clicked item.
            newStart = newEnd;

            const nextHour = this.getSortedHours().find(
                (hour) =>
                    hour.user === this.props.user.id &&
                    hour.start_time > newStart
            );

            if (nextHour) {
                newEnd = nextHour.start_time;
            } else {
                // Set the end time as the closest quarter of the current time..
                const duration = moment.duration(15, "minutes");
                newEnd = moment(
                    Math.round(+moment() / +duration) * +duration
                ).format("HH:mm");
            }

            // Make sure the break and comment are empty by default.
            newBreak = "00:00";
            newComment = "";
        }

        this.props.setStartTime(newStart);
        this.props.setEndTime(newEnd);
        this.props.setBreakTime(newBreak);
        this.props.setCommentTime(newComment);

        this.props.setTotalTime(calculateTotal(newStart, newEnd, newBreak));
    }

    render() {
        if (
            this.props.hoursLoading ||
            this.props.groupLoading ||
            this.props.projectsLoading ||
            !this.props.members ||
            this.props.members.length === 0
        ) {
            return (
                <div>
                    <Table>
                        <thead>
                            <tr>
                                <th style={{ width: "" }}>Project</th>
                                <th style={{ width: "200px" }}>Rate</th>
                                <th style={{ width: "100px" }}>Start</th>
                                <th style={{ width: "100px" }}>End</th>
                                <th style={{ width: "100px" }}>Break</th>
                                <th style={{ width: "120px" }}>Time spent</th>
                                <th style={{ width: "120px" }}>Comment</th>
                                <th style={{ width: "175px" }}>By</th>
                                <th style={{ width: "44px" }} />
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div style={{ width: "24px" }}>
                                        <LoadingSpinner />
                                    </div>
                                </td>
                                <td />
                                <td />
                                <td />
                                <td />
                            </tr>
                        </tbody>
                    </Table>
                </div>
            );
        }
        const items = this.getSortedHours().map((item) => {
            const user = this.props.members.find(
                (member) => member.id === item.user
            );

            return (
                <tr key={item.id}>
                    <td onClick={() => this.onHourClick(item)}>
                        {
                            this.props.projects.find(
                                (project) => project.id === item.project
                            ).name
                        }
                    </td>
                    <td onClick={() => this.onHourClick(item)}>
                        {item.rate && item.rate.name}
                    </td>
                    <td onClick={() => this.onHourClick(item)}>
                        {item.start_time.slice(0, 5)}
                    </td>
                    <td onClick={() => this.onHourClick(item, true)}>
                        {item.end_time.slice(0, 5)}
                    </td>
                    <td onClick={() => this.onHourClick(item)}>
                        {item.pause.slice(0, 5)}
                    </td>
                    <td onClick={() => this.onHourClick(item)}>
                        <b>{item.duration.slice(0, 5)}</b>
                    </td>
                    <td>{item.comment && <HourComment hour={item} />}</td>
                    <td onClick={() => this.onHourClick(item)}>
                        {`${user.first_name} ${user.last_name}`}
                    </td>
                    <td>
                        <div
                            role="button"
                            className="delete"
                            onClick={() =>
                                confirmAlert({
                                    title: "Remove hours",
                                    message: "Are you sure to do this?",
                                    buttons: [
                                        {
                                            label: "No",
                                        },
                                        {
                                            label: "Yes",
                                            onClick: () =>
                                                this.props.removeHours(
                                                    item.id,
                                                    item.date
                                                ),
                                        },
                                    ],
                                })
                            }
                        />
                    </td>
                </tr>
            );
        });

        return (
            <div>
                <HoursOnDayTimeLine
                    hours={[...this.props.hours, ...this.getPendingHours()]}
                />
                <Table>
                    <thead>
                        <tr>
                            <th style={{ width: "" }}>Project</th>
                            <th style={{ width: "200px" }}>Rate</th>
                            <th style={{ width: "100px" }}>Start</th>
                            <th style={{ width: "100px" }}>End</th>
                            <th style={{ width: "100px" }}>Break</th>
                            <th style={{ width: "120px" }}>Time spent</th>
                            <th style={{ width: "120px" }}>Comment</th>
                            <th style={{ width: "175px" }}>By</th>
                            <th style={{ width: "44px" }} />
                        </tr>
                    </thead>
                    <tbody>{items}</tbody>
                </Table>
            </div>
        );
    }
}

HoursOverview.propTypes = {
    hours: PropTypes.array.isRequired,
    registerSelectClient: PropTypes.func.isRequired,
    registerSelectProject: PropTypes.func.isRequired,
    selectRate: PropTypes.func.isRequired,
    setStartTime: PropTypes.func.isRequired,
    setEndTime: PropTypes.func.isRequired,
    setBreakTime: PropTypes.func.isRequired,
    setCommentTime: PropTypes.func.isRequired,
    setTotalTime: PropTypes.func.isRequired,
    projects: PropTypes.array,
    members: PropTypes.array,
    removeHours: PropTypes.func.isRequired,
    hoursLoading: PropTypes.bool,
    groupLoading: PropTypes.bool,
    projectsLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
    let hours = state.hours.hours.results;

    if (state.register.onlyShowOwnHours) {
        hours = hours.filter((hour) => hour.user === state.user.id);
    }

    return {
        hours,
        projects: state.projects.projects.results,
        members: state.group.members,
        groupLoading: state.group.getGroupLoading,
        hoursLoading: state.hours.getLoading,
        projectsLoading: state.projects.loading,
        user: state.user,
        register: state.register,
    };
};

const mapDispatchToProps = {
    removeHours,
    registerSelectClient,
    registerSelectProject,
    selectRate,
    setStartTime,
    setEndTime,
    setBreakTime,
    setCommentTime,
    setTotalTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(HoursOverview);
