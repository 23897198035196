import React from "react";

import CreateProjectModal from "./CreateProjectModal";
import EditClientModal from "./EditClientModal";
import CreateClientModal from "./CreateClientModal";

const ClientModals = () => (
    <div>
        <CreateProjectModal />
        <EditClientModal />
        <CreateClientModal />
    </div>
);

export default ClientModals;
