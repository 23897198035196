import React, { Component } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { NavBar, LoadingPage } from "../shared";

import { resetFilters } from "./actions";
import { getInvoices } from "./InvoiceOverview/actions";
import Tabs from "./Tabs";
import { HoursOverview } from "./HoursOverview";
import { InvoiceOverview } from "./InvoiceOverview";

class OverviewPage extends Component {
    constructor(props) {
        super(props);

        if (!this.props.invoicesFetched) {
            this.props.getInvoices();
        }
    }

    componentWillUnmount() {
        this.props.resetFilters();
    }

    render() {
        const { history, match } = this.props;

        if (
            this.props.invoicesLoading ||
            this.props.projectsLoading ||
            this.props.clientsLoading
        ) {
            return <LoadingPage history={history} />;
        }

        return (
            <div>
                <NavBar history={history} />
                <div className="container box">
                    <Tabs />
                    <hr />
                    <Route
                        path={`${match.path}/hours`}
                        render={() => <HoursOverview />}
                    />
                    <Route
                        path={`${match.path}/invoices`}
                        render={() => <InvoiceOverview />}
                    />
                </div>
            </div>
        );
    }
}

OverviewPage.propTypes = {
    resetFilters: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    getInvoices: PropTypes.func.isRequired,
    invoicesLoading: PropTypes.bool.isRequired,
    invoicesFetched: PropTypes.bool.isRequired,
    projectsLoading: PropTypes.bool.isRequired,
    clientsLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    selectedTab: state.overview.selectedTab,
    invoicesLoading: state.invoices.getLoading,
    projectsLoading: state.projects.loading,
    clientsLoading: state.clients.loading,
    invoicesFetched: state.invoices.fetched,
});

const mapDispatchToProps = {
    resetFilters,
    getInvoices,
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);
