import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal } from "../../../shared";
import {
    getHourSummary,
    resetHourSummaryData,
    toggleHourSummaryModal,
} from "../actions";
import Table from "../../../shared/Table";

class HourSummaryModal extends Component {
    constructor(props) {
        super(props);
        props.getHourSummary(props.invoiceId);
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.invoiceId &&
            nextProps.invoiceId !== this.props.invoiceId
        ) {
            this.props.resetHourSummaryData();
            this.props.getHourSummary(nextProps.invoiceId);
        }
    }

    render() {
        return (
            <Modal
                closeModal={() => {
                    this.props.toggleHourSummaryModal();
                }}
                modalState={this.props.modalState}
                title="Hour summary"
            >
                <Table>
                    <thead>
                        <tr>
                            <th>Employee</th>
                            <th>Hours</th>
                            <th>Split</th>
                            <th>Total (Excl)</th>
                            <th>Total (Incl)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.hourSummary.hours.map((item) => (
                            <tr>
                                <td>{item.user}</td>
                                <td>{item.duration}</td>
                                <td>€ {item.split_amount}</td>
                                <td>€ {item.total}</td>
                                <td>€ {item.total_incl}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td />
                            <td />
                            <td />

                            <td>
                                <span style={{ fontWeight: "bold" }}>
                                    € {this.props.hourSummary.total_excl}
                                </span>
                            </td>
                            <td>
                                <span style={{ fontWeight: "bold" }}>
                                    € {this.props.hourSummary.total_incl}
                                </span>
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            </Modal>
        );
    }
}

HourSummaryModal.propTypes = {
    toggleHourSummaryModal: PropTypes.func.isRequired,
    getHourSummary: PropTypes.func.isRequired,
    resetHourSummaryData: PropTypes.func.isRequired,
    modalState: PropTypes.bool,
    invoiceId: PropTypes.string.isRequired,
    hourSummary: PropTypes.object,
};

const mapStateToProps = (state) => ({
    modalState: state.invoices.hourSummaryModalToggled,
    hourSummary: state.invoices.hourSummary,
});

const mapDispatchToProps = {
    toggleHourSummaryModal,
    getHourSummary,
    resetHourSummaryData,
};

export default connect(mapStateToProps, mapDispatchToProps)(HourSummaryModal);
