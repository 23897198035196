import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LocalForm } from "react-redux-form";
import { Input } from "../../shared";
import { updateGroup } from "../../../actions/group/updateGroup";

export class InvoiceSection extends Component {
    sendInvite(values) {
        this.props.updateGroup(this.props.groupId, values);
    }

    render() {
        return (
            <div className="column is-6">
                <h1 className="title is-3">Invoice settings</h1>
                <hr />

                <LocalForm
                    model="inviteSectionForm"
                    onSubmit={(values) => this.sendInvite(values)}
                    initialState={{
                        next_invoice_number_raw: this.props.nextInvoiceNumber,
                    }}
                >
                    <Input
                        label="Next invoice number"
                        type="number"
                        id=".next_invoice_number_raw"
                    />

                    <div className="field">
                        <div className="control">
                            <button className="button is-success" type="submit">
                                Update
                            </button>
                        </div>
                    </div>
                </LocalForm>
            </div>
        );
    }
}

InvoiceSection.propTypes = {
    updateGroup: PropTypes.func.isRequired,
    nextInvoiceNumber: PropTypes.number,
    groupId: PropTypes.string,
};

const mapStateToProps = (state) => ({
    nextInvoiceNumber: state.group.next_invoice_number_raw,
    groupId: state.group.id,
});

const mapDispatchToProps = {
    updateGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSection);
