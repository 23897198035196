import React from "react";
import { Input } from "../shared";

const ClientForm = () => (
    <div>
        <Input
            label="Client name"
            id=".name"
            help="Only your group can see this name."
        />
        <Input
            label="Client number"
            id=".client_number"
            help="A unique number to identify the client."
        />
        <Input label="Email" id=".email" />

        <Input
            label="Invoice emails"
            id=".invoice_emails"
            help="Comma sepperated"
            required={false}
        />
        <Input
            label="Official name"
            id=".official_name"
            help="This is the name that will be used for invoices etc."
        />
        <Input label="Address" id=".address" />
        <Input label="City" id=".city" />
        <Input label="Zip" id=".zipcode" />
        <Input label="Contact person" id=".contact_person" />
        <Input label="Extern ID" id=".external_id" />
        <Input
            label="Invoice reminder 1"
            id=".invoice_reminder_1"
            required={false}
        />
        <Input
            label="Invoice reminder 2"
            id=".invoice_reminder_2"
            required={false}
        />
        <Input label="Enabled" id=".enabled" type="checkbox" required={false} />
    </div>
);

export default ClientForm;
