import React from "react";

import { NavLink } from "react-router-dom";

const Menu = () => (
    <div className="column is-3">
        <aside className="menu">
            <p className="menu-label">General</p>
            <ul className="menu-list">
                <li>
                    <NavLink
                        to="/settings/profile"
                        exact
                        activeClassName="is-active"
                    >
                        Profile
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/settings/admin" activeClassName="is-active">
                        Account
                    </NavLink>
                </li>
            </ul>
            <p className="menu-label">Organization</p>
            <ul className="menu-list">
                <li>
                    <NavLink
                        to="/settings/organization/settings"
                        activeClassName="is-active"
                    >
                        Settings
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/settings/organization/invoices"
                        activeClassName="is-active"
                    >
                        Invoices
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/settings/organization/members"
                        activeClassName="is-active"
                    >
                        Members
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/settings/organization/invite"
                        activeClassName="is-active"
                    >
                        Invite
                    </NavLink>
                </li>
            </ul>
        </aside>
    </div>
);

export default Menu;
