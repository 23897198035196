export const CREATE_PROJECT_MODAL_TOGGLE = "CREATE_PROJECT_MODAL_TOGGLE";

export const toggleCreateProjectModalBegin = () => ({
    type: CREATE_PROJECT_MODAL_TOGGLE,
});

export function toggleCreateProjectModal() {
    return (dispatch) => {
        dispatch(toggleCreateProjectModalBegin());
    };
}
