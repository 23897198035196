export const REGISTER_SET_END_TIME = "REGISTER_SET_END_TIME";

const setTime = (time) => ({
    type: REGISTER_SET_END_TIME,
    payload: time,
});

export function setEndTime(time) {
    return (dispatch) => {
        dispatch(setTime(time));
    };
}
