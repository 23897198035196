/* eslint camelcase: off */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LocalForm } from "react-redux-form";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { updateProject } from "./actions/updateProject";
import { removeProject } from "./actions/removeProject";
import ProjectForm from "./ProjectForm";

class GeneralSection extends Component {
    constructor(props) {
        super(props);

        this.project = this.props.projects.find(
            (item) => item.id === this.props.projectId
        );
        this.state = {
            start_date: moment(this.project.start_date),
            end_date: moment(this.project.end_date),
            project_assignment: moment(this.project.project_assignment),
            terms_and_conditions_signed: this.project
                .terms_and_conditions_signed
                ? moment(this.project.terms_and_conditions_signed)
                : null,
            next_invoice: moment(this.project.next_invoice),
        };

        this.dateChanged = this.dateChanged.bind(this);
    }

    onSubmit(values) {
        const data = {
            ...values,
            start_date: this.state.start_date.format("YYYY-MM-DD"),
            end_date: this.state.end_date.format("YYYY-MM-DD"),
            project_assignment:
                this.state.project_assignment.format("YYYY-MM-DD"),
            terms_and_conditions_signed: this.state.terms_and_conditions_signed
                ? this.state.terms_and_conditions_signed.format("YYYY-MM-DD")
                : null,
            next_invoice: this.state.next_invoice.format("YYYY-MM-DD"),
        };

        this.props.updateProject(this.project.id, data);
    }

    dateChanged(id, date) {
        const { state } = this;

        state[id] = date;
        this.setState(state);
    }

    render() {
        if (this.props.projectsLoading) {
            return <p>Loading...</p>;
        }

        const initialState = {
            ...this.project,
        };

        return (
            <div>
                <div className="columns">
                    <div className="column is-5">
                        <h1 className="title is-3">Settings</h1>
                        <div style={{ marginBottom: "2em" }}>
                            <LocalForm
                                initialState={initialState}
                                onSubmit={(values) => this.onSubmit(values)}
                            >
                                <ProjectForm
                                    dateChanged={this.dateChanged}
                                    nextInvoice={moment(
                                        this.project.next_invoice,
                                        "YYYY-MM-DD"
                                    )}
                                    projectAssignment={moment(
                                        this.project.project_assignment,
                                        "YYYY-MM-DD"
                                    )}
                                    termsAndConditionsSigned={
                                        this.project.terms_and_conditions_signed
                                            ? moment(
                                                  this.project
                                                      .terms_and_conditions_signed,
                                                  "YYYY-MM-DD"
                                              )
                                            : null
                                    }
                                    startDate={moment(
                                        this.project.start_date,
                                        "YYYY-MM-DD"
                                    )}
                                    endDate={moment(
                                        this.project.end_date,
                                        "YYYY-MM-DD"
                                    )}
                                />
                                <button
                                    className="button is-success"
                                    type="submit"
                                >
                                    Save
                                </button>
                            </LocalForm>
                        </div>
                    </div>
                </div>
                <h1 className="title is-3">Dangerous</h1>
                <button
                    className="button is-danger is-small"
                    type="button"
                    onClick={() => {
                        confirmAlert({
                            title: "Confirm delete",
                            message: "Are you sure to do delete this project?",
                            buttons: [
                                {
                                    label: "No",
                                },
                                {
                                    label: "Yes",
                                    onClick: () => {
                                        this.props.removeProject(
                                            this.props.projectId,
                                            this.props.projects.find(
                                                (item) =>
                                                    item.id ===
                                                    this.props.projectId
                                            ).client_id
                                        );
                                        this.props.history.push("/clients");
                                    },
                                },
                            ],
                        });
                    }}
                >
                    Delete project
                </button>
            </div>
        );
    }
}

GeneralSection.propTypes = {
    updateProject: PropTypes.func.isRequired,
    removeProject: PropTypes.func.isRequired,
    projects: PropTypes.array,
    projectsLoading: PropTypes.bool,
    projectId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    projects: state.projects.projects.results,
    projectsLoading: state.projects.loading,
    projectId: state.projects.projectDetailPage,
});

const mapDispatchToProps = {
    updateProject,
    removeProject,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(GeneralSection));
