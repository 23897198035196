import * as React from "react";
import { useDispatch } from "react-redux";
import { changeStatus } from "../actions";
import { Modal } from "../../../shared";
import { useState } from "react";

interface IProps {
    invoiceId: string;
    status: "pending" | "awaiting_payment" | "paid";
}

export const Status: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);

    const getColorForStatus = (status: string): string => {
        switch (status) {
            case "pending":
                return "is-danger";
            case "awaiting_payment":
                return "is-warning";
            case "paid":
                return "is-success";
            default:
                console.error(`Unknown status: ${status}`);
                return "";
        }
    };

    const getDisplayStatus = (status: string) => status.replace("_", " ");

    const onStatusChange = (status: string) => {
        dispatch(changeStatus([props.invoiceId], status));
        setModalOpen(false);
    };

    const renderTag = (status: string, onClick: () => void) => {
        return (
            <span
                className={`tag ${getColorForStatus(status)}`}
                style={{
                    cursor: "pointer",
                }}
                onClick={() => onClick()}
            >
                {getDisplayStatus(status)}
            </span>
        );
    };

    return (
        <>
            {modalOpen && (
                <Modal
                    closeModal={() => {
                        setModalOpen(false);
                    }}
                    modalState
                    title="Change invoice status"
                >
                    {["pending", "awaiting_payment", "paid"].map((status) => (
                        <div key={status} style={{ marginBottom: "1em" }}>
                            {renderTag(status, () => onStatusChange(status))}
                        </div>
                    ))}
                </Modal>
            )}
            {renderTag(props.status, () => setModalOpen(true))}
        </>
    );
};
