import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { getUrl } from "../../getBackend";

import { getWeekHours } from "./getWeekHours";

export const REMOVE_HOURS_BEGIN = "REMOVE_HOURS_BEGIN";
export const REMOVE_HOURS_SUCCESS = "REMOVE_HOURS_SUCCESS";
export const REMOVE_HOURS_FAILURE = "REMOVE_HOURS_FAILURE";

export const removeHoursBegin = () => ({
    type: REMOVE_HOURS_BEGIN,
});

export const removeHoursSuccess = (id) => ({
    type: REMOVE_HOURS_SUCCESS,
    payload: id,
});

export const removeHoursFailure = (error) => ({
    type: REMOVE_HOURS_FAILURE,
    payload: { error },
});

export function removeHours(hoursId, date) {
    return (dispatch) => {
        dispatch(removeHoursBegin());

        axios({
            method: "delete",
            url: `${getUrl()}/api/v1/hours/${hoursId}/?date=${date}`,
        })
            .then((res) => {
                try {
                    dispatch(removeHoursSuccess(hoursId));
                    dispatch(getWeekHours(moment(date, "YYYY-MM-DD")));
                } catch (error) {
                    console.error(error);
                }

                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to remove hours.", { type: "error" });
                dispatch(removeHoursFailure(error));
            });
    };
}
