export const CLIENT_SELECT = "CLIENT_SELECT";

export const selectClientBegin = (clientId) => ({
    type: CLIENT_SELECT,
    payload: clientId,
});

export function selectClient(clientId) {
    return (dispatch) => {
        dispatch(selectClientBegin(clientId));
    };
}
