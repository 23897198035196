import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../getBackend";

import { getWeekHours } from "./getWeekHours";
import {
    setBreakTime,
    setCommentTime,
    setEndTime,
    setStartTime,
    setTotalTime,
} from "../../components/RegisterPage/actions";
import { getHours } from "./getHours";

export const CREATE_HOURS_BEGIN = "CREATE_HOURS_BEGIN";
export const CREATE_HOURS_SUCCESS = "CREATE_HOURS_SUCCESS";
export const CREATE_HOURS_FAILURE = "CREATE_HOURS_FAILURE";

export const createHoursBegin = () => ({
    type: CREATE_HOURS_BEGIN,
});

export const createHoursSuccess = (data, user, rate) => ({
    type: CREATE_HOURS_SUCCESS,
    payload: { ...data, user, rate },
});

export const createHoursFailure = (error) => ({
    type: CREATE_HOURS_FAILURE,
    payload: { error },
});

export function createHours(hours, user, date, rate) {
    return (dispatch) => {
        dispatch(createHoursBegin());

        axios({
            method: "post",
            url: `${getUrl()}/api/v1/hours/`,
            data: {
                date: hours.date,
                duration: `${hours.total}:00`,
                end_time: `${hours.end}:00`,
                start_time: `${hours.start}:00`,
                project: hours.projectId,
                rate: hours.rate,
                pause: hours.break ? `${hours.break}:00` : "00:00:00",
                comment: hours.comment,
            },
        })
            .then((res) => {
                dispatch(createHoursSuccess(res.data, user, rate));
                dispatch(getWeekHours(date));
                dispatch(getHours(date.format("YYYY-MM-DD")));

                // Clear the inputs
                dispatch(setStartTime("00:00"));
                dispatch(setEndTime("00:00"));
                dispatch(setBreakTime("00:00"));
                dispatch(setTotalTime("00:00"));
                dispatch(setCommentTime(""));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to save.", { type: "error" });
                dispatch(createHoursFailure(error));
            });
    };
}
