import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../getBackend";

export const GET_RECURRING_PAYMENTS_BEGIN = "GET_RECURRING_PAYMENTS_BEGIN";
export const GET_RECURRING_PAYMENTS_SUCCESS = "GET_RECURRING_PAYMENTS_SUCCESS";
export const GET_RECURRING_PAYMENTS_FAILURE = "GET_RECURRING_PAYMENTS_FAILURE";

export const getRecurringPaymentsBegin = () => ({
    type: GET_RECURRING_PAYMENTS_BEGIN,
});

export const getRecurringPaymentsSuccess = (data) => ({
    type: GET_RECURRING_PAYMENTS_SUCCESS,
    payload: { ...data },
});

export const getRecurringPaymentsFailure = (error) => ({
    type: GET_RECURRING_PAYMENTS_FAILURE,
    payload: { error },
});

export function getRecurringPayments(projectId) {
    return (dispatch) => {
        dispatch(getRecurringPaymentsBegin());

        axios(`${getUrl()}/api/v1/recurring_payments/?project_id=${projectId}`)
            .then((res) => {
                dispatch(getRecurringPaymentsSuccess(res.data));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to get recurring payments.", { type: "error" });
                dispatch(getRecurringPaymentsFailure(error));
            });
    };
}
