import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { toggleCreateRateModal } from "./actions/toggleCreateRateModal";
import { removeRate } from "./actions/removeRate";
import { Table } from "../shared";

class RatesSection extends Component {
    render() {
        const ratesList = this.props.rates.map((rate) => (
            <tr key={rate.id}>
                <td>{rate.name}</td>
                <td>€ {rate.price.replace(".", ",")}</td>
                <td>
                    <div
                        className="delete"
                        onClick={() =>
                            this.props.removeRate(this.props.projectId, rate.id)
                        }
                        role="button"
                    />
                </td>
            </tr>
        ));

        return (
            <div>
                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Price</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>{ratesList}</tbody>
                </Table>
                <button
                    className="button is-link"
                    type="button"
                    onClick={() => this.props.toggleCreateRateModal()}
                >
                    Add new rate
                </button>
            </div>
        );
    }
}

RatesSection.propTypes = {
    toggleCreateRateModal: PropTypes.func.isRequired,
    removeRate: PropTypes.func.isRequired,
    rates: PropTypes.array,
    projectId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    projectId: state.projects.projectDetailPage,
    rates: state.projects.projects.results.find(
        (project) => project.id === state.projects.projectDetailPage
    ).rates,
});

const mapDispatchToProps = {
    toggleCreateRateModal,
    removeRate,
};

export default connect(mapStateToProps, mapDispatchToProps)(RatesSection);
