/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import TimeInput from "./TimeInput";
import TotalTimeInput from "./TotalTimeInput";

import {
    setBreakTime,
    setEndTime,
    setStartTime,
    setTotalTime,
} from "../actions";
import { calculateTotal } from "./hourHelpers";

class HourInput extends Component {
    constructor(props) {
        super(props);

        this.startChange = this.startChange.bind(this);
        this.endChange = this.endChange.bind(this);
        this.breakChange = this.breakChange.bind(this);
        this.totalChange = this.totalChange.bind(this);
    }

    startChange(time) {
        this.props.setStartTime(time);
        this.props.setTotalTime(
            calculateTotal(time, this.props.end, this.props.break)
        );
    }

    endChange(time) {
        this.props.setEndTime(time);
        this.props.setTotalTime(
            calculateTotal(this.props.start, time, this.props.break)
        );
    }

    breakChange(time) {
        this.props.setBreakTime(time);
        this.props.setTotalTime(
            calculateTotal(this.props.start, this.props.end, time)
        );
    }

    totalChange(time) {
        this.props.setTotalTime(time);
    }

    render() {
        return (
            <div style={{ marginBottom: "1em" }}>
                <div className="columns">
                    <div className="column">
                        <div className="columns">
                            <div className="column">
                                <TimeInput
                                    value={this.props.start}
                                    onChange={this.startChange}
                                />
                            </div>
                            <div className="column">
                                <TimeInput
                                    value={this.props.end}
                                    onChange={this.endChange}
                                />
                            </div>
                        </div>
                        <TimeInput
                            value={this.props.break}
                            onChange={this.breakChange}
                        />
                    </div>
                    <div className="column">
                        <div className="field">
                            <TotalTimeInput key="TotalTimeWrapper" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

HourInput.propTypes = {
    setStartTime: PropTypes.func.isRequired,
    setEndTime: PropTypes.func.isRequired,
    setBreakTime: PropTypes.func.isRequired,
    setTotalTime: PropTypes.func.isRequired,
    start: PropTypes.string,
    end: PropTypes.string,
    break: PropTypes.string,
};

const mapStateToProps = (state) => ({
    start: state.register.time.start,
    end: state.register.time.end,
    break: state.register.time.break,
});

const mapDispatchToProps = {
    setStartTime,
    setEndTime,
    setBreakTime,
    setTotalTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(HourInput);
