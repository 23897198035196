import * as React from "react";
import { CommentSummaryModal } from "./CommentSummaryModal";

interface IProps {
    hour: { comment: string; comment_total: number };
    children?: React.ReactNode;
}

export const HourComment: React.FC<IProps> = (props) => {
    const [modalOpen, setModalOpen] = React.useState(false);

    if (props.hour.comment_total === null) {
        return <>{props.children}</>;
    }

    return (
        <>
            {modalOpen && (
                <CommentSummaryModal
                    comment={props.hour.comment}
                    onClose={() => setModalOpen(false)}
                />
            )}
            <span
                className="tooltip"
                onClick={() => {
                    if (props.hour) {
                        setModalOpen(true);
                    }
                }}
                data-tooltip={`${props.hour.comment} - ${props.hour.comment_total}`}
            >
                {props.children ? (
                    props.children
                ) : (
                    <span>
                        {props.hour.comment && props.hour.comment.slice(0, 10)}
                        {props.hour.comment &&
                            props.hour.comment.length > 10 &&
                            "..."}
                    </span>
                )}
            </span>
        </>
    );
};
