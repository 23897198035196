import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { resetFilters } from "../../actions";

export class ResetButton extends Component {
    render() {
        return (
            <button
                type="button"
                className="button is-link"
                onClick={() => this.props.resetFilters()}
            >
                Reset filters
            </button>
        );
    }
}

ResetButton.propTypes = {
    resetFilters: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = { resetFilters };

export default connect(mapStateToProps, mapDispatchToProps)(ResetButton);
