import React, { Component } from "react";
import { LocalForm } from "react-redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { updateProfile } from "../../../actions/user/updateProfile";
import { Input } from "../../shared";

class ProfileSection extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(form) {
        this.props.updateProfile(this.props.id, form);
    }

    render() {
        const initialProfileForm = {
            ...this.props,
        };

        return (
            <div className="column is-6">
                <h1 className="title is-3">Profile</h1>
                <hr />
                {initialProfileForm.email !== null && (
                    <LocalForm
                        initialState={initialProfileForm}
                        onSubmit={(profileForm) => this.onSubmit(profileForm)}
                    >
                        <Input label="Email" id=".email" />
                        <Input label="First name" id=".first_name" />
                        <Input label="Last name" id=".last_name" />
                        <Input
                            label="Ledger number"
                            id=".ledger_number"
                            required={false}
                        />
                        <Input
                            label="JIRA Username"
                            id=".jira_user_name"
                            required={false}
                        />
                        <Input
                            label="JIRA API key"
                            id=".jira_api_key"
                            required={false}
                        />

                        <Input
                            label="TEMPO Account ID"
                            id=".tempo_account_id"
                            required={false}
                        />

                        <Input
                            label="Over budget email"
                            id=".over_budget_email"
                            required={false}
                        />

                        <Input
                            label="Over budget threshold"
                            id=".over_budget_threshold"
                            required={false}
                        />

                        <Input
                            label="External"
                            id=".external"
                            type="checkbox"
                            required={false}
                        />

                        <button type="submit" className="button is-success">
                            Update profile
                        </button>
                    </LocalForm>
                )}
                {initialProfileForm.email === null && (
                    <span className="icon is-large">
                        <i className="fas fa-spinner fa-spin" />
                    </span>
                )}
            </div>
        );
    }
}

/* eslint react/no-unused-prop-types: off */
ProfileSection.propTypes = {
    updateProfile: PropTypes.func.isRequired,
    id: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    external: PropTypes.bool,
    ledger_number: PropTypes.string,
};

const mapStateToProps = (state) => ({
    id: state.user.id,
    email: state.user.email,
    first_name: state.user.first_name,
    last_name: state.user.last_name,
    external: state.user.external,
    ledger_number: state.user.ledger_number,
    jira_user_name: state.user.jira_user_name,
    jira_api_key: state.user.jira_api_key,
    tempo_account_id: state.user.tempo_account_id,
    over_budget_email: state.user.over_budget_email,
    over_budget_threshold: state.user.over_budget_threshold,
});

export default connect(mapStateToProps, { updateProfile })(ProfileSection);
