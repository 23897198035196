import React from "react";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";

const Menu = ({ match }) => (
    <div className="column is-3">
        <aside className="menu">
            <ul className="menu-list">
                <li>
                    <NavLink
                        to={`/projects/${match.params.project}`}
                        exact
                        activeClassName="is-active"
                    >
                        General
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={`/projects/${match.params.project}/types`}
                        activeClassName="is-active"
                    >
                        Rates
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={`/projects/${match.params.project}/payments`}
                        activeClassName="is-active"
                    >
                        Recurring payments
                    </NavLink>
                </li>
            </ul>
        </aside>
    </div>
);

Menu.propTypes = {
    match: PropTypes.object.isRequired,
};

export default Menu;
