import { createReducer } from "../../reducers/reducerUtilities";

import {
    REGISTER_SELECT_CLIENT,
    REGISTER_SELECT_PROJECT,
    REGISTER_SET_DATE,
    REGISTER_SET_START_TIME,
    REGISTER_SET_END_TIME,
    REGISTER_SET_BREAK_TIME,
    REGISTER_SET_TOTAL_TIME,
    REGISTER_SET_COMMENT_TIME,
    REGISTER_SELECT_RATE,
    REGISTER_RESET_INPUT,
    TOGGLE_ONLY_OWN_HOURS,
} from "./actions";

const initialState = {
    client: null,
    project: null,
    date: null,
    rate: null,
    onlyShowOwnHours: false,
    time: {
        start: "",
        end: "",
        break: "",
        total: "",
        comment: "",
    },
};

function setRate(state, action) {
    return {
        ...state,
        rate: action.payload,
    };
}

function setClient(state, action) {
    return {
        ...state,
        client: action.payload,
        project: null,
    };
}

function setProject(state, action) {
    return {
        ...state,
        project: action.payload,
    };
}

function setDate(state, action) {
    return {
        ...state,
        date: action.payload,
    };
}

function setStartTime(state, action) {
    return {
        ...state,
        time: {
            ...state.time,
            start: action.payload,
        },
    };
}

function setEndTime(state, action) {
    return {
        ...state,
        time: {
            ...state.time,
            end: action.payload,
        },
    };
}

function setBreakTime(state, action) {
    return {
        ...state,
        time: {
            ...state.time,
            break: action.payload,
        },
    };
}

function setTotalTime(state, action) {
    return {
        ...state,
        time: {
            ...state.time,
            total: action.payload,
        },
    };
}

function setCommentTime(state, action) {
    return {
        ...state,
        time: {
            ...state.time,
            comment: action.payload,
        },
    };
}

function resetInputs(state) {
    return {
        ...state,
        client: null,
        project: null,
        rate: null,
        time: {
            ...initialState.time,
        },
    };
}

function onlyShowOwnHours(state) {
    return {
        ...state,
        onlyShowOwnHours: !state.onlyShowOwnHours,
    };
}

const handlers = {};
handlers[REGISTER_SELECT_CLIENT] = setClient;
handlers[REGISTER_SELECT_PROJECT] = setProject;
handlers[REGISTER_SET_DATE] = setDate;
handlers[REGISTER_SET_START_TIME] = setStartTime;
handlers[REGISTER_SET_END_TIME] = setEndTime;
handlers[REGISTER_SET_BREAK_TIME] = setBreakTime;
handlers[REGISTER_SET_TOTAL_TIME] = setTotalTime;
handlers[REGISTER_SET_COMMENT_TIME] = setCommentTime;
handlers[REGISTER_SELECT_RATE] = setRate;
handlers[REGISTER_RESET_INPUT] = resetInputs;
handlers[TOGGLE_ONLY_OWN_HOURS] = onlyShowOwnHours;

const registerReducer = createReducer(initialState, handlers);

export default registerReducer;
