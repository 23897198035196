import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { toggleCreateClientModal } from "./actions/toggleCreateClientModal";

class TopComponent extends Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.props.toggleCreateClientModal();
    }

    render() {
        return (
            <div style={{ marginBottom: "2rem" }}>
                <nav className="level box">
                    <div className="level-left">
                        <div className="level-item">
                            <p className="subtitle is-5">
                                <strong>{this.props.numberOfClients}</strong>{" "}
                                clients with{" "}
                                <strong>{this.props.numberOfProjects}</strong>{" "}
                                projects.
                            </p>
                        </div>
                    </div>

                    <div className="level-right">
                        {/* <div className="level-item">
              <div className="field has-addons">
                <p className="control">
                  <input
                    className="input"
                    autoComplete="off"
                    type="text"
                    placeholder="Type a query"
                  />
                </p>
                <p className="control">
                  <button className="button">Search</button>
                </p>
              </div>
            </div> */}
                        <p className="level-item">
                            <button
                                className="button is-success"
                                onClick={this.toggleModal}
                                type="button"
                            >
                                New client
                            </button>
                        </p>
                    </div>
                </nav>
            </div>
        );
    }
}

TopComponent.propTypes = {
    toggleCreateClientModal: PropTypes.func.isRequired,
    numberOfClients: PropTypes.number,
    numberOfProjects: PropTypes.number,
};

const mapStateToProps = (state) => ({
    numberOfClients: state.clients.clients.count,
    numberOfProjects: state.projects.projects.count,
});

export default connect(mapStateToProps, { toggleCreateClientModal })(
    TopComponent
);
