/* eslint camelcase: off */
import axios from "axios";
import { toast } from "react-toastify";
import { actions } from "react-redux-form";
import { getUrl } from "../../getBackend";

export const CREATE_INVITE_BEGIN = "CREATE_INVITE_BEGIN";
export const CREATE_INVITE_SUCCESS = "CREATE_INVITE_SUCCESS";
export const CREATE_INVITE_FAILURE = "CREATE_INVITE_FAILURE";

export const sendInviteBegin = () => ({
    type: CREATE_INVITE_BEGIN,
});

export const sendInviteSuccess = (data) => ({
    type: CREATE_INVITE_SUCCESS,
    payload: { ...data },
});

export const sendInviteFailure = (error) => ({
    type: CREATE_INVITE_FAILURE,
    payload: { error },
});

export function sendInvite(groupId, email, first_name, last_name) {
    return (dispatch) => {
        dispatch(sendInviteBegin());

        axios({
            method: "post",
            url: `${getUrl()}/api/v1/groups/${groupId}/invite/`,
            data: {
                first_name,
                last_name,
                email,
            },
        })
            .then((res) => {
                dispatch(sendInviteSuccess(res.data));
                toast("Invite sent succesfully!", { type: "success" });
                dispatch(actions.reset("inviteSectionForm"));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to get send invite.", { type: "error" });
                dispatch(sendInviteFailure(error));
            });
    };
}
