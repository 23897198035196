import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "react-redux-form";
import PropTypes from "prop-types";

import { sendInvite } from "../../../actions/group/sendInvite";
import { Input } from "../../shared";

class InviteSection extends Component {
    sendInvite(values) {
        this.props.sendInvite(
            this.props.organization_id,
            values.email,
            values.first_name,
            values.last_name
        );
    }

    render() {
        return (
            <div className="column is-6">
                <h1 className="title is-3">Invite new member</h1>
                <hr />

                <Form
                    model="inviteSectionForm"
                    onSubmit={(values) => this.sendInvite(values)}
                >
                    <Input
                        label="First name"
                        id="inviteSectionForm.first_name"
                    />
                    <Input label="Last name" id="inviteSectionForm.last_name" />
                    <Input label="Email address" id="inviteSectionForm.email" />

                    <div className="field">
                        <div className="control">
                            <button className="button is-success" type="submit">
                                Send invite
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

InviteSection.propTypes = {
    sendInvite: PropTypes.func.isRequired,
    organization_id: PropTypes.string,
};

const mapStateToProps = (state) => ({
    organization_id: state.user.group_id,
});

const mapDispatchToProps = {
    sendInvite,
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteSection);
