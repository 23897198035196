import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import moment from "moment";
import { NavBar, LoadingPage } from "../shared";
import TopComponent from "./TopComponent";
import ControlBox from "./ControlBox";
import DateList from "./DateList";
import HoursOverview from "./HoursOverview";

import { resetRegister, setDate } from "./actions";
import { getHours } from "../../actions/hours/getHours";

class RegisterPage extends Component {
    componentWillMount() {
        this.props.getHours(moment().format("YYYY-MM-DD"));
        this.props.setDate(moment());
    }

    componentWillUnmount() {
        this.props.resetRegister();
    }

    render() {
        const { history, clientsLoading, projectsLoading } = this.props;

        if (clientsLoading || projectsLoading) {
            return <LoadingPage history={history} />;
        }

        return (
            <div>
                <NavBar history={this.props.history} />
                <div className="container box">
                    <TopComponent />
                    <div className="columns">
                        <div className="column is-4">
                            <ControlBox />
                        </div>
                        <div className="column">
                            <DateList />
                            <HoursOverview />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

RegisterPage.propTypes = {
    resetRegister: PropTypes.func.isRequired,
    getHours: PropTypes.func.isRequired,
    setDate: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    clientsLoading: PropTypes.bool,
    projectsLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    clientsLoading: state.clients.loading,
    projectsLoading: state.clients.loading,
});

const mapDispatchToProps = {
    resetRegister,
    getHours,
    setDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
