import * as React from "react";
import { IUser } from "../../../models/IUser";
import { getColorById } from "../../../shared/functions";
import { ChartDataset, ChartTypeRegistry } from "chart.js";
import { Chart } from "react-chartjs-2";

import "chart.js/auto";

interface IProps {
    revenuePerMonth: { [userId: string]: Array<{ x: number; y: number }> };
    users: IUser[];
}

export const YearOverviewBarChart: React.FC<IProps> = (props) => {
    const dataPerMonth = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    if (!props.revenuePerMonth) {
        return null;
    }

    const dataset: ChartDataset<keyof ChartTypeRegistry, number[]>[] = [
        // Actual bars.
        ...Object.entries(props.revenuePerMonth).map(
            ([userId, values]): ChartDataset<
                keyof ChartTypeRegistry,
                number[]
            > => ({
                type: "bar",
                backgroundColor: getColorById(userId),
                data: values.map((x) => x.y),
                label: props.users.find((x) => x.id === userId)!.first_name,
                order: 1,
            })
        ),
        // Average
        ...Object.entries(props.revenuePerMonth).map(
            ([userId, values]): ChartDataset<
                keyof ChartTypeRegistry,
                number[]
            > => {
                const actualValues = values.filter((x) => x.y);
                const avg = Number(
                    (
                        actualValues.reduce((previousValue, currentValue) => {
                            return previousValue + currentValue.y;
                        }, 0) / actualValues.length
                    ).toFixed(2)
                );
                return {
                    type: "line",
                    backgroundColor: getColorById(userId),
                    borderColor: getColorById(userId),
                    data: values.map(() => avg),
                    label: `Avg ${
                        props.users.find((x) => x.id === userId)!.first_name
                    }`,
                };
            }
        ),
    ];

    return (
        <Chart
            type="bar"
            options={{
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback(tickValue): string {
                                return new Intl.NumberFormat("nl-NL", {
                                    style: "currency",
                                    currency: "EUR",
                                }).format(Number(tickValue));
                            },
                        },
                    },
                },
            }}
            data={{
                labels: dataPerMonth,
                datasets: dataset,
            }}
        />
    );
};
