import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../../getBackend";

export const GET_HOUR_SUMMARY_BEGIN = "GET_HOUR_SUMMARY_BEGIN";
export const GET_HOUR_SUMMARY_SUCCESS = "GET_HOUR_SUMMARY_SUCCESS";
export const GET_HOUR_SUMMARY_FAILURE = "GET_HOUR_SUMMARY_FAILURE";

export const getHourSummaryBegin = () => ({
    type: GET_HOUR_SUMMARY_BEGIN,
});

export const getHourSummarySuccess = (data) => ({
    type: GET_HOUR_SUMMARY_SUCCESS,
    payload: { ...data },
});

export const getHourSummaryFailure = (error) => ({
    type: GET_HOUR_SUMMARY_FAILURE,
    payload: { error },
});

export function getHourSummary(invoiceId) {
    return (dispatch) => {
        dispatch(getHourSummaryBegin());

        axios(`${getUrl()}/api/v1/invoices/${invoiceId}/hour_summary/`)
            .then((res) => {
                dispatch(getHourSummarySuccess(res.data));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to get invoices.", { type: "error" });
                dispatch(getHourSummaryFailure(error));
            });
    };
}
