import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setYear } from "../../actions";
import { getYearList } from "../../../../shared/functions";

class YearButton extends Component {
    render() {
        return (
            <div
                className="dropdown is-hoverable"
                style={{ paddingRight: "8px" }}
            >
                <div className="dropdown-trigger">
                    <button
                        className="button"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu"
                        type="button"
                    >
                        <span>{this.props.year}</span>
                        <span className="icon is-small">
                            <i
                                className="fas fa-angle-down"
                                aria-hidden="true"
                            />
                        </span>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                        {getYearList().map((year) => (
                            <span
                                className="dropdown-item"
                                key={year}
                                onClick={() =>
                                    this.props.setYear(this.props.month, year)
                                }
                            >
                                {year}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

YearButton.propTypes = {
    year: PropTypes.number,
    month: PropTypes.number,
    setYear: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    year: state.overview.year,
    month: state.overview.month,
});

const mapDispatchToProps = { setYear };

export default connect(mapStateToProps, mapDispatchToProps)(YearButton);
