import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../getBackend";

export const INVITE_TO_USER_BEGIN = "INVITE_TO_USER_BEGIN";
export const INVITE_TO_USER_SUCCESS = "INVITE_TO_USER_SUCCESS";
export const INVITE_TO_USER_FAILURE = "INVITE_TO_USER_FAILURE";

export const inviteToUserBegin = () => ({
    type: INVITE_TO_USER_BEGIN,
});

export const inviteToUserSuccess = (data) => ({
    type: INVITE_TO_USER_SUCCESS,
    payload: { ...data },
});

export const inviteToUserFailure = (error) => ({
    type: INVITE_TO_USER_FAILURE,
    payload: { error },
});
export function inviteToUser(inviteId, password) {
    return (dispatch) => {
        dispatch(inviteToUserBegin());

        axios({
            method: "post",
            url: `${getUrl()}/api/v1/users/accept_invite/`,
            data: {
                invite: inviteId,
                password,
            },
        })
            .then((res) => {
                dispatch(inviteToUserSuccess(res.data));
                // dispatch(addProjectToClient(project.client_id, res.data.id));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to create your account.", { type: "error" });
                dispatch(inviteToUserFailure(error));
            });
    };
}
