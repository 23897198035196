import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../getBackend";

export const CREATE_USER_BEGIN = "CREATE_USER_BEGIN";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

export const createUserBegin = () => ({
    type: CREATE_USER_BEGIN,
});

export const createUserSuccess = (data) => ({
    type: CREATE_USER_SUCCESS,
    payload: { ...data },
});

export const createUserFailure = (error) => ({
    type: CREATE_USER_FAILURE,
    payload: { error },
});

export function createUser(user) {
    return (dispatch) => {
        dispatch(createUserBegin());

        axios({
            method: "post",
            url: `${getUrl()}/api/v1/users/`,
            data: { ...user },
        })
            .then((res) => {
                dispatch(createUserSuccess(res.data));
                /* eslint no-restricted-globals: off */
                location.href = "/login";
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to create user.", { type: "error" });
                dispatch(createUserFailure(error));
            });
    };
}
