import React from "react";
import PropTypes from "prop-types";

const ModalActionButton = ({ form, text }) => (
    <div>
        <button type="submit" className="button is-success" form={form}>
            {text}
        </button>
    </div>
);

ModalActionButton.propTypes = {
    form: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default ModalActionButton;
