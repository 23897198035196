import {
    CREATE_CLIENT_BEGIN,
    CREATE_CLIENT_SUCCESS,
    CREATE_CLIENT_FAILURE,
} from "../components/ClientPage/actions/createClient";

import {
    REMOVE_CLIENT_BEGIN,
    REMOVE_CLIENT_SUCCESS,
    REMOVE_CLIENT_FAILURE,
} from "../components/ClientPage/actions/removeClient";

import {
    GET_CLIENTS_BEGIN,
    GET_CLIENTS_SUCCESS,
} from "../components/ClientPage/actions/getClients";

import { ADD_PROJECT_TO_CLIENT } from "../components/ClientPage/actions/addProjectToClient";

import { CLIENT_SELECT } from "../components/ClientPage/actions/selectClient";

import { CREATE_CLIENT_MODAL_TOGGLE } from "../components/ClientPage/actions/toggleCreateClientModal";
import { CLIENT_EDIT_MODAL_TOGGLE } from "../components/ClientPage/actions/toggleEditClientModal";

import { REMOVE_PROJECT_FROM_CLIENT } from "../components/ClientPage/actions/removeProjectFromClient";

import {
    UPDATE_CLIENT_BEGIN,
    UPDATE_CLIENT_FAILURE,
    UPDATE_CLIENT_SUCCESS,
} from "../components/ClientPage/actions/updateClient";

import { createReducer } from "./reducerUtilities";

const initialState = {
    loading: false,
    error: null,
    selected: null,
    clients: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    clientCreateModal: {
        toggled: false,
        loading: false,
        error: null,
    },
    clientEditModal: {
        toggled: false,
    },
    isRemoving: false,
    isRemovingError: null,
    isUpdating: false,
    isUpdatingError: null,
};

function updateClientBegin(state) {
    return {
        ...state,
        isUpdating: true,
    };
}

function updateClientSuccess(state, action) {
    return {
        ...state,
        isUpdating: false,
        clients: {
            ...state.clients,
            results: state.clients.results.map((client) => {
                if (client.id === action.payload.id) {
                    return {
                        ...action.payload,
                    };
                }
                return client;
            }),
        },
    };
}

function updateClientFailure(state, action) {
    return {
        ...state,
        isUpdating: false,
        isUpdatingError: action.payload,
    };
}

function toggleEditClientModal(state) {
    return {
        ...state,
        clientEditModal: {
            ...state.clientEditModal,
            toggled: !state.clientEditModal.toggled,
        },
    };
}

function toggleModal(state) {
    return {
        ...state,
        clientCreateModal: {
            ...state.clientCreateModal,
            toggled: !state.clientCreateModal.toggled,
        },
    };
}

function createClientBegin(state) {
    return {
        ...state,
        clientCreateModal: {
            ...state.clientCreateModal,
            loading: true,
        },
    };
}

function createClientSuccess(state, action) {
    return {
        ...state,
        clients: {
            ...state.clients,
            results: [...state.clients.results, action.payload],
            count: state.clients.count + 1,
        },
        clientCreateModal: {
            ...state.clientCreateModal,
            loading: false,
            error: null,
        },
    };
}

function createClientFailure(state, action) {
    return {
        ...state,
        clientCreateModal: {
            ...state.clientCreateModal,
            loading: false,
            error: action.payload,
        },
    };
}

function getClientsBegin(state) {
    return {
        ...state,
        loading: true,
    };
}

function getClientsSuccess(state, action) {
    return {
        ...state,
        clients: action.payload,
        loading: false,
        selected:
            action.payload.count > 0 ? action.payload.results[0].id : null,
    };
}

function selectClient(state, action) {
    return {
        ...state,
        selected: action.payload,
    };
}

function removeClientBegin(state) {
    return {
        ...state,
        isRemoving: true,
    };
}

function removeClientSuccess(state, action) {
    return {
        ...state,
        selected: null,
        isRemoving: false,
        isRemovingError: null,
        clients: {
            ...state.clients,
            results: state.clients.results.filter(
                (client) => client.id !== action.payload
            ),
            count: state.clients.count - 1,
        },
    };
}

function removeClientFailure(state, action) {
    return {
        ...state,
        isRemoving: false,
        isRemovingError: action.payload,
    };
}

function addProjectToClient(state, action) {
    return {
        ...state,
        clients: {
            ...state.clients,
            results: state.clients.results.map((client) => {
                if (client.id === action.payload.clientId) {
                    return {
                        ...client,
                        projects: [
                            ...client.projects,
                            action.payload.projectId,
                        ],
                    };
                }
                return client;
            }),
        },
    };
}

function removeProjectFromClient(state, action) {
    return {
        ...state,
        clients: {
            ...state.clients,
            results: state.clients.results.map((client) => {
                if (client.id === action.payload.clientId) {
                    return {
                        ...client,
                        projects: client.projects.filter(
                            (project) => project !== action.payload.projectId
                        ),
                    };
                }
                return client;
            }),
        },
    };
}

const handlers = {};
handlers[CREATE_CLIENT_MODAL_TOGGLE] = toggleModal;
handlers[CREATE_CLIENT_BEGIN] = createClientBegin;
handlers[CREATE_CLIENT_SUCCESS] = createClientSuccess;
handlers[CREATE_CLIENT_FAILURE] = createClientFailure;
handlers[GET_CLIENTS_BEGIN] = getClientsBegin;
handlers[GET_CLIENTS_SUCCESS] = getClientsSuccess;
handlers[CLIENT_SELECT] = selectClient;
handlers[REMOVE_CLIENT_BEGIN] = removeClientBegin;
handlers[REMOVE_CLIENT_SUCCESS] = removeClientSuccess;
handlers[REMOVE_CLIENT_FAILURE] = removeClientFailure;
handlers[ADD_PROJECT_TO_CLIENT] = addProjectToClient;
handlers[REMOVE_PROJECT_FROM_CLIENT] = removeProjectFromClient;
handlers[CLIENT_EDIT_MODAL_TOGGLE] = toggleEditClientModal;
handlers[UPDATE_CLIENT_BEGIN] = updateClientBegin;
handlers[UPDATE_CLIENT_SUCCESS] = updateClientSuccess;
handlers[UPDATE_CLIENT_FAILURE] = updateClientFailure;

const clientsReducer = createReducer(initialState, handlers);

export default clientsReducer;
