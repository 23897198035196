import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { createClient } from "./actions/createClient";
import { toggleCreateClientModal } from "./actions/toggleCreateClientModal";
import Actions from "./Actions";
import { setProjectDetail } from "../ProjectPage/actions/setDetailPage";
import { Table } from "../shared";

class SelectedClient extends Component {
    constructor(props) {
        super(props);

        this.getClient = this.getClient.bind(this);
    }

    getClient() {
        return this.props.clients.find(
            (client) => client.id === this.props.selectedId
        );
    }

    render() {
        if (this.props.loadingProjects || this.props.loadingClients) {
            return (
                <div className="box">
                    <p className="title is-5">Loading...</p>
                </div>
            );
        }

        if (!this.props.selectedId) {
            return (
                <div className="box">
                    <h2 className="title is-5">Select a client.</h2>
                </div>
            );
        }

        const projects = this.props.projects
            .filter((project) => project.client_id === this.props.selectedId)
            .map((project) => (
                <tr key={project.id}>
                    <td>{project.name}</td>
                    <td>{project.project_number}</td>
                    <td>{project.total_hours}</td>
                    <td>
                        <Link
                            to={`/projects/${project.id}`}
                            className="button is-small is-link"
                            type="button"
                            onClick={() => {
                                this.props.setProjectDetail(project.id);
                            }}
                        >
                            settings
                        </Link>
                    </td>
                </tr>
            ));

        return (
            <div className="box">
                <div className="level">
                    <div className="level-left">
                        <h2 className="title is-5">{this.getClient().name}</h2>
                    </div>
                    <div className="level-right">
                        <Actions />
                    </div>
                </div>
                <hr />
                <h2 className="is-size-5">Projects</h2>
                <Table>
                    <thead>
                        <tr>
                            <th style={{ width: "25%" }}>Name</th>
                            <th>Project number</th>
                            <th>Hours worked</th>
                            <th style={{ width: "88px" }} />
                        </tr>
                    </thead>
                    <tbody>{projects}</tbody>
                </Table>
            </div>
        );
    }
}

SelectedClient.propTypes = {
    setProjectDetail: PropTypes.func.isRequired,
    selectedId: PropTypes.string,
    clients: PropTypes.array,
    projects: PropTypes.array,
    loadingProjects: PropTypes.bool,
    loadingClients: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    selectedId: state.clients.selected,
    clients: state.clients.clients.results,
    projects: state.projects.projects.results,
    loadingProjects: state.projects.loading,
    loadingClients: state.clients.loading,
});

export default connect(mapStateToProps, {
    createClient,
    toggleCreateClientModal,
    setProjectDetail,
})(SelectedClient);
