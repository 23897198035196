import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import { setDate, toggleOnlyShowOwnHours } from "./actions";
import { RecentTicketsModal } from "./RecentTicketsModal";
import { MixerModal } from "./MixerModal";

class TopComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projectId: "",
            showMixer: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.dateIsToday = this.dateIsToday.bind(this);
    }

    handleChange(date) {
        this.props.setDate(date, this.props.date);
    }

    dateIsToday() {
        return (
            this.props.date.format("DD-MM-YYYY") ===
            moment().format("DD-MM-YYYY")
        );
    }

    openSummary() {
        this.setState({
            projectId: "-",
        });
    }
    openMixer() {
        this.setState({
            showMixer: true,
        });
    }

    render() {
        return (
            <nav className="level">
                {this.state.projectId && (
                    <RecentTicketsModal
                        projectId={this.state.projectId}
                        onClose={() => this.setState({ projectId: "" })}
                    />
                )}
                {this.state.showMixer && (
                    <MixerModal
                        onClose={() => this.setState({ showMixer: false })}
                    />
                )}
                <div className="level-left">
                    <div className="level-item">
                        <p className="subtitle is-5">
                            {this.props.date.format("dddd D MMMM")}{" "}
                            {this.dateIsToday() ? "(Today)" : null}
                        </p>
                    </div>
                </div>

                <div className="level-right">
                    <div className="field" style={{ marginBottom: 0 }}>
                        <button
                            className="button"
                            type="submit"
                            onClick={() => this.openSummary()}
                        >
                            Summary
                        </button>
                        <button
                            className="button"
                            type="submit"
                            onClick={() => this.openMixer()}
                            style={{ marginLeft: "8px" }}
                        >
                            Mixer
                        </button>
                    </div>

                    <div className="field" style={{ marginBottom: 0 }}>
                        <input
                            className="is-checkradio"
                            id="onlyOwnHours"
                            type="checkbox"
                            name="onlyOwnHours"
                            checked={this.props.onlyShowOwnHours}
                            onChange={() => this.props.toggleOnlyShowOwnHours()}
                        />
                        <label htmlFor="onlyOwnHours">
                            Only show own hours
                        </label>
                    </div>

                    <div className="level-item">
                        <div className="field" style={{ width: "110px" }}>
                            <div className="control">
                                <DatePicker
                                    selected={this.props.date}
                                    onChange={this.handleChange}
                                    className="input"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="level-item">
                        <div className="field has-addons">
                            <p
                                className="control"
                                onClick={() =>
                                    this.props.setDate(
                                        this.props.date
                                            .clone()
                                            .subtract(1, "d"),
                                        this.props.date
                                    )
                                }
                            >
                                <an className="button">
                                    <span className="icon is-small">
                                        <i className="fas fa-chevron-left" />
                                    </span>
                                </an>
                            </p>
                            <p
                                className="control"
                                onClick={() =>
                                    this.dateIsToday()
                                        ? null
                                        : this.props.setDate(
                                              moment(),
                                              this.props.date
                                          )
                                }
                            >
                                <span className="button">
                                    <span>Today</span>
                                </span>
                            </p>
                            <p
                                className="control"
                                onClick={() =>
                                    this.props.setDate(
                                        this.props.date.clone().add(1, "d"),
                                        this.props.date
                                    )
                                }
                            >
                                <span className="button">
                                    <span className="icon is-small">
                                        <i className="fas fa-chevron-right" />
                                    </span>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

TopComponent.propTypes = {
    date: PropTypes.object.isRequired,
    setDate: PropTypes.func.isRequired,
    onlyShowOwnHours: PropTypes.bool.isRequired,
    toggleOnlyShowOwnHours: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    date: state.register.date,
    onlyShowOwnHours: state.register.onlyShowOwnHours,
});

const mapDispatchToProps = { setDate, toggleOnlyShowOwnHours };

export default connect(mapStateToProps, mapDispatchToProps)(TopComponent);
