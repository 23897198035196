import axios from "axios";
import { toast } from "react-toastify";
import { getGroup } from "../group/getGroup";
import { getUrl } from "../../getBackend";

export const GET_USER_DETAILS_BEGIN = "GET_USER_DETAILS_BEGIN";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";

export const getUserProfileBegin = () => ({
    type: GET_USER_DETAILS_BEGIN,
});

export const getUserProfileSuccess = (data) => ({
    type: GET_USER_DETAILS_SUCCESS,
    payload: { ...data },
});

export const getUserProfileFailure = (error) => ({
    type: GET_USER_DETAILS_FAILURE,
    payload: { error },
});

export function getUserData() {
    return (dispatch) => {
        dispatch(getUserProfileBegin());

        axios(`${getUrl()}/api/v1/users/me/`)
            .then((res) => {
                dispatch(getUserProfileSuccess(res.data));
                dispatch(getGroup(res.data.group_id));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to your profile data.", { type: "error" });
                dispatch(getUserProfileFailure(error));
            });
    };
}
