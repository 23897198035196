import React from "react";
import PropTypes from "prop-types";

import Input from "./Input";

const HorizontalInput = ({ label, ...rest }) => (
    <div className="field is-horizontal">
        <div className="field-label is-normal">
            <label className="label">{label}</label>
        </div>
        <div className="field-body">
            <Input placeholder={label} {...rest} />
        </div>
    </div>
);

HorizontalInput.propTypes = {
    label: PropTypes.string.isRequired,
};

export default HorizontalInput;
