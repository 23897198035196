export const REGISTER_RESET = "REGISTER_RESET";

const reset = () => ({
    type: REGISTER_RESET,
});

export function resetRegister() {
    return (dispatch) => {
        dispatch(reset());
    };
}
