import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class PrivateRoute extends Component {
    render() {
        const { component: ProtectedComponent, ...rest } = this.props;

        if (this.props.logged_in) {
            return (
                <Route
                    {...rest}
                    render={(props) => <ProtectedComponent {...props} />}
                />
            );
        }
        return <Redirect to="/login" />;
    }
}

PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
    logged_in: PropTypes.string,
};

const mapStateToProps = (state) => ({
    logged_in: state.user.token,
});

export default connect(mapStateToProps)(PrivateRoute);
