import axios from "axios";
import { getUrl } from "../../getBackend";

export const GET_WEEK_HOURS_BEGIN = "GET_WEEK_HOURS_BEGIN";
export const GET_WEEK_HOURS_SUCCESS = "GET_WEEK_HOURS_SUCCESS";
export const GET_WEEK_HOURS_FAILURE = "GET_WEEK_HOURS_FAILURE";

export const getWeekHoursBegin = () => ({
    type: GET_WEEK_HOURS_BEGIN,
});

export const getWeekHoursSuccess = (data) => ({
    type: GET_WEEK_HOURS_SUCCESS,
    payload: { ...data },
});

export const getWeekHoursFailure = (error) => ({
    type: GET_WEEK_HOURS_FAILURE,
    payload: { error },
});

export function getWeekHours(moment) {
    return (dispatch, getState) => {
        dispatch(getWeekHoursBegin());

        const date = moment.clone();
        const start = date.startOf("isoWeek").format("YYYY-MM-DD");
        const end = date.endOf("isoWeek").format("YYYY-MM-DD");
        const onlySelf = getState().register.onlyShowOwnHours;

        axios(
            `${getUrl()}/api/v1/hours/range/?start=${start}&end=${end}&only_self=${onlySelf}`
        )
            .then((res) => {
                try {
                    dispatch(getWeekHoursSuccess(res.data));
                } catch (error) {
                    console.error(error);
                }
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                dispatch(getWeekHoursFailure(error));
            });
    };
}
