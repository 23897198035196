import { getOverviewHours } from "./getHours";

export const OVERVIEW_SET_YEAR = "OVERVIEW_SET_YEAR";

export const setYearAction = (year) => ({
    type: OVERVIEW_SET_YEAR,
    payload: year,
});

export function setYear(month, year) {
    return (dispatch) => {
        dispatch(setYearAction(year));
        dispatch(getOverviewHours(month, year));
    };
}
