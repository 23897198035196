import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../getBackend";

import { removeProjectFromClient } from "../../ClientPage/actions/removeProjectFromClient";

export const REMOVE_PROJECT_BEGIN = "REMOVE_PROJECT_BEGIN";
export const REMOVE_PROJECT_SUCCESS = "REMOVE_PROJECT_SUCCESS";
export const REMOVE_PROJECT_FAILURE = "REMOVE_PROJECT_FAILURE";

export const removeProjectBegin = () => ({
    type: REMOVE_PROJECT_BEGIN,
});

export const removeProjectSuccess = (id) => ({
    type: REMOVE_PROJECT_SUCCESS,
    payload: id,
});

export const removeProjectFailure = (error) => ({
    type: REMOVE_PROJECT_FAILURE,
    payload: { error },
});

export function removeProject(projectId, clientId) {
    return (dispatch) => {
        dispatch(removeProjectBegin());

        axios({
            method: "delete",
            url: `${getUrl()}/api/v1/projects/${projectId}/`,
        })
            .then((res) => {
                dispatch(removeProjectSuccess(projectId));
                dispatch(removeProjectFromClient(clientId, projectId));
                toast("Project removed succesfully!", {
                    type: "success",
                });
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to remove project.", { type: "error" });
                dispatch(removeProjectFailure(error));
            });
    };
}
