import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LocalForm, Control } from "react-redux-form";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Modal, ModalActionButton, TimeCycleInput, Input } from "../shared";
import {
    toggleCreateRecurringPaymentModal,
    createRecurringPayment,
} from "./actions";

class CreateRecurringPaymentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment(),
        };
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    }

    onSubmit(values) {
        const data = {
            name: values.name,
            price: values.price,
            activity: values.activity,
            start_date: this.state.startDate.format("YYYY-MM-DD"),
            project: this.props.projectId,
            billing_cycle_interval: values.cycle_interval,
            billing_cycle_type: values.cycle_type,
            billing_cycle_amount: values.cycles,
        };

        this.props.createRecurringPayment(data);
    }

    handleChangeStartDate(date) {
        this.setState({
            startDate: date,
        });
    }

    render() {
        const initialForm = {
            cycle_type: "months",
            cycles: "0",
        };

        return (
            <Modal
                closeModal={this.props.toggleCreateRecurringPaymentModal}
                modalState={this.props.modalState}
                title="Create recurring payment"
                footerButtons={
                    <ModalActionButton
                        form="createRecurringPaymentForm"
                        text="Create recurring payment"
                    />
                }
            >
                <LocalForm
                    onSubmit={(values) => this.onSubmit(values)}
                    initialState={initialForm}
                    id="createRecurringPaymentForm"
                >
                    <Input
                        label="Payment name"
                        id=".name"
                        required
                        help="The activity that will be displayed on the invoice."
                    />

                    <div className="field">
                        <label className="label">Amount</label>

                        <div className="control has-icons-left has-icons-right">
                            <Control.input
                                style={{ width: "246px" }}
                                type="number"
                                step="0.01"
                                min="0"
                                model=".price"
                                autoComplete="off"
                                placeholder="0.00"
                                className="input"
                                required
                            />
                            <span className="icon is-small is-left">
                                <i className="fas fa-euro-sign" />
                            </span>
                        </div>
                        <p className="help">
                            The net amount that will be billed. Tax is
                            calculated automatically.
                        </p>
                    </div>
                    <TimeCycleInput
                        interval=".cycle_interval"
                        type=".cycle_type"
                        label="Every"
                    />

                    <div className="field">
                        <label className="label">
                            Number of recurring payment cycles
                        </label>

                        <div className="control">
                            <div className="select">
                                <Control.select model=".cycles" required>
                                    <option value="0">Indefinitly</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="2">3</option>
                                    <option value="2">4</option>
                                    <option value="5">5</option>
                                    <option value="5">6</option>
                                    <option value="12">12</option>
                                </Control.select>
                            </div>
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Start date</label>
                        <div className="control">
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleChangeStartDate}
                                className="input"
                            />
                        </div>
                    </div>
                </LocalForm>
            </Modal>
        );
    }
}

CreateRecurringPaymentModal.propTypes = {
    modalState: PropTypes.bool,
    toggleCreateRecurringPaymentModal: PropTypes.func.isRequired,
    createRecurringPayment: PropTypes.func.isRequired,
    projectId: PropTypes.string,
};

const mapStateToProps = (state) => ({
    modalState: state.payments.recurringPaymentModalToggle,
    projectId: state.projects.projectDetailPage,
});

const mapDispatchToProps = {
    toggleCreateRecurringPaymentModal,
    createRecurringPayment,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateRecurringPaymentModal);
