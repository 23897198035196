import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form } from "react-redux-form";
import moment from "moment";

import { Modal, ModalActionButton } from "../shared";
import { toggleCreateProjectModal } from "../ProjectPage/actions/toggleCreateProjectModal";
import { createProject } from "../ProjectPage/actions/createProject";
import ProjectForm from "../ProjectPage/ProjectForm";

class CreateProjectModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start_date: moment(),
            end_date: moment(),
            project_assignment: moment(),
            terms_and_conditions_signed: moment(),
        };
        this.dateChanged = this.dateChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    dateChanged(id, date) {
        const { state } = this;
        state[id] = date;
        this.setState(state);
    }

    handleSubmit(project) {
        this.props.createProject({
            ...project,
            start_date: this.state.start_date.format("YYYY-MM-DD"),
            end_date: this.state.end_date.format("YYYY-MM-DD"),
            project_assignment:
                this.state.project_assignment.format("YYYY-MM-DD"),
            terms_and_conditions_signed:
                this.state.terms_and_conditions_signed.format("YYYY-MM-DD"),
            client_id: this.props.selectedClientId,
        });
    }

    render() {
        return (
            <Modal
                closeModal={this.props.toggleCreateProjectModal}
                modalState={this.props.modalState}
                title="Create new project"
                footerButtons={
                    <ModalActionButton
                        form="createProjectForm"
                        text="Create project"
                    />
                }
            >
                <Form
                    model="createProjectForm"
                    onSubmit={(createProjectForm) =>
                        this.handleSubmit(createProjectForm)
                    }
                    id="createProjectForm"
                >
                    <ProjectForm dateChanged={this.dateChanged} />
                </Form>
            </Modal>
        );
    }
}

CreateProjectModal.propTypes = {
    createProject: PropTypes.func.isRequired,
    modalState: PropTypes.bool.isRequired,
    toggleCreateProjectModal: PropTypes.func.isRequired,
    selectedClientId: PropTypes.string,
};

const mapStateToProps = (state) => ({
    modalState: state.projects.createProject.toggled,
    selectedClientId: state.clients.selected,
});

const mapDispatchToProps = { toggleCreateProjectModal, createProject };

export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectModal);
