import axios from "axios";
import { toast } from "react-toastify";
import { actions } from "react-redux-form";
import { getUrl } from "../../../getBackend";

import { toggleCreateProjectModal } from "./toggleCreateProjectModal";
import { addProjectToClient } from "../../ClientPage/actions/addProjectToClient";

export const CREATE_PROJECT_BEGIN = "CREATE_PROJECT_BEGIN";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAILURE = "CREATE_PROJECT_FAILURE";

export const createProjectBegin = () => ({
    type: CREATE_PROJECT_BEGIN,
});

export const createProjectSuccess = (data) => ({
    type: CREATE_PROJECT_SUCCESS,
    payload: { ...data },
});

export const createProjectFailure = (error) => ({
    type: CREATE_PROJECT_FAILURE,
    payload: { error },
});

export function createProject(project) {
    return (dispatch) => {
        dispatch(createProjectBegin());

        axios({
            method: "post",
            url: `${getUrl()}/api/v1/projects/`,
            data: { ...project },
        })
            .then((res) => {
                dispatch(createProjectSuccess(res.data));
                dispatch(toggleCreateProjectModal());
                dispatch(addProjectToClient(project.client_id, res.data.id));
                dispatch(actions.reset("createProjectForm"));
                toast("Project created succesfully!", {
                    type: "success",
                });
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to create project.", { type: "error" });
                dispatch(createProjectFailure(error));
            });
    };
}
