import * as React from "react";
import { useMemo } from "react";
import { Hour } from "./models/Hour";
import { CommentSummaryModal } from "./CommentSummaryModal";
import moment from "moment";
import Timeline, {
    DateHeader,
    TimelineHeaders,
    TimelineItem,
    TimelineGroup,
} from "react-calendar-timeline";
// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css";
import "./HoursOnDayTimeLine.css";
import { getColorById } from "../../shared/functions";

interface IProps {
    hours: Hour[];
}

export const HoursOnDayTimeLine: React.FC<IProps> = (props) => {
    const isEnabled = useMemo(
        () => localStorage.getItem("hiddenFeatures") === "true",
        []
    );
    const [selectedHour, setSelectedHour] = React.useState<Hour | null>(null);

    const hours = useMemo(
        () =>
            props.hours.sort((a, b) =>
                a.start_time.localeCompare(b.start_time)
            ),
        [props.hours]
    );

    if (hours.length === 0 || !isEnabled) {
        return null;
    }

    const groups: TimelineGroup<{}>[] = [];
    const items: TimelineItem<{}>[] = [];

    const start = hours[0].start_time;
    let end = hours[0].end_time;

    hours.forEach((hour) => {
        if (hour.end_time.localeCompare(end) > 0) {
            end = hour.end_time;
        }
        if (!groups.some((group) => group.id === hour.user)) {
            groups.push({
                id: hour.user,
                title: hour.user,
            });
        }
        items.push({
            id: hour.id,
            group: hour.user,
            start_time: moment(hour.start_time, "HH:mm:ss").unix() * 1000,
            end_time: moment(hour.end_time, "HH:mm:ss").unix() * 1000,
            title: hour.comment,
            itemProps: {
                style: {
                    backgroundColor:
                        hour.id === "-" ? "grey" : getColorById(hour.user),
                    color: "black",
                    border: "1px solid grey",
                    zIndex: 20,
                    textOverflow: "ellipsis",
                    display: "flex",
                    padding: "2px",
                },
            },
        });
    });

    return (
        <div
            style={{
                maxWidth: "800px",
                margin: "0 auto",
            }}
        >
            {selectedHour && (
                <CommentSummaryModal
                    comment={selectedHour.comment}
                    onClose={() => setSelectedHour(null)}
                />
            )}

            <Timeline
                groups={groups}
                items={items}
                sidebarWidth={0}
                defaultTimeStart={moment(start, "HH:mm:ss").subtract(
                    "1",
                    "hour"
                )}
                defaultTimeEnd={moment(end, "HH:mm:ss").add("1", "hour")}
                onTimeChange={(
                    visibleStart,
                    visibleEnd,
                    updateScrollCanvas
                ) => {
                    // Don't go outside the current day.
                    if (visibleStart < moment().startOf("day").unix() * 1000) {
                        return;
                    }
                    if (visibleEnd > moment().endOf("day").unix() * 1000) {
                        return;
                    }

                    updateScrollCanvas(visibleStart, visibleEnd);
                }}
                maxZoom={60 * 60 * 24 * 1000}
                minZoom={60 * 60 * 1000}
                canMove={false}
                canResize={false}
                canChangeGroup={false}
                lineHeight={50}
                itemHeightRatio={0.9}
                stackItems={true}
                selected={[]}
                onItemDoubleClick={(itemId) =>
                    setSelectedHour(
                        hours.find((hour) => hour.id === itemId) || null
                    )
                }
            >
                <TimelineHeaders>
                    <DateHeader
                        unit="hour"
                        labelFormat={([start]) => start.format("HH")}
                    />
                </TimelineHeaders>
            </Timeline>
        </div>
    );
};
