import { toast } from "react-toastify";
import axios from "axios";
import { getUrl } from "../../getBackend";

export const USER_UPDATE_PASSWORD_BEGIN = "USER_UPDATE_PASSWORD_BEGIN";
export const USER_UPDATE_PASSWORD_SUCCESS = "USER_UPDATE_PASSWORD_SUCCESS";
export const USER_UPDATE_PASSWORD_FAILURE = "USER_UPDATE_PASSWORD_FAILURE";

/**
 * Update the user password.
 */
export function updatePassword(id, data) {
    return () => {
        axios({
            method: "post",
            url: `${getUrl()}/api/v1/users/${id}/set_password/`,
            data: { ...data },
        })
            .then((res) => {
                toast("Password updated!", { type: "success" });
                return res.data;
            })
            .catch(() => {
                toast("Unable to update password.", { type: "error" });
            });
    };
}
