import * as React from "react";
import axios from "axios";
import { getUrl } from "../../../../getBackend";
import { getInvoices } from "../actions";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

interface IProps {}

export const GenerateInvoiceButton: React.FC<IProps> = () => {
    const dispatch = useDispatch();
    const generateInvoices = () => {
        console.log("generate");

        axios
            .post(`${getUrl()}/api/v1/invoices/generate/`)
            .then((res) => {
                dispatch(getInvoices());
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to export invoice.", { type: "error" });
            });
    };

    return (
        <button
            className="button is-primary"
            type="button"
            onClick={() => generateInvoices()}
            style={{ marginLeft: "1em" }}
        >
            Generate invoices
        </button>
    );
};
