import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../getBackend";

export const OVERVIEW_GET_HOURS_BEGIN = "OVERVIEW_GET_HOURS_BEGIN";
export const OVERVIEW_GET_HOURS_SUCCESS = "OVERVIEW_GET_HOURS_SUCCESS";
export const OVERVIEW_GET_HOURS_FAILURE = "OVERVIEW_GET_HOURS_FAILURE";

export const getHoursBegin = () => ({
    type: OVERVIEW_GET_HOURS_BEGIN,
});

export const getHoursSuccess = (data) => ({
    type: OVERVIEW_GET_HOURS_SUCCESS,
    payload: { ...data },
});

export const getHoursFailure = (error) => ({
    type: OVERVIEW_GET_HOURS_FAILURE,
    payload: { error },
});

export function getOverviewHours(month, year) {
    return (dispatch) => {
        dispatch(getHoursBegin());

        axios(`${getUrl()}/api/v1/hours/month/`, {
            params: {
                month: month + 1,
                year,
            },
        })
            .then((res) => {
                dispatch(getHoursSuccess(res.data));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to get hours.", { type: "error" });
                dispatch(getHoursFailure(error));
            });
    };
}
