import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../getBackend";

import { toggleCreateRecurringPaymentModal } from "./toggleRecurringPaymentModal";

export const CREATE_RECURRING_PAYMENT_BEGIN = "CREATE_RECURRING_PAYMENT_BEGIN";
export const CREATE_RECURRING_PAYMENT_SUCCESS =
    "CREATE_RECURRING_PAYMENT_SUCCESS";
export const CREATE_RECURRING_PAYMENT_FAILURE =
    "CREATE_RECURRING_PAYMENT_FAILURE";

export const createRecurringPaymentBegin = () => ({
    type: CREATE_RECURRING_PAYMENT_BEGIN,
});

export const createRecurringPaymentSuccess = (data) => ({
    type: CREATE_RECURRING_PAYMENT_SUCCESS,
    payload: { ...data },
});

export const createRecurringPaymentFailure = (error) => ({
    type: CREATE_RECURRING_PAYMENT_FAILURE,
    payload: { error },
});

export function createRecurringPayment(data) {
    return (dispatch) => {
        dispatch(createRecurringPaymentBegin());

        axios({
            method: "post",
            url: `${getUrl()}/api/v1/recurring_payments/`,
            data: { ...data },
        })
            .then((res) => {
                dispatch(createRecurringPaymentSuccess(res.data));
                dispatch(toggleCreateRecurringPaymentModal());
                toast("Recurring payment created succesfully!", {
                    type: "success",
                });
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to create recurring payment.", { type: "error" });
                dispatch(createRecurringPaymentFailure(error));
            });
    };
}
