import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../../getBackend";

export const INVOICE_CHANGE_STATUS_BEGIN = "INVOICE_CHANGE_STATUS_BEGIN";
export const INVOICE_CHANGE_STATUS_SUCCESS = "INVOICE_CHANGE_STATUS_SUCCESS";
export const INVOICE_CHANGE_STATUS_FAILURE = "INVOICE_CHANGE_STATUS_FAILURE";

export const changeStatusBegin = () => ({
    type: INVOICE_CHANGE_STATUS_BEGIN,
});

export const changeStatusSuccess = (ids, status) => ({
    type: INVOICE_CHANGE_STATUS_SUCCESS,
    payload: { ids, status },
});

export const changeStatusFailure = (error) => ({
    type: INVOICE_CHANGE_STATUS_FAILURE,
    payload: { error },
});

export function changeStatus(ids, status) {
    return (dispatch) => {
        axios({
            method: "post",
            url: `${getUrl()}/api/v1/invoices/change_status/`,
            data: {
                ids,
                status,
            },
        })
            .then((res) => {
                dispatch(changeStatusSuccess(ids, status));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to update the status of the selected hour(s).", {
                    type: "error",
                });
                dispatch(changeStatusFailure(error));
            });
    };
}
