import React from "react";
import PropTypes from "prop-types";

import { TimeCycleInput, Input, DateInput } from "../shared";

const ProjectForm = ({
    dateChanged,
    nextInvoice,
    projectAssignment,
    termsAndConditionsSigned,
    startDate,
    endDate,
}) => (
    <div style={{ marginBottom: "0.75em" }}>
        <Input label="Project name" id=".name" />
        <Input label="Project number" id=".project_number" />
        <Input
            label="Required comment part"
            id=".required_comment_part"
            required={false}
        />
        <Input
            label="Tax"
            id=".tax_percentage"
            help="Tax rate for the project in percent (for example '21')."
            type="number"
        />
        <TimeCycleInput
            interval=".billing_cycle_interval"
            type=".billing_cycle_type"
            label="Billing cycle"
        />
        <DateInput
            id="next_invoice"
            label="Next invoice date"
            onChange={dateChanged}
            date={nextInvoice}
        />
        <DateInput
            id="project_assignment"
            label="Confirmation of order"
            onChange={dateChanged}
            date={projectAssignment}
        />
        <DateInput
            id="terms_and_conditions_signed"
            label="Terms and Conditions signing date"
            onChange={dateChanged}
            date={termsAndConditionsSigned}
        />
        <DateInput
            id="start_date"
            label="Start date"
            onChange={dateChanged}
            date={startDate}
        />
        <DateInput
            id="end_date"
            label="End date"
            onChange={dateChanged}
            date={endDate}
        />
        <Input
            id=".jira_base_url"
            label="Jira base URL"
            required={false}
            help="Jira base url. ex https://inogo.atlassian.net"
        />
        <Input
            id=".enabled"
            type="checkbox"
            label="Enable this project"
            help="Generate invoices for this project"
            required={false}
        />
        <Input
            id=".enable_tempo"
            type="checkbox"
            label="Tempo integration"
            help="Export hours to tempo"
            required={false}
        />
        <Input id=".tempo_api_key" label="Tempo API key" required={false} />
        <Input
            id=".tempo_custom_attributes"
            label="Tempo Custom attributes JSON"
            required={false}
        />
    </div>
);

ProjectForm.propTypes = {
    dateChanged: PropTypes.func.isRequired,
    nextInvoice: PropTypes.object,
    projectAssignment: PropTypes.object,
    termsAndConditionsSigned: PropTypes.object,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
};

export default ProjectForm;
