import { getOverviewHours } from "./getHours";

export const OVERVIEW_SET_MONTH = "OVERVIEW_SET_MONTH";

export const setMonthAction = (month) => ({
    type: OVERVIEW_SET_MONTH,
    payload: month,
});

export function setMonth(month, year) {
    return (dispatch) => {
        dispatch(setMonthAction(month));
        dispatch(getOverviewHours(month, year));
    };
}
