export const REGISTER_SELECT_CLIENT = "REGISTER_SELECT_CLIENT";

const setClient = (id) => ({
    type: REGISTER_SELECT_CLIENT,
    payload: id,
});

export function registerSelectClient(clientId) {
    return (dispatch) => {
        dispatch(setClient(clientId));
    };
}
