import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../getBackend";

export const UPDATE_GROUP_BEGIN = "UPDATE_GROUP_BEGIN";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_FAILURE = "UPDATE_GROUP_FAILURE";

export const updateGroupBegin = () => ({
    type: UPDATE_GROUP_BEGIN,
});

export const updateGroupSuccess = (data) => ({
    type: UPDATE_GROUP_SUCCESS,
    payload: { ...data },
});

export const updateGroupFailure = (error) => ({
    type: UPDATE_GROUP_FAILURE,
    payload: { error },
});

export function updateGroup(id, data) {
    return (dispatch) => {
        dispatch(updateGroupBegin());

        axios({
            method: "patch",
            url: `${getUrl()}/api/v1/groups/${id}/`,
            data: { ...data },
        })
            .then((res) => {
                toast("Company settings updated!", { type: "success" });
                dispatch(updateGroupSuccess(res.data));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to update company settings.", { type: "error" });
                dispatch(updateGroupFailure(error));
            });
    };
}
