import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LocalForm } from "react-redux-form";

import { Modal, ModalActionButton } from "../shared";
import { toggleEditClientModal } from "./actions/toggleEditClientModal";
import { updateClient } from "./actions/updateClient";
import ClientForm from "./ClientForm";

class EditClientModal extends Component {
    handleSubmit(values) {
        const data = JSON.parse(JSON.stringify(values));
        delete data.id;
        delete data.projects;

        this.props.updateClient(values.id, data);
        this.props.toggleEditClientModal();
    }

    render() {
        const initialState = this.props.clients.find(
            (client) => client.id === this.props.selected
        );

        return (
            <Modal
                closeModal={this.props.toggleEditClientModal}
                modalState={this.props.modalState}
                title="Edit client"
                footerButtons={
                    <ModalActionButton
                        form="clientEditForm"
                        text="Update Client"
                    />
                }
            >
                <LocalForm
                    onSubmit={(values) => this.handleSubmit(values)}
                    initialState={initialState}
                    id="clientEditForm"
                >
                    <ClientForm />
                </LocalForm>
            </Modal>
        );
    }
}

EditClientModal.propTypes = {
    toggleEditClientModal: PropTypes.func.isRequired,
    updateClient: PropTypes.func.isRequired,
    modalState: PropTypes.bool,
    selected: PropTypes.string,
    clients: PropTypes.array,
};

const mapStateToProps = (state) => ({
    modalState: state.clients.clientEditModal.toggled,
    selected: state.clients.selected,
    clients: state.clients.clients.results,
});

const mapDispatchToProps = {
    toggleEditClientModal,
    updateClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditClientModal);
