import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setStatus } from "../../actions";

class StatusButton extends Component {
    constructor(props) {
        super(props);

        this.statusses = ["pending", "awaiting_payment", "paid", "exported"];
    }

    getDisplay() {
        if (!this.props.selectedStatus) {
            return "Not selected";
        }
        return this.capitalizeFirstLetter(
            this.props.selectedStatus.replace("_", " ")
        );
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    render() {
        const monthList = this.statusses.map((status) => (
            <span
                className="dropdown-item"
                key={status}
                onClick={() => this.props.setStatus(status)}
            >
                {this.capitalizeFirstLetter(status.replace("_", " "))}
            </span>
        ));

        return (
            <div
                className="dropdown is-hoverable"
                style={{ paddingRight: "8px" }}
            >
                <div className="dropdown-trigger">
                    <button
                        className="button"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu"
                        type="button"
                    >
                        <span>{this.getDisplay()}</span>
                        <span className="icon is-small">
                            <i
                                className="fas fa-angle-down"
                                aria-hidden="true"
                            />
                        </span>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                        <span
                            className="dropdown-item"
                            onClick={() => this.props.setStatus(null)}
                        >
                            Not selected
                        </span>
                        {monthList}
                    </div>
                </div>
            </div>
        );
    }
}

StatusButton.propTypes = {
    setStatus: PropTypes.func.isRequired,
    selectedStatus: PropTypes.string,
};

const mapStateToProps = (state) => ({
    selectedStatus: state.overview.selectedStatus,
});

const mapDispatchToProps = { setStatus };

export default connect(mapStateToProps, mapDispatchToProps)(StatusButton);
