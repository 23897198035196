import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../getBackend";

export const USER_UPDATE_PROFILE_BEGIN = "USER_UPDATE_PROFILE_BEGIN";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAILURE = "USER_UPDATE_PROFILE_FAILURE";

export const updateUserProfileBegin = () => ({
    type: USER_UPDATE_PROFILE_BEGIN,
});

export const updateUserProfileSuccess = (data) => ({
    type: USER_UPDATE_PROFILE_SUCCESS,
    payload: { ...data },
});

export const updateUserProfileFailure = (error) => ({
    type: USER_UPDATE_PROFILE_FAILURE,
    payload: { error },
});

export function updateProfile(id, data) {
    return (dispatch) => {
        dispatch(updateUserProfileBegin());

        axios({
            method: "patch",
            url: `${getUrl()}/api/v1/users/${id}/`,
            data: { ...data },
        })
            .then((res) => {
                toast("Profile updated!", { type: "success" });
                dispatch(updateUserProfileSuccess(res.data));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to update profile.", { type: "error" });
                dispatch(updateUserProfileFailure(error));
            });
    };
}
