import { getHours } from "../../../actions/hours/getHours";
import { resetHoursBegin } from "../../../actions/hours/resetHours";
import { getWeekHours } from "../../../actions/hours/getWeekHours";

export const REGISTER_SET_DATE = "REGISTER_SET_DATE";

const setNewDate = (date) => ({
    type: REGISTER_SET_DATE,
    payload: date,
});

export function setDate(date, oldDate) {
    return (dispatch) => {
        if (!oldDate || date.format("W") !== oldDate.format("W")) {
            dispatch(getWeekHours(date));
        }

        dispatch(setNewDate(date));
        dispatch(resetHoursBegin());
        dispatch(getHours(date.format("YYYY-MM-DD")));
    };
}
