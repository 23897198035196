import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LocalForm, Control } from "react-redux-form";

import { Modal, ModalActionButton } from "../shared";
import { toggleCreateRateModal } from "./actions/toggleCreateRateModal";
import { createRate } from "./actions/createRate";

class CreateRateModal extends Component {
    handleSubmit(values) {
        const data = {
            ...values,
            project: this.props.projectId,
        };
        this.props.createRate(data);
    }

    render() {
        return (
            <Modal
                closeModal={this.props.toggleCreateRateModal}
                modalState={this.props.modalState}
                title="Create rate"
                footerButtons={
                    <ModalActionButton
                        form="rateCreateForm"
                        text="Create rate"
                    />
                }
            >
                <LocalForm
                    initialState={{ enabled: true }}
                    onSubmit={(values) => this.handleSubmit(values)}
                    id="rateCreateForm"
                >
                    <div className="field">
                        <label className="label">Rate name</label>

                        <div className="control">
                            <Control.text
                                model=".name"
                                className="input"
                                autoComplete="off"
                                required
                            />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Hourly rate</label>
                        <p className="control has-icons-left has-icons-right">
                            <Control.input
                                type="number"
                                step="0.01"
                                model=".price"
                                autoComplete="off"
                                className="input"
                                required
                            />
                            <span className="icon is-small is-left">
                                <i className="fas fa-euro-sign" />
                            </span>
                        </p>
                    </div>

                    <div className="field">
                        <label className="label">Enabled</label>

                        <div className="control">
                            <Control.checkbox
                                model=".enabled"
                                type="checkbox"
                            />
                        </div>
                    </div>
                </LocalForm>
            </Modal>
        );
    }
}

CreateRateModal.propTypes = {
    toggleCreateRateModal: PropTypes.func.isRequired,
    createRate: PropTypes.func.isRequired,
    modalState: PropTypes.bool,
    projectId: PropTypes.string,
};

const mapStateToProps = (state) => ({
    modalState: state.projects.createRateModal,
    projectId: state.projects.projectDetailPage,
});

const mapDispatchToProps = {
    toggleCreateRateModal,
    createRate,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRateModal);
