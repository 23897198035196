import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../getBackend";

export const REMOVE_INVITE_BEGIN = "REMOVE_INVITE_BEGIN";
export const REMOVE_INVITE_SUCCESS = "REMOVE_INVITE_SUCCESS";
export const REMOVE_INVITE_FAILURE = "REMOVE_INVITE_FAILURE";

export const removeInviteBegin = () => ({
    type: REMOVE_INVITE_BEGIN,
});

export const removeInviteSuccess = (data) => ({
    type: REMOVE_INVITE_SUCCESS,
    payload: { ...data },
});

export const removeInviteFailure = (error) => ({
    type: REMOVE_INVITE_FAILURE,
    payload: { error },
});

export function removeInvite(id) {
    return (dispatch) => {
        dispatch(removeInviteBegin());

        axios({
            method: "delete",
            url: `${getUrl()}/api/v1/invites/${id}/`,
        })
            .then((res) => {
                dispatch(removeInviteSuccess({ id }));
                toast("Invite removed succesfully!", {
                    type: "success",
                });
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to remove rate.", { type: "error" });
                dispatch(removeInviteFailure(error));
            });
    };
}
