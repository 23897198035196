import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../getBackend";

export const REMOVE_RECURRING_PAYMENT_BEGIN = "REMOVE_RECURRING_PAYMENT_BEGIN";
export const REMOVE_RECURRING_PAYMENT_SUCCESS =
    "REMOVE_RECURRING_PAYMENT_SUCCESS";
export const REMOVE_RECURRING_PAYMENT_FAILURE =
    "REMOVE_RECURRING_PAYMENT_FAILURE";

export const removeRecurringPaymentBegin = () => ({
    type: REMOVE_RECURRING_PAYMENT_BEGIN,
});

export const removeRecurringPaymentSuccess = (id) => ({
    type: REMOVE_RECURRING_PAYMENT_SUCCESS,
    payload: id,
});

export const removeRecurringPaymentFailure = (error) => ({
    type: REMOVE_RECURRING_PAYMENT_FAILURE,
    payload: { error },
});

export function removeRecurringPayment(id) {
    return (dispatch) => {
        dispatch(removeRecurringPaymentBegin());

        axios({
            method: "delete",
            url: `${getUrl()}/api/v1/recurring_payments/${id}/`,
        })
            .then((res) => {
                dispatch(removeRecurringPaymentSuccess(id));
                toast("Recurring payment removed succesfully!", {
                    type: "success",
                });
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to remove recurring payment.", { type: "error" });
                dispatch(removeRecurringPaymentFailure(error));
            });
    };
}
