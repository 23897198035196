import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../../getBackend";
import { toggleCreateInvoiceModal } from "./createInvoiceModal";
import { getInvoices } from "./getInvoices";

export const CREATE_INVOICES_BEGIN = "CREATE_INVOICES_BEGIN";
export const CREATE_INVOICES_SUCCESS = "CREATE_INVOICES_SUCCESS";
export const CREATE_INVOICES_FAILURE = "CREATE_INVOICES_FAILURE";

export const createInvoicesBegin = () => ({
    type: CREATE_INVOICES_BEGIN,
});

export const createInvoicesSuccess = (data) => ({
    type: CREATE_INVOICES_SUCCESS,
    payload: { ...data },
});

export const createInvoicesFailure = (error) => ({
    type: CREATE_INVOICES_FAILURE,
    payload: { error },
});

export function createInvoice(data) {
    return (dispatch) => {
        dispatch(createInvoicesBegin());

        axios
            .post(`${getUrl()}/api/v1/invoices/`, data)
            .then((res) => {
                dispatch(createInvoicesSuccess(res.data));
                dispatch(toggleCreateInvoiceModal());
                dispatch(getInvoices());
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to create invoice.", { type: "error" });
                dispatch(createInvoicesFailure(error));
            });
    };
}
