export const REGISTER_SELECT_PROJECT = "REGISTER_SELECT_PROJECT";

const setProject = (id) => ({
    type: REGISTER_SELECT_PROJECT,
    payload: id,
});

export function registerSelectProject(clientId) {
    return (dispatch) => {
        dispatch(setProject(clientId));
    };
}
