import React, { Component } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

class DateInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: this.props.date,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(date) {
        this.setState({
            date,
        });
        this.props.onChange(this.props.id, date);
    }

    render() {
        const { label } = this.props;

        return (
            <div className="field">
                <label className="label">{label}</label>
                <div className="control">
                    <DatePicker
                        selected={this.state.date}
                        onChange={this.handleChange}
                        className="input"
                    />
                </div>
            </div>
        );
    }
}

DateInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    date: PropTypes.object,
};

DateInput.defaultProps = {
    date: moment(),
};

export default DateInput;
