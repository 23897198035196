export const ADD_PROJECT_TO_CLIENT = "ADD_PROJECT_TO_CLIENT";

export const addProject = (data) => ({
    type: ADD_PROJECT_TO_CLIENT,
    payload: { ...data },
});

export function addProjectToClient(clientId, projectId) {
    return (dispatch) => {
        dispatch(addProject({ clientId, projectId }));
    };
}
