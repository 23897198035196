import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../getBackend";

export const GET_PROJECTS_BEGIN = "GET_PROJECTS_BEGIN";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILURE = "GET_PROJECTS_FAILURE";

export const getProjectsBegin = () => ({
    type: GET_PROJECTS_BEGIN,
});

export const getProjectsSuccess = (data) => ({
    type: GET_PROJECTS_SUCCESS,
    payload: { ...data },
});

export const getProjectsFailure = (error) => ({
    type: GET_PROJECTS_FAILURE,
    payload: { error },
});

export function getProjects() {
    return (dispatch) => {
        dispatch(getProjectsBegin());

        axios(`${getUrl()}/api/v1/projects/`)
            .then((res) => {
                dispatch(getProjectsSuccess(res.data));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to get projects.", { type: "error" });
                dispatch(getProjectsFailure(error));
            });
    };
}
