import axios from "axios";
import { toast } from "react-toastify";
import { actions } from "react-redux-form";
import { getUrl } from "../../../getBackend";
import { toggleCreateClientModalBegin } from "./toggleCreateClientModal";
import { selectClient } from "./selectClient";

export const CREATE_CLIENT_BEGIN = "CREATE_CLIENT_BEGIN";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_FAILURE = "CREATE_CLIENT_FAILURE";

export const createClientBegin = () => ({
    type: CREATE_CLIENT_BEGIN,
});

export const createClientSuccess = (data) => ({
    type: CREATE_CLIENT_SUCCESS,
    payload: { ...data },
});

export const createClientFailure = (error) => ({
    type: CREATE_CLIENT_FAILURE,
    payload: { error },
});

export function createClient(client) {
    return (dispatch) => {
        dispatch(createClientBegin());

        const payload = {
            ...client,
            invoice_reminder_1: client.invoice_reminder_1 || null,
            invoice_reminder_2: client.invoice_reminder_2 || null,
            invoice_emails: client.invoice_emails
                ? client.invoice_emails.split(",")
                : [],
        };

        axios({
            method: "post",
            url: `${getUrl()}/api/v1/clients/`,
            data: payload,
        })
            .then((res) => {
                dispatch(createClientSuccess(res.data));
                dispatch(toggleCreateClientModalBegin());
                dispatch(selectClient(res.data.id));
                dispatch(actions.reset("createClientForm"));
                toast(`Client ${client.name} created succesfully!`, {
                    type: "success",
                });
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to create client.", { type: "error" });
                dispatch(createClientFailure(error));
            });
    };
}
