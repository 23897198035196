import moment from "moment";

export function calculateTotal(startTime, endTime, breakTime) {
    const start = moment(startTime, "HH:mm").clone();
    const end = moment(endTime, "HH:mm").clone();
    const pause = moment(breakTime, "HH:mm").clone();

    let newTotalTime;
    if (pause.isValid()) {
        newTotalTime = moment
            .utc(moment(end.clone().diff(start.clone())))
            .subtract(Number(breakTime.split(":")[0]), "hours")
            .subtract(Number(breakTime.split(":")[1]), "minutes")
            .format("HH:mm");
    } else {
        newTotalTime = moment
            .utc(moment(end.clone().diff(start.clone())))
            .format("HH:mm");
    }

    if (newTotalTime === "Invalid date") {
        return "";
    }

    return newTotalTime;
}
