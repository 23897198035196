import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../getBackend";

export const DASHBOARD_GET_DATA_BEGIN = "DASHBOARD_GET_DATA_BEGIN";
export const DASHBOARD_GET_DATA_SUCCESS = "DASHBOARD_GET_DATA_SUCCESS";
export const DASHBOARD_GET_DATA_FAILURE = "DASHBOARD_GET_DATA_FAILURE";

export const getDashboardDataBegin = () => ({
    type: DASHBOARD_GET_DATA_BEGIN,
});

export const getDashboardDataSuccess = (data) => ({
    type: DASHBOARD_GET_DATA_SUCCESS,
    payload: { ...data },
});

export const getDashboardDataFailure = (error) => ({
    type: DASHBOARD_GET_DATA_FAILURE,
    payload: { error },
});

export function getDashboardData(year) {
    return (dispatch) => {
        dispatch(getDashboardDataBegin());

        axios(`${getUrl()}/api/v1/groups/dashboard/?year=${year}`)
            .then((res) => {
                dispatch(getDashboardDataSuccess(res.data));
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to get dashboard data.", { type: "error" });
                dispatch(getDashboardDataFailure(error));
            });
    };
}
