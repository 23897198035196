import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../../getBackend";
import { getInvoices } from "./getInvoices";

export function exportInvoice(invoiceId) {
    return (dispatch) => {
        axios
            .post(`${getUrl()}/api/v1/invoices/${invoiceId}/export/`)
            .then((res) => {
                dispatch(getInvoices());
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to export invoice.", { type: "error" });
            });
    };
}
