export const CLIENT_EDIT_MODAL_TOGGLE = "CLIENT_EDIT_MODAL_TOGGLE";

export const toggleEditClientModalBegin = () => ({
    type: CLIENT_EDIT_MODAL_TOGGLE,
});

export function toggleEditClientModal() {
    return (dispatch) => {
        dispatch(toggleEditClientModalBegin());
    };
}
