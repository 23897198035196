import { toast } from "react-toastify";
import axios from "axios";

import { setAuthenticationToken } from "../../setAuthenticationToken";

import { getUserData } from "./getDetails";
import { getClients } from "../../components/ClientPage/actions/getClients";
import { getProjects } from "../../components/ProjectPage/actions/getProjects";
import { getUrl } from "../../getBackend";

export const USER_LOGIN_BEGIN = "USER_LOGIN_BEGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const loginUserBegin = () => ({
    type: USER_LOGIN_BEGIN,
});

export const loginUserSuccess = (data) => ({
    type: USER_LOGIN_SUCCESS,
    payload: { ...data },
});

export const loginUserFailure = (error) => ({
    type: USER_LOGIN_FAILURE,
    payload: { error },
});

export function login(data) {
    return (dispatch) => {
        dispatch(loginUserBegin());

        axios({
            method: "post",
            url: `${getUrl()}/api/api-token-auth/`,
            data: {
                username: data.username,
                password: data.password,
            },
        })
            .then((res) => {
                if (data.remember) {
                    localStorage.setItem("token", res.data.token);
                } else {
                    sessionStorage.setItem("token", res.data.token);
                }
                setAuthenticationToken(res.data.token);

                // toast('You are now logged in!', { type: 'success' });
                setTimeout(() => {
                    dispatch(getUserData());
                    dispatch(getClients());
                    dispatch(getProjects());
                }, 50);
                dispatch(loginUserSuccess(res.data));

                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to login.", { type: "error" });
                dispatch(loginUserFailure(error));
            });
    };
}
