import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Input } from "../../shared";
import { setCommentTime } from "../actions";
import { getUrl } from "../../../getBackend";
import { HourComment } from "../HourComment";
import { debounce } from "lodash";
import { TicketSummary } from "../models/TicketSummary";

interface IProps {}

const mapStateToProps = (state: any) => ({
    comment: state.register.time.comment,
    projectId: state.register.project,
});

const mapDispatchToProps = {
    setCommentTime,
};

type Props = IProps &
    ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

const CommentInput: React.FC<Props> = (props) => {
    const [tickets, setTickets] = React.useState<TicketSummary[]>([]);
    const [totalHours, setTotalHours] = useState<number>(0);

    React.useEffect(() => {
        if (!props.projectId) {
            return;
        }
        axios
            .get(
                `${getUrl()}/api/v1/hours/summary/?project_id=${
                    props.projectId
                }&max_results=3`
            )
            .then((res) => {
                setTickets(res.data);
            });
    }, [props.projectId]);

    const fetch = (newComment: string) => {
        if (newComment === "") {
            setTotalHours(0);
            return;
        }

        axios
            .get(`${getUrl()}/api/v1/hours/total/?comment=${newComment}`)
            .then((res) =>
                setTotalHours(
                    Math.round((res.data.total_seconds / 60 / 60) * 100) / 100
                )
            );
    };

    const onCommentChange = useCallback(debounce(fetch, 1000), []);

    return (
        <React.Fragment>
            <Input
                id="comment"
                placeholder="Comment"
                onChange={(e: any) => {
                    const comment = e.target.value;
                    props.setCommentTime(comment);
                    onCommentChange(comment);
                }}
                value={props.comment}
                label={undefined}
                type={undefined}
                required={undefined}
                help={undefined}
            />

            <div style={{ marginBottom: "0.75rem" }}>
                <HourComment
                    hour={{
                        comment: props.comment,
                        comment_total: totalHours,
                    }}
                >
                    Total: {totalHours} hour(s)
                </HourComment>

                <hr />

                {tickets.map((ticket) => (
                    <div
                        key={ticket.key}
                        style={{ display: "flex", cursor: "pointer" }}
                        onClick={() => {
                            props.setCommentTime(ticket.key);
                            onCommentChange(ticket.key);
                        }}
                    >
                        <div style={{ flex: 1 }}>{ticket.key}</div>
                        <div
                            style={{
                                flex: 3,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {ticket.summary}
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommentInput as any);
