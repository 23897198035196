import { createReducer } from "./reducerUtilities";
import {
    GET_GROUP_BEGIN,
    GET_GROUP_SUCCESS,
    GET_GROUP_FAILURE,
} from "../actions/group/getGroup";

import {
    CREATE_INVITE_BEGIN,
    CREATE_INVITE_SUCCESS,
    CREATE_INVITE_FAILURE,
} from "../actions/group/sendInvite";

import {
    UPDATE_GROUP_BEGIN,
    UPDATE_GROUP_SUCCESS,
    UPDATE_GROUP_FAILURE,
} from "../actions/group/updateGroup";

import {
    REMOVE_INVITE_BEGIN,
    REMOVE_INVITE_SUCCESS,
    REMOVE_INVITE_FAILURE,
} from "../actions/group/removeInvite";

const initialState = {
    getGroupLoading: false,
    getGroupError: null,
    updateGroup: false,
    updateGroupError: null,
    sendInviteLoading: false,
    sendInviteError: null,
    removeInviteLoading: false,
    removeInviteError: null,
    id: null,
    members: [],
    invites: [],
};

function removeInviteBegin(state) {
    return {
        ...state,
        removeInviteLoading: true,
    };
}

function removeInviteSuccess(state, action) {
    return {
        ...state,
        removeInviteLoading: false,
        invites: state.invites.filter((item) => item.id !== action.payload.id),
    };
}

function removeInviteFailure(state, action) {
    return {
        ...state,
        removeInviteLoading: false,
        updateGroupError: action.payload,
    };
}

// ///////////////////////////////////

function updateGroupBegin(state) {
    return {
        ...state,
        updateGroupLoading: true,
    };
}

function updateGroupSuccess(state, action) {
    return {
        ...state,
        updateGroupLoading: false,
        ...action.payload,
    };
}

function updateGroupFailure(state, action) {
    return {
        ...state,
        updateGroupLoading: false,
        updateGroupError: action.payload,
    };
}

function getGroupBegin(state) {
    return {
        ...state,
        getGroupLoading: true,
    };
}

function getGroupSuccess(state, action) {
    return {
        ...state,
        ...action.payload,
        getGroupLoading: false,
    };
}

function getGroupFailure(state, action) {
    return {
        ...state,
        getGroupLoading: false,
        getGroupError: action.payload,
    };
}

function sendInviteBegin(state) {
    return {
        ...state,
        sendInviteLoading: true,
    };
}

function sendInviteSuccess(state, action) {
    return {
        ...state,
        ...action.payload,
        sendInviteLoading: false,
        invites: [...state.invites, action.payload],
    };
}

function sendInviteFailure(state, action) {
    return {
        ...state,
        sendInviteLoading: false,
        sendInviteError: action.payload,
    };
}

const handlers = {};
handlers[GET_GROUP_BEGIN] = getGroupBegin;
handlers[GET_GROUP_SUCCESS] = getGroupSuccess;
handlers[GET_GROUP_FAILURE] = getGroupFailure;
handlers[CREATE_INVITE_BEGIN] = sendInviteBegin;
handlers[CREATE_INVITE_SUCCESS] = sendInviteSuccess;
handlers[CREATE_INVITE_FAILURE] = sendInviteFailure;
handlers[UPDATE_GROUP_BEGIN] = updateGroupBegin;
handlers[UPDATE_GROUP_SUCCESS] = updateGroupSuccess;
handlers[UPDATE_GROUP_FAILURE] = updateGroupFailure;
handlers[REMOVE_INVITE_BEGIN] = removeInviteBegin;
handlers[REMOVE_INVITE_SUCCESS] = removeInviteSuccess;
handlers[REMOVE_INVITE_FAILURE] = removeInviteFailure;

const groupReducer = createReducer(initialState, handlers);

export default groupReducer;
