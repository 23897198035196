import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Table } from "../shared";

import {
    toggleCreateRecurringPaymentModal,
    getRecurringPayments,
    removeRecurringPayment,
} from "./actions";

class PaymentSection extends Component {
    componentDidMount() {
        this.props.getRecurringPayments(this.props.projectId);
    }

    render() {
        if (this.props.paymentsLoading) {
            return <p>Loading...</p>;
        }

        const paymentList = this.props.recurringPayments.map((item) => (
            <tr key={item.id}>
                <td>{item.name}</td>
                <td>€ {item.price.replace(".", ",")}</td>
                <td>
                    {item.billing_cycle_interval} {item.billing_cycle_type}
                </td>
                <td>
                    {item.billing_cycle_amount === 0
                        ? "Indefinitly"
                        : item.billing_cycle_amount}
                </td>
                <td>
                    <div
                        className="delete"
                        onClick={() =>
                            confirmAlert({
                                title: "Are you sure you want to delete this recurring payment?",
                                buttons: [
                                    {
                                        label: "No",
                                    },
                                    {
                                        label: "Yes",
                                        onClick: () =>
                                            this.props.removeRecurringPayment(
                                                item.id
                                            ),
                                    },
                                ],
                            })
                        }
                        role="button"
                    />
                </td>
            </tr>
        ));

        return (
            <div>
                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Every</th>
                            <th>Number of cycles</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>{paymentList}</tbody>
                </Table>
                <button
                    className="button is-link"
                    type="button"
                    onClick={() =>
                        this.props.toggleCreateRecurringPaymentModal()
                    }
                >
                    Add new recurring payment
                </button>
            </div>
        );
    }
}

PaymentSection.propTypes = {
    projectId: PropTypes.string.isRequired,
    toggleCreateRecurringPaymentModal: PropTypes.func.isRequired,
    removeRecurringPayment: PropTypes.func.isRequired,
    getRecurringPayments: PropTypes.func.isRequired,
    paymentsLoading: PropTypes.bool,
    recurringPayments: PropTypes.array,
};

const mapStateToProps = (state) => ({
    paymentsLoading: state.payments.getRecurringPayments,
    recurringPayments: state.payments.recurringPayments.results,
});

const mapDispatchToProps = {
    toggleCreateRecurringPaymentModal,
    getRecurringPayments,
    removeRecurringPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSection);
