import { getWeekHours } from "../../../actions/hours/getWeekHours";

export const TOGGLE_ONLY_OWN_HOURS = "TOGGLE_ONLY_OWN_HOURS";

const toggleOwnHours = () => ({
    type: TOGGLE_ONLY_OWN_HOURS,
});

export const toggleOnlyShowOwnHours = () => (dispatch, getState) => {
    dispatch(toggleOwnHours());
    dispatch(getWeekHours(getState().register.date));
};
