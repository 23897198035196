import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { inviteToUser } from "./actions";

class InvitePage extends Component {
    constructor(props) {
        super(props);
        this.state = { password: "", passwordConfirm: "" };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event, type) {
        const newState = {};
        newState[type] = event.target.value;
        this.setState(newState);
    }

    handleSubmit(event) {
        if (this.state.password !== this.state.passwordConfirm) {
            confirmAlert({
                title: "Error",
                message: "Passwords must match.",
                buttons: [
                    {
                        label: "Ok",
                    },
                ],
            });
        } else {
            this.props.inviteToUser(
                this.props.match.params.group,
                this.state.password
            );
            this.props.history.push("/");
        }

        event.preventDefault();
    }

    render() {
        return (
            <section className="hero is-fullheight">
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <div className="column is-4 is-offset-4">
                            <div className="box">
                                <h2
                                    className="title is-2"
                                    style={{ paddingBottom: "16px" }}
                                >
                                    Welcome to InoGo Time
                                </h2>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="field">
                                        <div className="control">
                                            <input
                                                value={this.state.password}
                                                onChange={(vals) =>
                                                    this.handleChange(
                                                        vals,
                                                        "password"
                                                    )
                                                }
                                                id="password"
                                                className="input has-text-centered"
                                                type="password"
                                                placeholder="Enter a password"
                                                autoComplete="off"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="field">
                                        <div className="control">
                                            <input
                                                value={
                                                    this.state.passwordConfirm
                                                }
                                                onChange={(vals) =>
                                                    this.handleChange(
                                                        vals,
                                                        "passwordConfirm"
                                                    )
                                                }
                                                id="confirmPassword"
                                                className="input has-text-centered"
                                                type="password"
                                                placeholder="Confirm your password"
                                                autoComplete="off"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <input
                                        type="submit"
                                        className="button is-block is-info is-fullwidth"
                                        style={{ marginTop: "32px" }}
                                        value="Register"
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

InvitePage.propTypes = {
    inviteToUser: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
    inviteToUser,
};

export default connect(null, mapDispatchToProps)(InvitePage);
