import React from "react";
import PropTypes from "prop-types";
import { Control } from "react-redux-form";

const TimeCycleInput = ({ interval, type, label }) => (
    <div style={{ marginBottom: "0.75em" }}>
        <label className="label">{label}</label>
        <div className="field is-grouped">
            <div className="control">
                <Control.text
                    type="number"
                    style={{ width: "126px" }}
                    min="1"
                    model={interval}
                    className="input"
                    autoComplete="off"
                    placeholder="0"
                    required
                />
            </div>
            <div className="control">
                <div className="select">
                    <Control.select model={type} id={type} required>
                        <option value="weeks">Weeks</option>
                        <option value="months">Months</option>
                        <option value="years">Years</option>
                    </Control.select>
                </div>
            </div>
        </div>
    </div>
);

TimeCycleInput.propTypes = {
    interval: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default TimeCycleInput;
