import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setMonth } from "../../actions";

class MonthButton extends Component {
    constructor(props) {
        super(props);

        this.months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
    }

    getMonth(monthAsInt) {
        return this.months[parseInt(monthAsInt, 10)];
    }

    render() {
        const monthList = this.months.map((month, index) => (
            <span
                className="dropdown-item"
                key={month}
                onClick={() => this.props.setMonth(index, this.props.year)}
            >
                {month}
            </span>
        ));

        return (
            <div
                className="dropdown is-hoverable"
                style={{ paddingRight: "8px" }}
            >
                <div className="dropdown-trigger">
                    <button
                        className="button"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu"
                        type="button"
                    >
                        <span>{this.getMonth(this.props.month)}</span>
                        <span className="icon is-small">
                            <i
                                className="fas fa-angle-down"
                                aria-hidden="true"
                            />
                        </span>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content">{monthList}</div>
                </div>
            </div>
        );
    }
}

MonthButton.propTypes = {
    month: PropTypes.number,
    year: PropTypes.number,
    setMonth: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    month: state.overview.month,
    year: state.overview.year,
});

const mapDispatchToProps = { setMonth };

export default connect(mapStateToProps, mapDispatchToProps)(MonthButton);
