import { createReducer } from "./reducerUtilities";

import { CREATE_PROJECT_MODAL_TOGGLE } from "../components/ProjectPage/actions/toggleCreateProjectModal";
import {
    GET_PROJECTS_BEGIN,
    GET_PROJECTS_SUCCESS,
} from "../components/ProjectPage/actions/getProjects";
import {
    CREATE_PROJECT_BEGIN,
    CREATE_PROJECT_SUCCESS,
    CREATE_PROJECT_FAILURE,
} from "../components/ProjectPage/actions/createProject";
import {
    REMOVE_PROJECT_BEGIN,
    REMOVE_PROJECT_SUCCESS,
    REMOVE_PROJECT_FAILURE,
} from "../components/ProjectPage/actions/removeProject";
import {
    UPDATE_PROJECT_BEGIN,
    UPDATE_PROJECT_SUCCESS,
    UPDATE_PROJECT_FAILURE,
} from "../components/ProjectPage/actions/updateProject";
import { PROJECT_SET_DETAIL } from "../components/ProjectPage/actions/setDetailPage";
import { TOGGLE_CREATE_RATE_MODAL } from "../components/ProjectPage/actions/toggleCreateRateModal";
import {
    CREATE_RATE_BEGIN,
    CREATE_RATE_FAILURE,
    CREATE_RATE_SUCCESS,
} from "../components/ProjectPage/actions/createRate";

import {
    REMOVE_RATE_BEGIN,
    REMOVE_RATE_FAILURE,
    REMOVE_RATE_SUCCESS,
} from "../components/ProjectPage/actions/removeRate";

const initialState = {
    loading: false,
    isRemoving: false,
    isRemovingError: null,
    isRemovingRate: false,
    isRemovingRateError: null,
    isUpdating: false,
    isUpdatingError: null,
    creatingRate: false,
    creatingRateError: null,
    projects: {
        results: [],
        count: 0,
        next: null,
        previous: null,
    },
    createProject: {
        toggled: false,
        loading: false,
        error: null,
    },
    projectDetailPage: null,
    createRateModal: false,
};

function removeRateBegin(state) {
    return {
        ...state,
        isRemovingRate: true,
    };
}

function removeRateSuccess(state, action) {
    return {
        isRemovingRate: true,
        projects: {
            ...state.projects,
            results: state.projects.results.map((item) =>
                item.id === action.payload.projectId
                    ? {
                          ...item,
                          rates: item.rates.filter(
                              (rate) => rate.id !== action.payload.rateId
                          ),
                      }
                    : item
            ),
        },
    };
}

function removeRateFailure(state, action) {
    return {
        ...state,
        isRemovingRate: false,
        isRemovingRateError: action.payload,
    };
}

function createRateBegin(state) {
    return {
        ...state,
        creatingRate: true,
    };
}

function createRateSuccess(state, action) {
    return {
        ...state,
        creatingRate: false,
        projects: {
            ...state.projects,
            results: state.projects.results.map((item) =>
                item.id === action.payload.project
                    ? {
                          ...item,
                          rates: item.rates.concat({
                              id: action.payload.id,
                              name: action.payload.name,
                              price: action.payload.price,
                          }),
                      }
                    : item
            ),
        },
    };
}

function createRateFailure(state, action) {
    return {
        ...state,
        creatingRate: false,
        creatingRateError: action.payload,
    };
}

function toggleCreateRateModal(state) {
    return {
        ...state,
        createRateModal: !state.createRateModal,
    };
}

function projectDetailPage(state, action) {
    return {
        ...state,
        projectDetailPage: action.payload,
    };
}

function updateProjectBegin(state) {
    return {
        ...state,
        isUpdating: true,
    };
}

function updateProjectSuccess(state, action) {
    return {
        ...state,
        isUpdating: false,
        projects: {
            ...state.projects,
            results: state.projects.results.map((project) =>
                action.payload.id === project.id
                    ? { ...action.payload }
                    : project
            ),
        },
    };
}

function updateProjectFailure(state, action) {
    return {
        ...state,
        isUpdating: false,
        isUpdatingError: action.payload,
    };
}

function createProjectBegin(state) {
    return {
        ...state,
        createProject: {
            ...state.createProject,
            loading: true,
        },
    };
}

function createProjectSuccess(state, action) {
    return {
        ...state,
        projects: {
            ...state.projects,
            results: [...state.projects.results, action.payload],
            count: state.projects.count + 1,
        },
        createProject: {
            ...state.createProject,
            loading: false,
            error: null,
        },
    };
}

function createProjectFailure(state, action) {
    return {
        ...state,
        createProject: {
            ...state.createProject,
            loading: false,
            error: action.payload,
        },
    };
}

function toggleModal(state) {
    return {
        ...state,
        createProject: {
            ...state.createProject,
            toggled: !state.createProject.toggled,
        },
    };
}

function getProjectsBegin(state) {
    return {
        ...state,
        loading: true,
    };
}

function getProjectsSuccess(state, action) {
    return {
        ...state,
        projects: action.payload,
        loading: false,
    };
}

function removeProjectBegin(state) {
    return {
        ...state,
        isRemoving: true,
    };
}

function removeProjectSuccess(state, action) {
    return {
        ...state,
        isRemoving: false,
        isRemovingError: null,
        projects: {
            ...state.projects,
            results: state.projects.results.filter(
                (project) => project.id !== action.payload
            ),
            count: state.projects.count - 1,
        },
    };
}

function removeProjectFailure(state, action) {
    return {
        ...state,
        isRemoving: false,
        isRemovingError: action.payload,
    };
}

const handlers = {};
handlers[CREATE_PROJECT_MODAL_TOGGLE] = toggleModal;
handlers[GET_PROJECTS_BEGIN] = getProjectsBegin;
handlers[GET_PROJECTS_SUCCESS] = getProjectsSuccess;
handlers[CREATE_PROJECT_BEGIN] = createProjectBegin;
handlers[CREATE_PROJECT_SUCCESS] = createProjectSuccess;
handlers[CREATE_PROJECT_FAILURE] = createProjectFailure;
handlers[REMOVE_PROJECT_BEGIN] = removeProjectBegin;
handlers[REMOVE_PROJECT_SUCCESS] = removeProjectSuccess;
handlers[REMOVE_PROJECT_FAILURE] = removeProjectFailure;
handlers[UPDATE_PROJECT_BEGIN] = updateProjectBegin;
handlers[UPDATE_PROJECT_SUCCESS] = updateProjectSuccess;
handlers[UPDATE_PROJECT_FAILURE] = updateProjectFailure;
handlers[PROJECT_SET_DETAIL] = projectDetailPage;
handlers[TOGGLE_CREATE_RATE_MODAL] = toggleCreateRateModal;
handlers[CREATE_RATE_BEGIN] = createRateBegin;
handlers[CREATE_RATE_SUCCESS] = createRateSuccess;
handlers[CREATE_RATE_FAILURE] = createRateFailure;
handlers[REMOVE_RATE_BEGIN] = removeRateBegin;
handlers[REMOVE_RATE_SUCCESS] = removeRateSuccess;
handlers[REMOVE_RATE_FAILURE] = removeRateFailure;

const projectsReducer = createReducer(initialState, handlers);

export default projectsReducer;
