import { createReducer } from "../../reducers/reducerUtilities";
import * as a from "./actions";

const initialState = {
    recurringPaymentModalToggle: false,
    getRecurringPayments: false,
    getRecurringPaymentsError: null,
    createRecurringPayments: false,
    createRecurringPaymentsError: null,
    removeRecurringPayments: false,
    removeRecurringPaymentsError: null,
    recurringPayments: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
};

function createRecurringPaymentsBegin(state) {
    return {
        ...state,
        createRecurringPayments: true,
    };
}

function createRecurringPaymentsSuccess(state, action) {
    return {
        ...state,
        createRecurringPayments: false,
        recurringPayments: {
            count: state.recurringPayments.count + 1,
            results: [
                ...state.recurringPayments.results,
                { ...action.payload },
            ],
        },
    };
}

function createRecurringPaymentsFailure(state, action) {
    return {
        ...state,
        createRecurringPayments: false,
        createRecurringPaymentsError: action.payload,
    };
}

function removeRecurringPaymentsBegin(state) {
    return {
        ...state,
        removeRecurringPayments: true,
    };
}

function removeRecurringPaymentsSuccess(state, action) {
    return {
        ...state,
        removeRecurringPayments: false,
        recurringPayments: {
            count: state.recurringPayments.count - 1,
            results: state.recurringPayments.results.filter(
                (item) => item.id !== action.payload
            ),
        },
    };
}

function removeRecurringPaymentsFailure(state, action) {
    return {
        ...state,
        removeRecurringPayments: false,
        removeRecurringPaymentsError: action.payload,
    };
}

function getRecurringPaymentsBegin(state) {
    return {
        ...state,
        getRecurringPayments: true,
    };
}

function getRecurringPaymentsSuccess(state, action) {
    return {
        ...state,
        getRecurringPayments: false,
        recurringPayments: action.payload,
    };
}

function getRecurringPaymentsFailure(state, action) {
    return {
        ...state,
        getRecurringPayments: false,
        getRecurringPaymentsError: action.payload,
    };
}

function toggleRecPayModal(state) {
    return {
        ...state,
        recurringPaymentModalToggle: !state.recurringPaymentModalToggle,
    };
}

const handlers = {};
handlers[a.TOGGLE_CREATE_RECURRING_PAYMENT_MODAL] = toggleRecPayModal;
handlers[a.GET_RECURRING_PAYMENTS_BEGIN] = getRecurringPaymentsBegin;
handlers[a.GET_RECURRING_PAYMENTS_SUCCESS] = getRecurringPaymentsSuccess;
handlers[a.GET_RECURRING_PAYMENTS_FAILURE] = getRecurringPaymentsFailure;
handlers[a.CREATE_RECURRING_PAYMENT_BEGIN] = createRecurringPaymentsBegin;
handlers[a.CREATE_RECURRING_PAYMENT_SUCCESS] = createRecurringPaymentsSuccess;
handlers[a.CREATE_RECURRING_PAYMENT_FAILURE] = createRecurringPaymentsFailure;
handlers[a.REMOVE_RECURRING_PAYMENT_BEGIN] = removeRecurringPaymentsBegin;
handlers[a.REMOVE_RECURRING_PAYMENT_SUCCESS] = removeRecurringPaymentsSuccess;
handlers[a.REMOVE_RECURRING_PAYMENT_FAILURE] = removeRecurringPaymentsFailure;

const reducer = createReducer(initialState, handlers);

export default reducer;
