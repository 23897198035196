export const REGISTER_SET_START_TIME = "REGISTER_SET_START_TIME";

const setTime = (time) => ({
    type: REGISTER_SET_START_TIME,
    payload: time,
});

export function setStartTime(time) {
    return (dispatch) => {
        dispatch(setTime(time));
    };
}
