import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../getBackend";

export const REMOVE_RATE_BEGIN = "REMOVE_RATE_BEGIN";
export const REMOVE_RATE_SUCCESS = "REMOVE_RATE_SUCCESS";
export const REMOVE_RATE_FAILURE = "REMOVE_RATE_FAILURE";

export const removeRateBegin = () => ({
    type: REMOVE_RATE_BEGIN,
});

export const removeRateSuccess = (data) => ({
    type: REMOVE_RATE_SUCCESS,
    payload: { ...data },
});

export const removeRateFailure = (error) => ({
    type: REMOVE_RATE_FAILURE,
    payload: { error },
});

export function removeRate(projectId, rateId) {
    return (dispatch) => {
        dispatch(removeRateBegin());

        axios({
            method: "delete",
            url: `${getUrl()}/api/v1/rates/${rateId}/`,
        })
            .then((res) => {
                dispatch(removeRateSuccess({ projectId, rateId }));
                toast("Rate removed succesfully!", {
                    type: "success",
                });
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to remove rate.", { type: "error" });
                dispatch(removeRateFailure(error));
            });
    };
}
