import axios from "axios";
import { toast } from "react-toastify";
import { getUrl } from "../../../getBackend";

export const UPDATE_CLIENT_BEGIN = "UPDATE_CLIENT_BEGIN";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAILURE = "UPDATE_CLIENT_FAILURE";

export const updateClientBegin = () => ({
    type: UPDATE_CLIENT_BEGIN,
});

export const updateClientSuccess = (id) => ({
    type: UPDATE_CLIENT_SUCCESS,
    payload: id,
});

export const updateClientFailure = (error) => ({
    type: UPDATE_CLIENT_FAILURE,
    payload: { error },
});

export function updateClient(clientId, data) {
    return (dispatch) => {
        dispatch(updateClientBegin());

        const payload = {
            ...data,
            invoice_emails: data.invoice_emails
                .split(",")
                .flatMap((email) => (email ? [email] : [])),
            invoice_reminder_1: data.invoice_reminder_1 || null,
            invoice_reminder_2: data.invoice_reminder_2 || null,
        };

        axios({
            method: "patch",
            url: `${getUrl()}/api/v1/clients/${clientId}/`,
            data: payload,
        })
            .then((res) => {
                dispatch(updateClientSuccess(res.data));
                toast("Client updated succesfully!", {
                    type: "success",
                });
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                toast("Unable to update client.", { type: "error" });
                dispatch(updateClientFailure(error));
            });
    };
}
