import React, { Component } from "react";
import { LocalForm, actions } from "react-redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { Input } from "../../shared";
import { updatePassword } from "../../../actions/user/password";

class AccountSection extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(form) {
        if (form.new_password !== form.confirm_password) {
            toast("Passwords don't match.", { type: "error" });
        } else {
            this.props.updatePassword(this.props.id, {
                old_password: form.old_password,
                new_password: form.new_password,
            });
        }
        // Reset the form.
        this.formDispatch(actions.reset("local"));
    }

    render() {
        return (
            <div>
                <div className="column is-6">
                    <h1 className="title is-3">Account</h1>
                    <hr />
                    <LocalForm
                        onSubmit={(profileForm) => this.onSubmit(profileForm)}
                        getDispatch={(dispatch) => {
                            this.formDispatch = dispatch;
                        }}
                    >
                        <Input
                            label="Old password"
                            id=".old_password"
                            type="password"
                        />
                        <Input
                            label="New password"
                            id=".new_password"
                            type="password"
                        />
                        <Input
                            label="Confirm password"
                            id=".confirm_password"
                            type="password"
                        />

                        <button type="submit" className="button is-success">
                            Update password
                        </button>
                    </LocalForm>
                </div>
            </div>
        );
    }
}

AccountSection.propTypes = {
    updatePassword: PropTypes.func.isRequired,
    id: PropTypes.string,
};

const mapStateToProps = (state) => ({
    id: state.user.id,
});

export default connect(mapStateToProps, { updatePassword })(AccountSection);
