import React from "react";
import PropTypes from "prop-types";

const Modal = ({
    children,
    closeModal,
    modalState,
    title,
    footerButtons,
    width,
}) => {
    if (!modalState) {
        return null;
    }

    return (
        <div className="modal is-active">
            <div
                className="modal-background"
                onClick={closeModal}
                role="presentation"
            />
            <div className="modal-card" style={{ width }}>
                <header className="modal-card-head">
                    <p className="modal-card-title">{title}</p>
                    <button
                        className="delete"
                        onClick={closeModal}
                        type="button"
                    />
                </header>
                <section className="modal-card-body">
                    <div className="content">{children}</div>
                </section>
                <footer
                    className="modal-card-foot"
                    style={{ justifyContent: "space-between" }}
                >
                    <span className="button" onClick={closeModal} role="button">
                        Cancel
                    </span>
                    {footerButtons}
                </footer>
            </div>
        </div>
    );
};

Modal.propTypes = {
    children: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    modalState: PropTypes.bool.isRequired,
    footerButtons: PropTypes.object,
    title: PropTypes.string,
    width: PropTypes.string,
};

export default Modal;
