export const USER_LOGOUT = "USER_LOGOUT";

export const logoutBegin = () => ({
    type: USER_LOGOUT,
});

export function logout() {
    return (dispatch) => {
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        dispatch(logoutBegin());
    };
}
