import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavBar } from "../shared";
import TopComponent from "./TopComponent";
import ClientsOverview from "./ClientsOverview";
import SelectedClient from "./SelectedClient";
import ClientModals from "./ClientModals";
import NoClients from "./NoClients";

class ClientPage extends Component {
    render() {
        const { history, clientExists } = this.props;
        return (
            <div>
                <NavBar history={history} />
                <div className="container">
                    <TopComponent />
                    {clientExists && (
                        <div className="columns">
                            <div className="column is-3">
                                <ClientsOverview />
                            </div>
                            <div className="column">
                                <SelectedClient />
                            </div>
                        </div>
                    )}
                    {!clientExists && <NoClients />}
                </div>
                <ClientModals />
            </div>
        );
    }
}
ClientPage.propTypes = {
    history: PropTypes.object.isRequired,
    clientExists: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    loadingClients: state.clients.loading,
    clientExists: state.clients.clients.count > 0,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ClientPage);
